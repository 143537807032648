import * as React from "react";
import "../styles/Modal_SearchAddress.css";
import {searchAddress} from "../api/Api";
import {useEffect, useState} from "react";
import Paging from "./Paging";
import Spinner from "./Spinner";

export default function Modal_SearchAddress({showModal, onClose, setAddressNumber, setAddressText, setDetailAddress}) {
    const [address, setAddress] = useState("");
    const [addressList, setAddressList] = useState([]);
    const tableHeader = [
        {text: '우편번호', value: 'zipNo'},
        {text: '주소', value: 'lnmAdres'}
    ]
    const itemsPerPage = 10; // 페이지 당 요청서 개수
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const lastPageNumber = totalCount % itemsPerPage === 0 ? Math.floor(totalCount / itemsPerPage) : Math.floor(totalCount / itemsPerPage + 1);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const headerKey = tableHeader.map((header) => header.value);
    const [loading, setLoading] = useState(false);
    const handleBackgroundClick = (e) => {
        if (e.target.classList.contains("modal-wrapper")) {
            e.stopPropagation();
            onClose();
        }
    };
    const handlePageChange = async (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
        setLoading(true);
        setAddressList([]);
        const response = await searchAddress(address, 10, pageNumber);
        if (response.status === 200) {
            setLoading(false);
            setAddressList(response.data.address.NewAddressListResponse.newAddressListAreaCdSearchAll);
            setTotalCount(response.data.address.NewAddressListResponse.cmmMsgHeader.totalCount);
        }
    };
    const handleClickAddress = (item) => {
        setAddressNumber(item.zipNo);
        setAddressText(item.lnmAdres);
        setDetailAddress("");
        onClose();
    }
    const handleAddressSearch = async (e) => {
        e.preventDefault();
        setAddressList([]);
        setCurrentPage(1);
        setTotalCount(0);
        if (address.length === 0) {
            alert("검색어를 입력하세요.");
            return;
        }
        setLoading(true);
        const response = await searchAddress(address, 10, currentPage);
        if (response?.status === 200) {
            setLoading(false);
            setAddressList(response.data.address.NewAddressListResponse.newAddressListAreaCdSearchAll);
            setTotalCount(response.data.address.NewAddressListResponse.cmmMsgHeader.totalCount);
            if (!response.data.address.NewAddressListResponse.newAddressListAreaCdSearchAll) {
                setAddressList([]);
                alert("검색 결과가 존재하지 않습니다.");
                setTotalCount(0);
                return;
            }
            if (!Array.isArray(response.data.address.NewAddressListResponse.newAddressListAreaCdSearchAll)) {
                setAddressList([response.data.address.NewAddressListResponse.newAddressListAreaCdSearchAll]);
                setTotalCount(1);
            }
        } else {
            setLoading(false);
        }
    }
    useEffect(() => {
        setAddressList([]);
    }, []);
    return (
        <div className="modal-wrapper">
            <div className="modal" id="searchAddressModal">
                <div className="header">
                    <p>주소 검색</p>
                    <span className="close" onClick={onClose}>&times;</span>
                </div>
                <div className="content">
                    <div>
                        <div className="addressSearchBar">
                            <input value={address} onChange={(e) => setAddress(e.target.value)}/>
                            <button className="blackBtn" onClick={(e) => handleAddressSearch(e)}
                                    disabled={loading === true}>검색
                            </button>
                        </div>
                        <div className="address-notice">
                            <div>
                                <p>정확한 주소를 모르시는 경우</p>
                                <ul>
                                    <li>시/군/구 + 도로명, 동명 또는 건물명<br/>예) 동해시 중앙로, 여수 중앙동, 대전 현대아파트</li>
                                </ul>
                            </div>
                            <div>
                                <p>정확한 주소를 아시는 경우</p>
                                <ul>
                                    <li>도로명 + 건물번호 예) 종로 6</li>
                                    <li>읍/면/동/리 + 지번 예) 서린동 154-1</li>
                                </ul>
                            </div>
                        </div>
                        <table className="list_table">
                            <thead>
                            <tr className="table-header">
                                {
                                    tableHeader.map((header) =>
                                        <th key={header.text}>
                                            {header.text}
                                        </th>
                                    )
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                addressList.length > 0 && addressList.map((item, index) => (
                                    <tr key={index} className="table-body" onClick={() => handleClickAddress(item)}>
                                        {
                                            headerKey.map((key) =>
                                                <td key={key + index}
                                                    style={{cursor: "pointer"}}>
                                                    {item[key]}
                                                </td>
                                            )
                                        }
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                        <Spinner loading={loading}/>
                        {totalCount > 0 && <Paging itemsPerPage={itemsPerPage} currentPage={currentPage}
                                                   lastPage={lastPageNumber} handlePageChange={handlePageChange}
                                                   totalItemCount={totalCount}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}