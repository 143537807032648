import RequestLogo from "../car_logo_1.jpg";
import {useNavigate} from "react-router-dom";
import "../styles/Client.css";
import {useRecoilState} from "recoil";
import {IsLogin, userInfo} from "./UserInfo";
import {getUserInfo} from "../api/Api";
import {useEffect} from "react";

export default function Client() {
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useRecoilState(IsLogin);
    const [user, setUser] = useRecoilState(userInfo);
    function getCookie(cookieName) {
        const cookieString = document.cookie;
        const cookies = cookieString.split(';').map(cookie => cookie.trim().split('='));
        const cookie = cookies.find(([name]) => name === cookieName);
        return cookie ? cookie[1] : null;
    }

    // setIsLogin(!!getCookie('session_id'));
    const fetchMyInfo = async () => {
        if (!!getCookie('session_id')) {
            const myInfo = await getUserInfo();
            if (myInfo.status === 200) {
                setIsLogin(true);
                setUser(myInfo.data.user_info);
                navigate("/");
            } else {
                setIsLogin(false);
                navigate("/login/loginForm")
            }
        } else {
            setIsLogin(false);
            setUser({});
        }
    }

    useEffect(() => {
        fetchMyInfo();
    }, []);


    const handleLogoClick = () => {
        if (isLogin) {
            navigate("/req/list");
        } else {
            navigate("/login/loginForm");
        }
    }
    return (
        <div className="client">
            <img className="clientLogo" src={RequestLogo} alt="랜드로버 코리아"
                 onClick={handleLogoClick}/>
        </div>
    )
}

