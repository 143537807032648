// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.join-term-wrapper {
    width: 80%;
    margin: 48px auto;
    text-align: left;
}

.terms_txt {
    border: 1px solid #dcdcdc;
    overflow-y: scroll;
    padding: 10px;
    height: 152px;
    margin-bottom: 10px;
}

.agreeBtn {
    margin-bottom: 48px;
    font-size: 16px;
}

.joinTermController {
    text-align: center;
    font-size: 16px;
}

.joinTermController button {
    font-size: 16px;
    padding: 6.4px 0;
    margin: 0 16px;
    width: 160px;
}

@media screen and (max-width: 600px) {
    .join-term-wrapper{
        width: 90%;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/JoinTerms.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".join-term-wrapper {\n    width: 80%;\n    margin: 48px auto;\n    text-align: left;\n}\n\n.terms_txt {\n    border: 1px solid #dcdcdc;\n    overflow-y: scroll;\n    padding: 10px;\n    height: 152px;\n    margin-bottom: 10px;\n}\n\n.agreeBtn {\n    margin-bottom: 48px;\n    font-size: 16px;\n}\n\n.joinTermController {\n    text-align: center;\n    font-size: 16px;\n}\n\n.joinTermController button {\n    font-size: 16px;\n    padding: 6.4px 0;\n    margin: 0 16px;\n    width: 160px;\n}\n\n@media screen and (max-width: 600px) {\n    .join-term-wrapper{\n        width: 90%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
