// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list_table {
    width: 100%;
    margin: 0 auto 16px auto;
    border-collapse: collapse;
    font-size: min(1.1Vw, 16px);
    text-align: center;
}

.table-header {
    border-top: 1px solid black;
    background-color: rgba(0, 0, 0, 0.1);
}

.table-header th {
    padding: 11.2px 16px;
}

.table-body {
    border-bottom: .5px solid rgba(0, 0, 0, 0.1);
}

.table-body td {
    padding: 11.2px 0;
}

@media screen and (max-width: 600px) {
    .list_table {
        margin: 0 0 16px 0;
        font-size: min(2vw, 16px);
    }

    .table-header th {
        padding: 11px 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Table.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,wBAAwB;IACxB,yBAAyB;IACzB,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;IAC3B,oCAAoC;AACxC;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,4CAA4C;AAChD;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI;QACI,kBAAkB;QAClB,yBAAyB;IAC7B;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".list_table {\n    width: 100%;\n    margin: 0 auto 16px auto;\n    border-collapse: collapse;\n    font-size: min(1.1Vw, 16px);\n    text-align: center;\n}\n\n.table-header {\n    border-top: 1px solid black;\n    background-color: rgba(0, 0, 0, 0.1);\n}\n\n.table-header th {\n    padding: 11.2px 16px;\n}\n\n.table-body {\n    border-bottom: .5px solid rgba(0, 0, 0, 0.1);\n}\n\n.table-body td {\n    padding: 11.2px 0;\n}\n\n@media screen and (max-width: 600px) {\n    .list_table {\n        margin: 0 0 16px 0;\n        font-size: min(2vw, 16px);\n    }\n\n    .table-header th {\n        padding: 11px 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
