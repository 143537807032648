import TextEditor from "./TextEditor";
import "../styles/Modal_PopupWrite.css";
import {useState} from "react";
import dayjs from "dayjs";

export default function Modal_PopupWrite({showModal, onClose}) {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    if (!showModal) {
        return null;
    }
    const handleBackgroundClick = (e) => {
        // 모달 외부를 클릭하면 모달을 닫음
        if (e.target.classList.contains("modal-wrapper")) {
            onClose();
        }
    };

    return (
        <div className="modal-wrapper" onClick={handleBackgroundClick}>
            <div className="modal">
                <div className="header">
                    <p>팝업 등록</p>
                    <span className="close" onClick={onClose}>&times;</span>
                </div>
                <div className="content">
                    <div className="modal-inputs" style={{marginBottom: '16px'}}>
                        <div className="modal-inputs-category">접속기기</div>
                        <select defaultValue={"PM"}>
                            <option value="PM">PC와 모바일</option>
                            <option value="P">PC</option>
                            <option value="M">모바일</option>
                        </select>
                        <div className="modal-inputs-category">시간</div>
                        <div>
                            <input/>
                            <br/><p className="caution">[시간] 고객이 다시 보지 않음을 선택할 시 몇 시간동안 팝업레이어를 보여주지 않을지 설정합니다.</p>
                        </div>
                        <div className="modal-inputs-category">시작일시</div>
                        <div className="popUpDate">
                            <input/>
                            <input type={"checkbox"}/>
                            <label>시작일시를 오늘로</label>
                        </div>
                        <div className="modal-inputs-category">종료일시</div>
                        <div className="popUpDate">
                            <input/>
                            <input type={"checkbox"}/>
                            <label>종료일시를 오늘로부터 7일 후로</label>
                        </div>
                        <div className="modal-inputs-category">팝업레이어 좌측 위치</div>
                        <div>
                            <input/><span>px</span>
                        </div>
                        <div className="modal-inputs-category">팝업레이어 상단 위치</div>
                        <div>
                            <input/><span>px</span>
                        </div>
                        <div className="modal-inputs-category">팝업레이어 넓이</div>
                        <div>
                            <input/><span>px</span>
                        </div>
                        <div className="modal-inputs-category">팝업레이어 높이</div>
                        <div>
                            <input/><span>px</span>
                        </div>
                        <div className="modal-inputs-category">팝업 제목</div>
                        <input style={{width: "auto"}}/>
                    </div>
                    <TextEditor/>
                    <div style={{textAlign:"center", marginTop:'16px'}}>
                        <button id="saveBtn" className="blackBtn">저장</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
