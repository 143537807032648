import {useEffect, useState} from "react";
import Paging from "./Paging";
import "../styles/GuaranteeList.css";
import {getWarrantyList, viewWarrantyDetail} from "../api/Api";
import Modal_WarrantyDetails from "./Modal_WarrantyDetails";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";

export default function GuaranteeList({searchCondition, currentPage, setCurrentPage}) {
    const navigate = useNavigate();
    const [isWarrantyModalOpen, setIsWarrantyModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null); // 클릭한 사용자 데이터
    const [warrantyData, setWarrantyData] = useState([]);
    const tableHeader = [
        {text: '번호', value: 'index'},
        {text: '시공점명', value: 'agency_affiliation_value'},
        {text: '고객명', value: 'customer_name'},
        {text: '영업직원', value: 'name'},
        {text: '시공희망일', value: 'wished_date'},
        {text: '차대번호', value: 'vehicle_info_number'},
        {text: '보증서 보기', value: 'view_guarantee'},
        {text: '비고', value: 'remark'}
    ]
    const headerKey = tableHeader.map((header) => header.value);
    // 페이지당 데이터 계산 -- 역할별로 구분된 데이터는 let 단일 변수로 선언 후 조건문 내에서 값 할당하도록 수정 필요
    const itemsPerPage = 10; // 페이지 당 요청서 개수
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [lastPageNumber, setLastPageNumber] = useState(1);
    const getWarranties = async (searchCondition = {}, currentPageNumber = 1) => {
        let warrantyList;
        warrantyList = await getWarrantyList(searchCondition, currentPageNumber);
        if (warrantyList?.status === 200) {
            setWarrantyData(warrantyList?.data.guarantee_list);
            setLastPageNumber(warrantyList?.data.total_page);
        } else if (warrantyList?.status === 401) {
            navigate("/");
        }
    }

    useEffect(() => {
        getWarranties(searchCondition, currentPage);
    }, [searchCondition, currentPage]);


    const handlePageChange = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
    };
    const openWarrantyModal = (item) => {
        setSelectedItem(item);
        setIsWarrantyModalOpen(true);
    };

    const closeWarrantyModal = () => {
        setIsWarrantyModalOpen(false);
    };

    const handleViewWarranty = async (id) => {
        const viewWarranty = await viewWarrantyDetail(id);
        if (viewWarranty) {
            if (viewWarranty.status === 200) {
                const newWindow = window.open('', '_blank');
                newWindow.document.write(viewWarranty.data);
                newWindow.document.close();
            } else if (viewWarranty.status === 401) {
                navigate("/");
            }
        }
    }

    return (
        <div>
            {warrantyData.length > 0 ? (
                <div>
                    <table className="list_table">
                        <thead>
                        <tr className="table-header">
                            {
                                tableHeader.map((item) =>
                                    <th key={item.text}>
                                        {item.text}
                                    </th>
                                )
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            warrantyData.map((item, index) => (
                                <tr key={index} className="table-body" style={{cursor: "pointer"}}
                                    onClick={() => openWarrantyModal(item)}>
                                    {
                                        headerKey.map((key) =>
                                            <td key={key + index}>
                                                {key === 'view_guarantee' ? (
                                                    <button className="viewGuaranteeBtn"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleViewWarranty(item.job_request_id)
                                                            }}>
                                                        보증서 보기
                                                    </button>) : key === 'index' ? indexOfFirstItem + index + 1 : key === 'agency_affiliation_value' ? item[key].split("/")[1] : key === 'wished_date' ? dayjs(item[key] * 1000).format("YYYY-MM-DD") : (item[key])}
                                            </td>)
                                    }
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                    <Paging currentPage={currentPage} lastPage={lastPageNumber} handlePageChange={handlePageChange}/>
                </div>
            ) : (<div className="no_data_exist">
                <h3>시공 보증서가 존재하지 않습니다.</h3>
            </div>)}
            {
                isWarrantyModalOpen && (
                    <Modal_WarrantyDetails showModal={isWarrantyModalOpen} onClose={closeWarrantyModal} item={selectedItem}
                                           situation={"조회"}/>
                )
            }
        </div>
    )
}