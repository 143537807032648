import {useEffect, useRef, useState} from "react";
import "../styles/Modal_AgencyConnection.css";
import {addOrUpdateConnection, getAgencyCodeList, getConnection} from "../api/Api";

export default function Modal_AgencyConnection({onClose, affiliation_code}) {
    const [validAgency, setValidAgency] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [connectedAgency, setConnectedAgency] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const inputRef = useRef(null);
    const [hoveredAgency, setHoveredAgency] = useState(null);

    const fetchValidAgency = async () => {
        const response = await getAgencyCodeList();
        if (response.status === 200) {
            setValidAgency(response.data.agency_list);
        } else setValidAgency([]);
    }
    const fetchConnection = async () => {
        const response = await getConnection('admin', affiliation_code);
        if (response.status === 200) {
            setConnectedAgency(response.data.dasa_relation);
        } else setConnectedAgency([]);
    }

    const addConnection = async (e, code) => {
        e.preventDefault();
        if (code.length >= 8) {
            if (validAgency?.some((agency) => agency.code_key?.includes(code)) && !connectedAgency?.some(agency => agency.code_key?.includes(code))) {
                setConnectedAgency([...connectedAgency,])
                setInputValue("");
            } else if (!validAgency?.some((agency) => agency.code_key?.includes(code))) {
                alert("연결할 수 없는 대리점입니다.");
            } else if (connectedAgency?.some(agency => agency.code_key?.includes(code))) {
                alert("이미 연결한 대리점입니다.");
            }
        } else {
            alert("연결할 수 없는 대리점입니다.");
        }
    }

    const deleteConnection = async (code) => {
        setConnectedAgency(prevConnectedAgency => prevConnectedAgency?.filter(agency => agency.code_key !== code.code_key));
    }

    const inputHandler = (input) => {
        setInputValue(input);
        if (!input) {
            setSuggestions([]);
        } else {
            const filteredSuggestions = validAgency?.filter(agency => (agency.code_key?.includes(input) && !connectedAgency?.some(connection => connection.code_key?.includes(agency.code_key))) || (agency.code_value?.includes(input) && !connectedAgency?.some(connection => connection.code_value?.includes(agency.code_value))));
            setSuggestions(filteredSuggestions);
        }
    }
    const suggestionClickHandler = (suggestion) => {
        setConnectedAgency([...connectedAgency, suggestion]);
        setSuggestions([]);
        setInputValue("");
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const connectionSaveHandler = async () => {
        const isConfirmed = window.confirm("저장하시겠습니까?");
        if(isConfirmed){
            const connectedAgencyCodesString = connectedAgency?.map(agency => agency.code_key).join(",");
            // console.log(connectedAgencyCodesString)
            const response = await addOrUpdateConnection(affiliation_code, connectedAgencyCodesString);
            if(response === 200){
                alert("저장되었습니다.");
                onClose();
            }
        }
    }

    useEffect(() => {
        fetchValidAgency();
        fetchConnection();
    }, []);

    return (
        <div className="modal-wrapper">
            <div className="modal" id="agencyConnectionModal">
                <div className="header">
                    <p>대리점 연결 관리</p>
                    <span className="close" onClick={onClose}>&times;</span>
                </div>
                <div className="content">
                    <div>
                        <h3>연결된 대리점</h3>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="agencyConnector">
                                {connectedAgency?.map((agency, index) => (
                                    <div className="connectedAgency" key={index}
                                         onMouseEnter={() => setHoveredAgency(agency)}
                                         onMouseLeave={() => setHoveredAgency(null)}>
                                        <p>{agency.code_key}</p>
                                        <span onClick={() => deleteConnection(agency)}
                                              style={{cursor: "pointer"}}>&times;</span>
                                        {hoveredAgency && hoveredAgency.code_key === agency.code_key && (
                                            <div className="hoverInfo">{agency.code_value}</div>
                                        )}
                                    </div>
                                ))}
                                <div className="agencyInput">
                                    <input autoFocus={true} value={inputValue}
                                           onChange={(e) => inputHandler(e.target.value)} ref={inputRef}
                                    placeholder="연결할 대리점명 또는 대리점 코드를 입력해주세요. ex) SA001001"/>
                                    <div className="suggestion">
                                        {suggestions?.map((suggestion, index) => (
                                            <div key={index} onClick={() => suggestionClickHandler(suggestion)}>
                                                <span>{suggestion.code_key}</span><span>{suggestion.code_value}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="connectionController">
                        <button className="blackBtn" onClick={connectionSaveHandler}>저장</button>
                    </div>
                </div>
            </div>
        </div>
    )
}