// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-wrapper {
    width: 100%;
    border-top: 4px solid #000;
}

.footer {
    display: flex;
    width: 95%;
    margin: 0 auto;
    padding: 30px 0 30px 10px;
    justify-content: space-around;
    text-align: left;
    font-size: min(13px, 3vw);
    line-height: 150%;
}

.footer .copy_txt {
    color: #969696;
}

@media screen and (max-width: 900px) {
    .footer {
        flex-direction: column;
        gap: 16px;
        width: auto;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,UAAU;IACV,cAAc;IACd,yBAAyB;IACzB,6BAA6B;IAC7B,gBAAgB;IAChB,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,sBAAsB;QACtB,SAAS;QACT,WAAW;IACf;AACJ","sourcesContent":[".footer-wrapper {\n    width: 100%;\n    border-top: 4px solid #000;\n}\n\n.footer {\n    display: flex;\n    width: 95%;\n    margin: 0 auto;\n    padding: 30px 0 30px 10px;\n    justify-content: space-around;\n    text-align: left;\n    font-size: min(13px, 3vw);\n    line-height: 150%;\n}\n\n.footer .copy_txt {\n    color: #969696;\n}\n\n@media screen and (max-width: 900px) {\n    .footer {\n        flex-direction: column;\n        gap: 16px;\n        width: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
