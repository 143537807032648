import "../styles/Notice.css";
import TextEditor from "./TextEditor";
import {saveNotice} from "../api/Api";
import {Quill} from 'react-quill';
import ImageResize from "quill-image-resize";
import {useEffect} from "react";

Quill.register('modules/ImageResize', ImageResize);

const Font = Quill.import('formats/font');
Font.whitelist = ['Arial', 'Serif', 'Sans-serif', 'Courier', 'Georgia', 'Impact', 'Tahoma', 'Verdana'];
Quill.register(Font, true);

const Size = Quill.import('attributors/style/size');
Size.whitelist = ['14px', '16px', '18px', '20px', '24px', '32px', '48px'];
Quill.register(Size, true);

export default function Notice({aff, activeOption, setActiveOption, noti, setNoti}) {
    const modules = {
        toolbar: [
            [{'font': Font.whitelist}],
            [{'size': Size.whitelist}],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                {list: "ordered"},
                {list: "bullet"},
                {indent: "-1"},
                {indent: "+1"},
            ],
            ["code-block"],
            ["link", "image", "video"],
            [{align: []}, {color: []}, {background: []}], // dropdown with defaults from theme
            [{script: "sub"}, {script: "super"}],
            [{table: true}],
            ["clean"],
        ],
        ImageResize: {
            parchment: Quill.import("parchment"),
        },
    };
    const formats = [
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "code-block",
        "video",
        "align",
        "color",
        "background",
        "script",
        "table",
        "alt",
        "height",
        "width",
    ];

    const handleRadioChange = (event) => {
        setActiveOption(event.target.value === 'true');
    };
    const handleSaveNoti = async () => {
        const isConfirmed = window.confirm("저장하시겠습니까?");
        if (isConfirmed) {
            let type;
            if (aff === "대리점") {
                type = "agency";
            } else if (aff === "지점") {
                type = "dealer";
            } else if (aff === "지점장") {
                type = "financial";
            } else if (aff === "본사") {
                type = "admin"
            }
            const response = await saveNotice(type, noti, activeOption);
            if (response === 200) {
                alert("저장되었습니다.");
            }
            console.log(noti);
        }
    }

    return (
        <div>
            <div className="noti-header">
                {aff} 공지사항
            </div>
            <div className="noti-body">
                <label>활성여부</label>
                <div className="activeController">
                    <div>
                        <input type={"radio"} value={true}
                               checked={activeOption === true}
                               onChange={handleRadioChange}/>활성
                    </div>
                    <div>
                        <input type={"radio"} value={false}
                               checked={activeOption === false}
                               onChange={handleRadioChange}/>비활성
                    </div>
                </div>
                <label>공지내용</label>
                <TextEditor text={noti} setText={setNoti} modules={modules} formats={formats}/>
            </div>
            <button id="saveNotiBtn" className="blackBtn" onClick={handleSaveNoti}>저장</button>
        </div>
    )
}