import "../styles/FindUserInfo.css";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {findId, findPw} from "../api/Api";

export default function FindUserInfo() {
    const [activeTab, setActiveTab] = useState("tab1");
    const [id, setId] = useState("");
    const [phone1, setPhone1] = useState("");
    const [phone2, setPhone2] = useState("");
    const [phone3, setPhone3] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const handleTabClick = (tabId) => {
        if (activeTab !== tabId) {
            setId("");
            setPhone1("");
            setPhone2("");
            setPhone3("");
            setMessage("");
            setActiveTab(tabId);
        }
    };

    const getMyId = async () => {
        setMessage("");
        if (!(phone1 && phone2 && phone3)) {
            alert("휴대폰 번호를 입력해주세요.");
            return;
        }
        const response = await findId(`${phone1}-${phone2}-${phone3}`);
        setMessage(response);
    }

    const getMyPw = async () => {
        setMessage("");
        if (!id) {
            alert("ID를 입력해주세요.");
            return;
        }
        if (!(phone1 && phone2 && phone3)) {
            alert("휴대폰 번호를 입력해주세요.");
            return;
        }
        const response = await findPw(id, `${phone1}-${phone2}-${phone3}`);
        setMessage(response);
    }
    return (
        <div className="findUserInfo">
            <div className="tabBtns">
                <a href="#tab1" className="blackBtn" onClick={() => handleTabClick("tab1")}>ID 찾기</a>
                <a href="#tab2" className="grayBtn" onClick={() => handleTabClick("tab2")}>비밀번호 찾기</a>
            </div>
            <div className="find-wrapper">
                <div id="tab1" style={{display: activeTab === "tab1" ? "block" : "none"}}>
                    <div>
                        <p>가입할 때 입력하셨던 휴대폰 번호를 입력하세요.</p>
                        <div className="find-input">
                            <label>휴대폰 번호</label>
                            <div>
                                <input value={phone1} onChange={(e) => setPhone1(e.target.value)}/>
                                <span>-</span>
                                <input value={phone2} onChange={(e) => setPhone2(e.target.value)}/>
                                <span>-</span>
                                <input value={phone3} onChange={(e) => setPhone3(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    {message !== '' && <p className="message">{message}</p>}
                    <div className="findController">
                        <button className="grayBtn" onClick={() => navigate("/")}>취소</button>
                        <button className="blackBtn" onClick={getMyId}>확인</button>
                    </div>
                </div>
                <div id="tab2" style={{display: activeTab === "tab2" ? "block" : "none"}}>
                    <div>
                        <p>가입할 때 입력하셨던 ID와 휴대폰 번호를 입력하세요.</p>
                        <div className="find-input">
                            <label>ID</label>
                            <input value={id} onChange={(e) => setId(e.target.value)}/>
                        </div>
                        <div className="find-input">
                            <label>휴대폰 번호</label>
                            <div>
                                <input value={phone1} onChange={(e) => setPhone1(e.target.value)}/>
                                <span>-</span>
                                <input value={phone2} onChange={(e) => setPhone2(e.target.value)}/>
                                <span>-</span>
                                <input value={phone3} onChange={(e) => setPhone3(e.target.value)}/>
                            </div>
                        </div>
                        {message !== '' && <p className="message">{message}</p>}
                        <div className="findController">
                            <button className="grayBtn" onClick={() => navigate("/")}>취소</button>
                            <button className="blackBtn" onClick={getMyPw}>확인</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}