import SmithLogo from "../logo_request.png";
import "../styles/Modal.css";

export default function Modal_Copyright({showModal, onClose}) {
    if (!showModal) {
        return null;
    }
    const handleBackgroundClick = (e) => {
        // 모달 외부를 클릭하면 모달을 닫음
        if (e.target.classList.contains("modal-wrapper")) {
            onClose();
        }
    };
    return (
        <div className="modal-wrapper" onClick={handleBackgroundClick}>
            <div className="modal">
                <div className="header">
                    <p>개인정보처리방침</p>
                    <span className="close" onClick={onClose}>&times;</span>
                </div>
                <div className="content">
                    <p>
                        '(주)스미스클럽' 은 (이하 '회사'는) 고객님의 개인정보를 중요시하며, "정보통신망 이용 촉진 및 정보보호"에 관한 법률을 준수하고 있습니다.<br/>
                        회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로
                        이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.<br/>
                        회사는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별 공지)을 통하여 공지할
                        것입니다.<br/><br/>
                        <b>1. 개인정보의 수집 및 이용 목적</b><br/><br/>
                        회사는 상담, 서비스 신청, 문의 등의 방법으로 아래와 같은 개인정보를 수집하고 있습니다.<br/>
                        <b>가.</b> 수집 항목 : 성명, 연락처(휴대전화), 연락처(일반), 이메일, 로그인 ID, 비밀번호, 주소, 상호,
                        사업자 번호, 팩스, 업태, 종목, 서비스 이용 기록, 결제 기록<br/>
                        <b>나.</b> 서비스 이용과정이나, 처리 과정에서 이용자의 인터넷 환경, 방문기록, 서비스이용기록, 쿠키
                        정보가 생성되어 수집될 수 있습니다.<br/>
                        <b>다.</b> 회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br/>
                        - 서비스 제공에 관한 계약 이행을 위한 관할 대리점의 업무 진행<br/>
                        - 서비스 제공에 따른 요금정산, 콘텐츠 제공, 구매 및 요금 결제, 물품배송 또는 청구지 등
                        발송<br/>
                        - 회원제 서비스 이용에 따른 본인확인, 가입 의사 확인, 불만 처리 등 민원처리<br/>
                        - 마케팅 및 광고에 활용<br/>
                        - 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계<br/><br/>
                        <b>2. 이용자 자신의 개인정보 관리(열람, 정정, 삭제 등)에 관한 사항</b><br/><br/>
                        <b>가.</b> 이용자는 언제든지 회사 홈페이지를 이용하여 회원 등록되어 있는 자신의 개인정보를 조회
                        하거나 수정할 수 있으며 회원 등록 탈퇴를 요청할 수도 있습니다.<br/>
                        <b>나.</b> 자신의 개인정보가 조회되지 않거나 확인이 어려운 경우, 개인정보보호책임자 또는 담당자에게 서면, 전화 또는 이메일을 통해 가능합니다.<br/><br/>
                        <b>3. 개인정보 이용 및 제3자 제공</b><br/><br/>
                        회사는 제 2장에 고지한 범위 내에서 사용하며, 고객님의 사전 동의 없이는 원칙적으로 초과하여
                        이용하거나, 외부에 공개하지 않습니다. 다만, 아래와 같은 경우에는 예외로 합니다.<br/>
                        - 고객이 사전에 공개에 동의한 경우<br/>
                        - 기타 법에 의해 요구된다고 판단되는 경우<br/>
                        (예, 관련법령에 의거 적법한 절차에 의한 정부/수사기관의 요청이 있는 경우 등)<br/><br/>
                        <b>4. 개인정보의 보유 및 이용 기간</b><br/><br/>
                        회사는 개인정보 수집 및 이용목적이 달성된 후에는 예외 없이 해당 정보를 지체 없이 파기합니
                        다.<br/><br/>
                        <b>5. 개인정보의 파기절차 및 방법</b><br/><br/>
                        고객님의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 아래와 같이 지체 없이 파기합니다.<br/>
                        - 컴퓨터 및 기록장치에 저장된 정보는 안전하고 복구 불가능하게 파기됩니다.<br/>
                        - 종이에 출력된 정보는 분쇄기 또는 소각을 통해 파기됩니다.<br/><br/>
                        <b>6. 의견 수렴 및 개인정보보호책임자</b><br/><br/>
                        회사는 개인정보와 관련된 고객님의 의견을 언제나 성실하게 답변을 할 의무가 있습니다. 이에 따라 회사는 개인정보관리책임자를 선정하였으며, 고객님은 문의 사항이 있을 시 아래의 연락처로
                        문의 주시면 답변을 받으실 수 있습니다.<br/><br/>
                        개인정보관리책임자 : 김헌균<br/>
                        이메일 : group@smithclub.net<br/>
                        연락처 : 031-903-2121<br/><br/>
                        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br/><br/>
                        – 개인정보침해신고센터(http://www.118.or.kr / 118)<br/>
                        – 정보보호마크인증위원회 (http://www.eprivacy.or.kr / 02-580-0533~4)<br/>
                        – 대검찰청 인터넷범죄수사센터 ( http://www.spo.go.kr / 02-3480-2000)<br/>
                        – 경찰청 사이버테러대응센터 ( http://www.ctrc.go.kr / 02-392-0330)<br/><br/>
                        <b>7. 고지의 의무</b><br/><br/>
                        현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 홈페이지 ‘공지사항’을 통해 고지할
                        것입니다.<br/><br/>
                        시행 일자 : 2020년 1월 1일<br/><br/>
                    </p>
                    <center><img className="modal_smith_logo" src={SmithLogo} alt="스미스클럽"/></center>
                </div>
            </div>
        </div>
    )
}