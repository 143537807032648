// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#regModal {
    border: 2px solid black;
}

#regModal .modal-inputs span {
    margin: 0 3.2px;
}

#regModal select {
    width: 30%;
    max-width: 200px;
}

.regModalController {
    display: flex;
    justify-content: center;
    margin: 48px auto 0 auto;
}

.regModalController button {
    font-weight: bold;
    font-size: 12.8px;
    padding: 6.4px 0;
    margin: 0 32px;
    width: 96px;
}`, "",{"version":3,"sources":["webpack://./src/styles/Modal_Registration.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,WAAW;AACf","sourcesContent":["#regModal {\n    border: 2px solid black;\n}\n\n#regModal .modal-inputs span {\n    margin: 0 3.2px;\n}\n\n#regModal select {\n    width: 30%;\n    max-width: 200px;\n}\n\n.regModalController {\n    display: flex;\n    justify-content: center;\n    margin: 48px auto 0 auto;\n}\n\n.regModalController button {\n    font-weight: bold;\n    font-size: 12.8px;\n    padding: 6.4px 0;\n    margin: 0 32px;\n    width: 96px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
