import "../styles/SearchBar.css";
import {useState} from "react";
import DateInput from "./DateInput";
import * as React from "react";
import dayjs from 'dayjs';

export default function SearchBar_Request({role, searchCondition, setSearchCondition, setCurrentPage}) {
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = React.useState(dayjs('2023-01-01'));
    const [selectedEndDate, setSelectedEndDate] = React.useState(dayjs());
    const [dealers, setDealers] = useState("");
    const [agency, setAgency] = useState("");
    const [exhibitionHall, setExhibitionHall] = useState("");
    const [salesPerson, setSalesPerson] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [vehicleNumber, setVehicleNumber] = useState("");
    const [contractNumber, setContractNumber] = useState("");
    const [status, setStatus] = useState("");
    const [vehicleType, setVehicleType] = useState("");

    const onChangeHandler = (e, setState) => {
        const value = e.target.value;
        setState(value);
    };

    const handleSearch = () => {
        if (selectedStartDate && selectedEndDate) {
            if ((selectedStartDate.format("YYYY-MM-DD")) > (selectedEndDate.format("YYYY-MM-DD"))) {
                alert("올바른 등록 일자를 선택해주세요.");
                return;
            }
        }
        if(selectedStartDate){
            if (selectedStartDate.format("YYYY-MM-DD") === 'Invalid Date'){
                alert("올바른 등록 일자를 선택해주세요.");
                return;
            }
        }
        if(selectedEndDate){
            if (selectedEndDate.format("YYYY-MM-DD") === 'Invalid Date'){
                alert("올바른 등록 일자를 선택해주세요.");
                return;
            }
        }

        if (role === 'admin') {
            setSearchCondition({
                dealer_affiliation: dealers,
                dealer_location: exhibitionHall,
                agency_affiliation: agency,
                sales_person_name: salesPerson,
                customer_name: customerName,
                vehicle_info_number: vehicleNumber,
                process_status: status,
                registration_date: selectedStartDate ? selectedEndDate ? selectedStartDate.format("YYYY-MM-DD") + "/" + selectedEndDate.format("YYYY-MM-DD") : selectedStartDate + "/" + " " : selectedEndDate ? " " + "/" + selectedEndDate : " / ",
            })
        } else if (role === 'dealer') {
            setSearchCondition({
                customer_name: customerName,
                agency_affiliation: agency,
                process_status: status,
                vehicle_info_number: vehicleNumber,
                car_type: vehicleType,
                registration_date: selectedStartDate ? selectedEndDate ? selectedStartDate.format("YYYY-MM-DD") + "/" + selectedEndDate.format("YYYY-MM-DD") : selectedStartDate + "/" + " " : selectedEndDate ? " " + "/" + selectedEndDate : " / ",
            })
        } else if (role === 'agency') {
            setSearchCondition({
                dealer_affiliation: dealers,
                agency_affiliation: agency,
                sales_person_name: salesPerson,
                customer_name: customerName,
                vehicle_info_number: vehicleNumber,
                process_status: status,
                registration_date: selectedStartDate ? selectedEndDate ? selectedStartDate.format("YYYY-MM-DD") + "/" + selectedEndDate.format("YYYY-MM-DD") : selectedStartDate + "/" + " " : selectedEndDate ? " " + "/" + selectedEndDate : " / ",
            })
        } else if (role === 'financial') {
            setSearchCondition({
                customer_name: customerName,
                agency_affiliation: agency,
                process_status: status,
                sales_person: salesPerson,
                registration_date: selectedStartDate ? selectedEndDate ? selectedStartDate.format("YYYY-MM-DD") + "/" + selectedEndDate.format("YYYY-MM-DD") : selectedStartDate + "/" + " " : selectedEndDate ? " " + "/" + selectedEndDate : " / ",
            })
        }
        setCurrentPage(1);
    }
    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
    };

    if (role === 'admin') {
        return (
            <div className="searchBar">
                <button id="search_switch" className="blackBtn"
                        onClick={toggleSearch}>{isSearchOpen ? "검색 항목 닫기" : "검색 항목 열기"}</button>

                    <div className={`search_admin ${isSearchOpen ? 'open' : ''}`}>
                        <div className="search_input">
                            <div>
                                <label>딜러사</label>
                                <input type="text" id="search_dealers" className="search_values"
                                       value={dealers}
                                       onChange={(e) => onChangeHandler(e, setDealers)}/>
                            </div>
                            <div>
                                <label>전시장</label>
                                <input type="text" id="search_exhibitionHall"
                                       className="search_values" value={exhibitionHall}
                                       onChange={(e) => onChangeHandler(e, setExhibitionHall)}/>
                            </div>
                            <div>
                                <label>대리점</label>
                                <input type="text" id="search_agency"
                                       className="search_values" value={agency}
                                       onChange={(e) => onChangeHandler(e, setAgency)}/>
                            </div>
                            <div>
                                <label>영업직원</label>
                                <input type="text" id="search_salesPerson"
                                       className="search_values" value={salesPerson}
                                       onChange={(e) => onChangeHandler(e, setSalesPerson)}/>
                            </div>
                            <div>
                                <label>고객명</label>
                                <input type="text" id="search_customerName"
                                       className="search_values" value={customerName}
                                       onChange={(e) => onChangeHandler(e, setCustomerName)}/>
                            </div>
                            <div>
                                <label>차대번호</label>
                                <input type="text" id="search_vehicleNumber"
                                       className="search_values" value={vehicleNumber}
                                       onChange={(e) => onChangeHandler(e, setVehicleNumber)}/>
                            </div>
                            {/*<div>*/}
                            {/*    <label>계약번호</label>*/}
                            {/*    <input type="text" id="search_contractNumber"*/}
                            {/*           className="search_values" value={contractNumber}*/}
                            {/*           onChange={(e) => onChangeHandler(e, setContractNumber)}/>*/}
                            {/*</div>*/}
                            <div>
                                <label>상태</label>
                                <select id="search_status" className="search_values" value={status}
                                        onChange={(e) => onChangeHandler(e, setStatus)}>
                                    <option value="">선택</option>
                                    <option value="승인대기">승인대기</option>
                                    <option value="승인">승인</option>
                                    <option value="반려">반려</option>
                                    <option value="취소">취소</option>
                                    <option value="시공진행">시공진행</option>
                                    <option value="시공완료">시공완료</option>
                                </select>
                            </div>
                            <div style={{display: "flex", gridColumn: '2/span 2', alignItems: "center"}}>
                                <label>등록일</label>
                                <div id="search_registration_date">
                                    <div style={{margin: '0 8px 0 0'}}><DateInput date={selectedStartDate}
                                                                                  setDate={setSelectedStartDate}/></div>
                                    ~
                                    <div style={{margin: '0 0 0 8px'}}><DateInput date={selectedEndDate}
                                                                                  setDate={setSelectedEndDate}/></div>
                                </div>
                            </div>
                        </div>
                        <button id="searchBtn" className="blackBtn" onClick={handleSearch}>검색</button>
                    </div>

            </div>
        )
    } else if (role === 'dealer') {
        return (
            <div className="searchBar">
                <button id="search_switch" className="blackBtn"
                        onClick={toggleSearch}>{isSearchOpen ? "검색 항목 닫기" : "검색 항목 열기"}</button>
                    <div className={`search_dealer ${isSearchOpen ? "open" : ''}`}>
                        <div className="search_input">
                            <div>
                                <label>고객명</label>
                                <input type="text" id="search_customerName"
                                       className="search_values" value={customerName}
                                       onChange={(e) => onChangeHandler(e, setCustomerName)}/>
                            </div>
                            <div>
                                <label>차대번호</label>
                                <input type="text" id="search_vehicleNumber"
                                       className="search_values" value={vehicleNumber}
                                       onChange={(e) => onChangeHandler(e, setVehicleNumber)}/>
                            </div>
                            <div>
                                <label>상태</label>
                                <select id="search_status" className="search_values" value={status}
                                        onChange={(e) => onChangeHandler(e, setStatus)}>
                                    <option value="">선택</option>
                                    <option value="승인대기">승인대기</option>
                                    <option value="승인">승인</option>
                                    <option value="반려">반려</option>
                                    <option value="취소">취소</option>
                                    <option value="시공진행">시공진행</option>
                                    <option value="시공완료">시공완료</option>
                                </select>
                            </div>
                            <div>
                                <label>대리점</label>
                                <input type="text" id="search_agency"
                                       className="search_values" value={agency}
                                       onChange={(e) => onChangeHandler(e, setAgency)}/>
                            </div>
                            <div>
                                <label>차종</label>
                                <input type="text" id="search_vehicleType" className="search_values" value={vehicleType}
                                       onChange={(e) => onChangeHandler(e, setVehicleType)}/>
                            </div>
                            <div style={{display: "flex", gridColumn: '1/span 2', alignItems: "center"}}>
                                <label>등록일</label>
                                <div id="search_registration_date">
                                    <div style={{margin: '0 8px 0 0'}}><DateInput date={selectedStartDate}
                                                                                  setDate={setSelectedStartDate}/>
                                    </div>
                                    ~
                                    <div style={{margin: '0 0 0 8px'}}><DateInput date={selectedEndDate}
                                                                                  setDate={setSelectedEndDate}/></div>
                                </div>
                            </div>
                        </div>
                        <button id="searchBtn" className="blackBtn" onClick={handleSearch}>검색</button>
                    </div>
            </div>
        )
    } else if (role === 'agency') {
        return (
            <div className="searchBar">
                <button id="search_switch" className="blackBtn"
                        onClick={toggleSearch}>{isSearchOpen ? "검색 항목 닫기" : "검색 항목 열기"}</button>
                    <div className={`search_agency ${isSearchOpen ? "open" : ''}`}>
                        <div className="search_input">
                            <div>
                                <label>딜러사</label>
                                <input type="text" id="search_dealers"
                                       className="search_values" value={dealers}
                                       onChange={(e) => onChangeHandler(e, setDealers)}/>
                            </div>
                            <div>
                                <label>대리점</label>
                                <input type="text" id="search_agency"
                                       className="search_values" value={agency}
                                       onChange={(e) => onChangeHandler(e, setAgency)}/>
                            </div>
                            <div>
                                <label>영업직원</label>
                                <input type="text" id="search_salesPerson"
                                       className="search_values" value={salesPerson}
                                       onChange={(e) => onChangeHandler(e, setSalesPerson)}/>
                            </div>
                            <div>
                                <label>고객명</label>
                                <input type="text" id="search_customerName"
                                       className="search_values" value={customerName}
                                       onChange={(e) => onChangeHandler(e, setCustomerName)}/>
                            </div>
                            <div>
                                <label>차대번호</label>
                                <input type="text" id="search_vehicleNumber"
                                       className="search_values" value={vehicleNumber}
                                       onChange={(e) => onChangeHandler(e, setVehicleNumber)}/>
                            </div>
                            <div>
                                <label>상태</label>
                                <select id="search_status" className="search_values" value={status}
                                        onChange={(e) => onChangeHandler(e, setStatus)}>
                                    <option value="">선택</option>
                                    <option value="승인대기">승인대기</option>
                                    <option value="승인">승인</option>
                                    <option value="반려">반려</option>
                                    <option value="취소">취소</option>
                                    <option value="시공진행">시공진행</option>
                                    <option value="시공완료">시공완료</option>
                                </select>
                            </div>
                            <div style={{display: "flex", gridColumn: '3/span 2', alignItems: "center"}}>
                                <label>등록일</label>
                                <div id="search_registration_date">
                                    <div style={{margin: '0 8px 0 0'}}><DateInput date={selectedStartDate}
                                                                                  setDate={setSelectedStartDate}/>
                                    </div>
                                    ~
                                    <div style={{margin: '0 0 0 8px'}}><DateInput date={selectedEndDate}
                                                                                  setDate={setSelectedEndDate}/></div>
                                </div>
                            </div>
                        </div>
                        <button id="searchBtn" className="blackBtn" onClick={handleSearch}>검색</button>
                    </div>
            </div>
        )
    } else if (role === 'financial') {
        return (
            <div className="searchBar">
                <button id="search_switch" className="blackBtn"
                        onClick={toggleSearch}>{isSearchOpen ? "검색 항목 닫기" : "검색 항목 열기"}</button>
                    <div className={`search_financial ${isSearchOpen ? "open" : ''}`}>
                        <div className="search_input">
                            <div>
                                <label>고객명</label>
                                <input type="text" id="search_customerName"
                                       className="search_values" value={customerName}
                                       onChange={(e) => onChangeHandler(e, setCustomerName)}/>
                            </div>
                            <div>
                                <label>대리점</label>
                                <input type="text" id="search_agency"
                                       className="search_values" value={agency}
                                       onChange={(e) => onChangeHandler(e, setAgency)}/>
                            </div>
                            <div>
                                <label>영업직원</label>
                                <input type="text" id="search_salesPerson"
                                       className="search_values" value={salesPerson}
                                       onChange={(e) => onChangeHandler(e, setSalesPerson)}/>
                            </div>
                            <div style={{display: "flex", gridColumn: '1/span 2', alignItems: "center"}}>
                                <label>등록일</label>
                                <div id="search_registration_date">
                                    <div style={{margin: '0 8px 0 0'}}><DateInput date={selectedStartDate}
                                                                                  setDate={setSelectedStartDate}/>
                                    </div>
                                    ~
                                    <div style={{margin: '0 0 0 8px'}}><DateInput date={selectedEndDate}
                                                                                  setDate={setSelectedEndDate}/></div>
                                </div>
                            </div>
                            <div>
                                <label>상태</label>
                                <select id="search_status" className="search_values" value={status}
                                        onChange={(e) => onChangeHandler(e, setStatus)}>
                                    <option value="">선택</option>
                                    <option value="승인대기">승인대기</option>
                                    <option value="승인">승인</option>
                                    <option value="반려">반려</option>
                                    <option value="취소">취소</option>
                                    <option value="시공진행">시공진행</option>
                                    <option value="시공완료">시공완료</option>
                                </select>
                            </div>
                            {/*<div>*/}
                            {/*    <label>계약번호</label>*/}
                            {/*    <input type="text" id="search_contractNumber"*/}
                            {/*           className="search_values" value={contractNumber}*/}
                            {/*           onChange={(e) => onChangeHandler(e, setContractNumber)}/>*/}
                            {/*</div>*/}
                        </div>
                        <button id="searchBtn" className="blackBtn" onClick={handleSearch}>검색</button>
                    </div>
            </div>
        )
    }
}