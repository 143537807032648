import {useNavigate} from "react-router-dom";
import "../styles/JoinForm.css";
import {useEffect, useState} from "react";
import {idDupCheck, emailSend, emailCheck, signUp, getCodeList, smsSend, smsCheck} from "../api/Api";
import dayjs from "dayjs";
import Modal_SearchAddress from "./Modal_SearchAddress";
import * as React from "react";
import Spinner from "./Spinner";

const JoinForm = () => {
    const navigate = useNavigate();
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const [passwordCheck, setPasswordCheck] = useState("");
    const [name, setName] = useState("");
    const [affList1, setAffList1] = useState([]);
    const [affList2, setAffList2] = useState([]);
    const [affList3, setAffList3] = useState([]);
    const [aff1, setAff1] = useState("");
    const [aff2, setAff2] = useState("");
    const [aff3, setAff3] = useState("");

    const [email, setEmail] = useState("");
    const [emailDomain, setEmailDomain] = useState("");
    const [phone1, setPhone1] = useState("");
    const [phone2, setPhone2] = useState("");
    const [phone3, setPhone3] = useState("");
    const [addressNumber, setAddressNumber] = useState("");
    const [address, setAddress] = useState("");
    const [detailAddress, setDetailAddress] = useState("");
    const [smsAuthNumber, setSmsAuthNumber] = useState("");

    const [smsAuthCheck, setSmsAuthCheck] = useState(false);
    const [smsAuthCheckClicked, setSmsAuthCheckClicked] = useState(false);
    const [duplicateCheck, setDuplicateCheck] = useState(false);
    const [dupCheckButtonClicked, setDupCheckButtonClicked] = useState(false);
    const [smsSendCheck, setSmsSendCheck] = useState(false);
    const [isSmsSending, setIsSmsSending] = useState(false);
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

    const getAff1List = async () => {
        let firstList;
        firstList = await getCodeList('DA');
        return firstList;
    }
    const getAff2List = async (aff1) => {
        const secondList = await getCodeList(aff1);
        return secondList;
    }

    const getAff3List = async (aff2) => {
        const thirdList = await getCodeList(aff2);
        return thirdList;
    }

    const initializer1 = async () => {
        const first_list = await getAff1List();
        setAffList1(first_list);
    }

    useEffect(() => {
        initializer1();
    }, []);

    const initializer2 = async () => {
        setAff2("");
        setAff3("");
        const second_list = await getAff2List(aff1);
        setAffList2(second_list);
    }

    useEffect(() => {
        if(affList1.length > 0) {
            initializer2();
        }
    }, [aff1]);

    const initializer3 = async () => {
        setAff3("");
        const third_list = await getAff3List(aff2);
        setAffList3(third_list);
    }

    useEffect(() => {
        if(affList2.length > 0){
            initializer3();
        }
    }, [aff2]);

    const openAddressModal = (e) => {
        e.preventDefault();
        setIsSearchModalOpen(true);
    }

    const closeAddressModal = () => {
        setIsSearchModalOpen(false);
    }

    const onChangeHandler = (e, setState) => {
        const value = e.target.value;
        setState(value);
    };

    useEffect(() => {
        setDuplicateCheck(false);
        setDupCheckButtonClicked(false);
    }, [id]);

    const duplicateCheckBtnClick = async (e) => {
        e.preventDefault();
        if (id.length === 0) {
            alert("아이디를 입력해주세요.");
            return;
        }
        setDupCheckButtonClicked(true);
        const id_duplication = await idDupCheck({id});
        if (id_duplication === 200) {
            setDuplicateCheck(true);
        } else {
            setDuplicateCheck(false);
        }
    };

    const smsSendBtnClick = async (e) => {
        e.preventDefault();
        if (!(phone1 && phone2 && phone3)) {
            alert("휴대폰 번호를 입력해주세요");
            return;
        }
        setIsSmsSending(true);
        const sms_send = await smsSend(phone1 + "-" + phone2 + "-" + phone3);
        if (sms_send === 200) {
            setSmsSendCheck(true);
        } else {
            setSmsSendCheck(false);
        }
        setIsSmsSending(false);
    };

    const smsAuthCheckBtnClick = async (e) => {
        e.preventDefault();
        if (!smsSendCheck) {
            alert("인증 메세지를 발송한 후 진행해주세요");
            return;
        }
        setSmsAuthCheckClicked(true);
        const sms_check = await smsCheck(phone1 + "-" + phone2 + "-" + phone3, smsAuthNumber);
        if (sms_check === 200) {
            setSmsAuthCheck(true);
        } else {
            setSmsAuthCheck(false);
        }
    };

    const formSubmit = async (e) => {
        e.preventDefault();

        if (id.length === 0) {
            alert("아이디를 입력해주세요.");
            return;
        }

        if (duplicateCheck === false) {
            alert("아이디 중복확인을 해주세요.");
            return;
        }

        if (password.length < 8) {
            alert("비밀번호는 8자리 이상이어야 합니다.");
            return;
        }

        if (password !== passwordCheck) {
            alert("비밀번호가 일치하지 않습니다.");
            return;
        }

        if (name.length === 0) {
            alert("사용자명을 입력해주세요.");
            return;
        }

        if (aff3.length === 0) {
            alert("딜러사/전시장을 입력해주세요.");
            return;
        }
        if ((email && !emailDomain) || (!email && emailDomain)) {
            alert("이메일 주소를 정확히 입력해주세요.");
            return;
        }
        if ((phone1.length === 0) | (phone2.length === 0) | (phone3.length === 0)) {
            alert("휴대전화 번호를 입력해주세요");
            return;
        }

        // if (emailAuthCheck === false) {
        //     alert("이메일 인증을 완료해주세요.");
        //     return;
        // }

        const bodyData = {
            user_id: id,
            password: password,
            name: name,
            email: email + "@" + emailDomain,
            phone_number: phone1 + "-" + phone2 + "-" + phone3,
            address: addressNumber + "/" + address + "/" + detailAddress,
            affiliation_code: aff3,
        };
        const sign_up = await signUp({bodyData});
        if (sign_up === 200) {
            navigate("/login/loginForm");
        }
    };

    return (
        <form className="join-form-wrapper" onSubmit={formSubmit}>
            <div className="join-form-header">회원정보 입력</div>
            <ul>
                <li>
                    <label>ID</label>
                    <div>
                        <input
                            onChange={(e) => {
                                onChangeHandler(e, setId);
                            }}
                        ></input>
                        <button
                            id="duplicateCheckBtn"
                            className="blackBtn"
                            onClick={(e) => {
                                duplicateCheckBtnClick(e);
                            }}
                        >
                            중복 확인
                        </button>
                        <p
                            style={{
                                color: duplicateCheck ? "#2DB400" : "#EA2837",
                                fontSize: "12.8px",
                                margin: "1.6px 6.4px",
                            }}
                        >
                            {dupCheckButtonClicked ? (
                                duplicateCheck ? (
                                    <span>사용 가능한 아이디입니다.</span>
                                ) : (
                                    <span>이미 사용 중인 아이디입니다.</span>
                                )
                            ) : (
                                <></> // 중복확인 버튼을 누르지 않았을 때는 아무것도 표시하지 않음
                            )}
                        </p>
                    </div>
                </li>
                <li>
                    <label>비밀번호</label>
                    <div>
                        <input
                            type="password"
                            onChange={(e) => {
                                onChangeHandler(e, setPassword);
                            }}
                        ></input>
                        {(1 <= password.length) & (password.length < 8) ? (
                            <p
                                style={{
                                    color: "#EA2837",
                                    fontSize: "12.8px",
                                    margin: "1.6px 6.4px",
                                }}
                            >
                                비밀번호는 8자리 이상이어야 합니다.
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>
                </li>
                <li>
                    <label>비밀번호 확인</label>
                    <div>
                        <input
                            type="password"
                            onChange={(e) => {
                                onChangeHandler(e, setPasswordCheck);
                            }}
                        ></input>
                        {(password !== passwordCheck) & (passwordCheck.length !== 0) ? (
                            <p
                                style={{
                                    color: "#EA2837",
                                    fontSize: "12.8px",
                                    margin: "1.6px 6.4px",
                                }}
                            >
                                비밀번호가 일치하지 않습니다.
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>
                </li>
                <li>
                    <label>사용자명</label>
                    <input
                        onChange={(e) => {
                            onChangeHandler(e, setName);
                        }}
                    ></input>
                </li>
                <li>
                    <label>딜러사/전시장</label>
                    <div>
                        <select value={aff1} onChange={(e) => onChangeHandler(e, setAff1)}>
                            <option value="">선택</option>
                            {
                                affList1?.map((item, index) => {
                                    return <option value={item['code_key']}>{item['code_value']}</option>
                                })
                            }
                        </select>
                        <select value={aff2} onChange={(e) => onChangeHandler(e, setAff2)}>
                            <option value="">선택</option>
                            {
                                affList2?.map((item, index) => {
                                    return <option value={item['code_key']}>{item['code_value']}</option>
                                })
                            }
                        </select>
                        <select value={aff3} onChange={(e) => onChangeHandler(e, setAff3)}>
                            <option value="">선택</option>
                            {
                                affList3?.map((item, index) => {
                                    return <option value={item['code_key']}>{item['code_value']}</option>
                                })
                            }
                        </select>
                    </div>
                </li>
                <li>
                    <label>이메일</label>
                    <div>
                        <input
                            onChange={(e) => {
                                onChangeHandler(e, setEmail);
                            }}
                        ></input>
                        <span>@</span>
                        <input
                            onChange={(e) => {
                                onChangeHandler(e, setEmailDomain);
                            }}
                        ></input>
                    </div>
                </li>
                <li>
                    <label>휴대폰번호</label>
                    <div>
                        <input onChange={(e) => onChangeHandler(e, setPhone1)}></input>
                        <span>-</span>
                        <input onChange={(e) => onChangeHandler(e, setPhone2)}></input>
                        <span>-</span>
                        <input onChange={(e) => onChangeHandler(e, setPhone3)}></input>
                        <button
                            id="smsSendBtn"
                            className="blackBtn"
                            onClick={(e) => {
                                smsSendBtnClick(e);
                            }} style={{cursor: isSmsSending ? "not-allowed" : "pointer"}}
                            disabled={isSmsSending}
                        >
                            <div style={{justifyContent: isSmsSending ? "space-between" : "space-evenly"}}>
                                <Spinner loading={isSmsSending}/>
                                <span>휴대폰 인증</span>
                            </div>
                        </button>
                        <p
                            style={{
                                color: smsSendCheck ? "#2DB400" : "#EA2837",
                                fontSize: "12.8px",
                                margin: "1.6px 6.4px",
                            }}
                        >
                            {smsSendCheck ? <span>인증 메세지가 발송되었습니다.</span> : <span>입력하신 휴대폰 번호로 인증 메세지가 발송됩니다.</span>}
                        </p>
                    </div>
                </li>
                <li>
                    <label>휴대폰 인증</label>
                    <div>
                        <input
                            onChange={(e) => {
                                onChangeHandler(e, setSmsAuthNumber);
                            }}
                        ></input>
                        <button
                            id="smsAuthCheckBtn"
                            className="blackBtn"
                            onClick={(e) => {
                                smsAuthCheckBtnClick(e);
                            }}
                        >
                            인증번호 확인
                        </button>
                        <p
                            style={{
                                color: smsAuthCheck ? "#2DB400" : "#EA2837",
                                fontSize: "12.8px",
                                margin: "1.6px 6.4px",
                            }}
                        >
                            {smsAuthCheckClicked ? (
                                smsAuthCheck ? (
                                    <span>인증이 완료되었습니다.</span>
                                ) : (
                                    <span>인증번호를 다시 확인해주세요.</span>
                                )
                            ) : (
                                <></>
                            )}
                        </p>
                    </div>
                </li>
                <li>
                    <label>주소</label>
                    <div id="addressInput">
                        <div>
                            <input
                                value={addressNumber}
                                onChange={(e) => {
                                    onChangeHandler(e, setAddressNumber);
                                }}
                                placeholder="우편번호"
                            />
                            <button
                                id="addressSearchBtn"
                                className="blackBtn"
                                onClick={(e) => openAddressModal(e)}
                            >
                                주소검색
                            </button>
                        </div>
                        <input
                            placeholder="주소"
                            value={address}
                            onChange={(e) => {
                                onChangeHandler(e, setAddress);
                            }}
                        />
                        <input
                            placeholder="상세주소"
                            value={detailAddress}
                            onChange={(e) => {
                                onChangeHandler(e, setDetailAddress);
                            }}
                        />
                    </div>
                </li>
            </ul>
            <div className="joinFormController">
                <button
                    onClick={(e) => {
                        const cancelConfirmed = window.confirm(
                            "입력한 데이터가 저장되지 않을 수 있습니다. 정말로 떠나시겠습니까?"
                        );
                        if (cancelConfirmed) {
                            navigate("/");
                        } else {
                            e.preventDefault();
                        }
                    }}
                    className="grayBtn"
                >
                    취소
                </button>
                <button className="blackBtn" onClick={formSubmit}>
                    회원가입
                </button>
            </div>
            {
                isSearchModalOpen && <Modal_SearchAddress showModal={isSearchModalOpen} onClose={closeAddressModal}
                                                          setAddressNumber={setAddressNumber}
                                                          setAddressText={setAddress}
                                                          setDetailAddress={setDetailAddress}/>
            }
        </form>
    );
};
export default JoinForm;

