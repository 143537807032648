// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
    width: 80%;
    margin: 0 auto;
}

#writeRequestBtn, #writePopupBtn {
    display: flex;
    justify-content: flex-start;
    margin: 16px 0 16px 16px;
    padding: 7px 24px;
}

.printExcelBtn {
    display: flex;
    width: 100%;
    margin: 16px auto 16px auto;
    justify-content: right;
}

.printExcelBtn > button {
    background-color: green;
    color: white;
    padding: 6.4px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.printExcelBtn img {
    width: 19.2px;
    margin-right: 4.8px;
}

.printExcelBtn span:hover {
    font-weight: bold;
}

#userRegBtn {
    display: flex;
    justify-content: flex-start;
    margin: 16px 0 0 16px;
    padding: 7px 24px;
}

#saveBtn {
    font-size: 14.4px;
    padding: 4.8px 16px;
    width: 96px;
}

@media screen and (max-width: 600px){
    .page{
        width: 100%;
    }
    .printExcelBtn{
        width: 98%;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Page.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,wBAAwB;IACxB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,0CAA0C;IAC1C,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI;QACI,WAAW;IACf;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":[".page {\n    width: 80%;\n    margin: 0 auto;\n}\n\n#writeRequestBtn, #writePopupBtn {\n    display: flex;\n    justify-content: flex-start;\n    margin: 16px 0 16px 16px;\n    padding: 7px 24px;\n}\n\n.printExcelBtn {\n    display: flex;\n    width: 100%;\n    margin: 16px auto 16px auto;\n    justify-content: right;\n}\n\n.printExcelBtn > button {\n    background-color: green;\n    color: white;\n    padding: 6.4px 8px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);\n    border-radius: 4px;\n}\n\n.printExcelBtn img {\n    width: 19.2px;\n    margin-right: 4.8px;\n}\n\n.printExcelBtn span:hover {\n    font-weight: bold;\n}\n\n#userRegBtn {\n    display: flex;\n    justify-content: flex-start;\n    margin: 16px 0 0 16px;\n    padding: 7px 24px;\n}\n\n#saveBtn {\n    font-size: 14.4px;\n    padding: 4.8px 16px;\n    width: 96px;\n}\n\n@media screen and (max-width: 600px){\n    .page{\n        width: 100%;\n    }\n    .printExcelBtn{\n        width: 98%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
