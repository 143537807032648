import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import "../styles/TextEditor.css";
import {useEffect} from "react";
export default function TextEditor({text, setText, modules={}, formats={}, readOnly=false, resize="none"}){
    return(
    <div>
        <ReactQuill modules={modules} formats={formats} value={text} onChange={setText} readOnly={readOnly} style={{resize:resize}}/>
    </div>
    )
}