// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#resetPwModal-wrapper {
    background-color: rgba(0, 0, 0, 0.2);
}

#resetPwModal {
    width: 200px;
    height: 250px;
    border: 1px solid black;
}

#resetPwModal .content {
    display: flex;
    flex-direction: column;
    height: 80%;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    overflow: hidden;
}

#resetPwModal input {
    width: 96%;
    height: 22.4px;
    padding: 3px 4px;
    border: 1px solid #dcdcdc;
}

@media screen and (max-width: 900px) {
    #resetPwModal {
        height: 95%;
        max-height: 250px;
    }
}

@media screen and (max-width: 600px) {
    #resetPwModal {
        height: 80%;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Modal_ResetPassword.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,cAAc;IACd,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI;QACI,WAAW;QACX,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":["#resetPwModal-wrapper {\n    background-color: rgba(0, 0, 0, 0.2);\n}\n\n#resetPwModal {\n    width: 200px;\n    height: 250px;\n    border: 1px solid black;\n}\n\n#resetPwModal .content {\n    display: flex;\n    flex-direction: column;\n    height: 80%;\n    align-items: center;\n    justify-content: space-between;\n    padding: 16px;\n    overflow: hidden;\n}\n\n#resetPwModal input {\n    width: 96%;\n    height: 22.4px;\n    padding: 3px 4px;\n    border: 1px solid #dcdcdc;\n}\n\n@media screen and (max-width: 900px) {\n    #resetPwModal {\n        height: 95%;\n        max-height: 250px;\n    }\n}\n\n@media screen and (max-width: 600px) {\n    #resetPwModal {\n        height: 80%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
