import {useEffect, useState} from "react";
import Paging from "./Paging";
import "../styles/Table.css";
import {GetInventoryLog} from "../api/Api";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";

export default function InventoryLog({searchCondition, currentPage, setCurrentPage}) {
    const tableHeader = [
        {text: '번호', value: 'index'},
        {text: '사용자ID', value: 'user_id'},
        {text: '사용자명', value: 'name'},
        {text: '소속', value: 'affiliation_value'},
        {text: '제품', value: 'product_value'},
        {text: '입고량', value: 'income_quantity'},
        {text: '출고량', value: 'outcome_quantity'},
        {text: '등록일', value: 'registration_date'}
    ]
    const navigate = useNavigate();
    const [inventoryData, setInventoryData] = useState([]);
    const headerKey = tableHeader.map((header) => header.value);
    const itemsPerPage = 10; // 페이지 당 요청서 개수
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [lastPageNumber, setLastPageNumber] = useState(1);
    const getInventory = async (searchCondition = {}, currentPageNumber = 1) => {
        const inventoryLog = await GetInventoryLog(searchCondition, currentPageNumber);
        if(inventoryLog?.status === 200){
            setInventoryData(inventoryLog.data.inventory_logs);
            setLastPageNumber(inventoryLog.data.total_page)
        } else if(inventoryLog?.status === 401){
            navigate("/");
        }
    }

    useEffect(() => {
        getInventory(searchCondition, currentPage);
    }, [searchCondition, currentPage]);

    const handlePageChange = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
    };
    return (
        <div>
            {inventoryData.length > 0 ? (
                <div>
                    <table className="list_table">
                        <thead>
                        <tr className="table-header">
                            {
                                tableHeader.map((item) =>
                                    <th key={item.text}>
                                        {item.text}
                                    </th>
                                )
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            inventoryData.map((item, index) => (
                                <tr key={index} className="table-body">
                                    {
                                        headerKey.map((key) =>
                                            <td key={key + index}>
                                                {key === 'index' ? indexOfFirstItem + index + 1 : key === 'registration_date' ? dayjs(item[key] * 1000).format("YYYY-MM-DD") : item[key]}
                                            </td>)
                                    }
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                    <Paging currentPage={currentPage} lastPage={lastPageNumber} handlePageChange={handlePageChange}/>
                </div>
            ) : (<div className="no_data_exist">
                <h3>재고 내역이 존재하지 않습니다.</h3>
            </div>)}
        </div>
    )
}