import {useEffect, useState} from "react";
import Paging from "./Paging";
import Modal_UserDetails from "./Modal_UserDetails";
import {getUserList} from "../api/Api";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";

export default function UserList({searchCondition, reRender, setReRender, currentPage, setCurrentPage}) {
    const [isUserDetailModalOpen, setIsUserDetailModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null); // 클릭한 사용자 데이터
    const [userData, setUserData] = useState([]);
    const tableHeader = [
        {text: '번호', value: 'index'},
        {text: 'ID', value: 'user_id'},
        {text: '사용자명', value: 'name'},
        {text: '그룹', value: 'group'},
        {text: '소속', value: 'affiliation_value'},
        {text: '등록일', value: 'registration_date'}
    ]
    const navigate = useNavigate();
    const headerKey = tableHeader.map((header) => header.value);
    // 페이지당 데이터 계산
    const itemsPerPage = 10; // 페이지 당 요청서 개수
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [lastPageNumber, setLastPageNumber] = useState(1);
    const getUsers = async (searchCondition = {}, currentPageNumber = 1) => {
        let userList;
        userList = await getUserList(searchCondition, currentPageNumber);
        if (userList?.status === 200) {
            setUserData(userList.data.users);
            setLastPageNumber(userList.data.total_page);
        } else if(userList?.status === 401){
            navigate("/");
        }
    }

    useEffect(() => {
        getUsers(searchCondition, currentPage);
    }, [reRender, currentPage, searchCondition]);


    const handlePageChange = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
    };

    const openUserDetailModal = (item) => {
        setSelectedItem(item);
        setIsUserDetailModalOpen(true);
    };

    const closeUserDetailModal = () => {
        setIsUserDetailModalOpen(false);
    };

    return (
        <div>
            {userData.length > 0 ? (
                <div>
                    <table className="list_table">
                        <thead>
                        <tr className="table-header">
                            {
                                tableHeader.map((item) =>
                                    <th key={item.text}>
                                        {item.text}
                                    </th>
                                )
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            userData.map((item, index) => (
                                <tr key={index} className="table-body" style={{cursor: "pointer"}}
                                    onClick={() => openUserDetailModal(item)}>
                                    {
                                        headerKey.map((key) =>
                                            <td key={key + index}>
                                                {key === 'index' ? indexOfFirstItem + index + 1 :
                                                    key === 'group' ? (
                                                        (() => {
                                                            const authorityType = item.authority_type;
                                                            switch (authorityType) {
                                                                case 'admin':
                                                                    return '본사';
                                                                case 'dealer':
                                                                    return '지점';
                                                                case 'agency':
                                                                    return '대리점';
                                                                case 'financial':
                                                                    return '지점장';
                                                                default:
                                                                    return '';
                                                            }
                                                        })()
                                                    ) : key === 'affiliation_value' ? item[key].split("/").slice(1).join("/") : key === 'registration_date' ? dayjs(item[key] * 1000).format("YYYY-MM-DD") : item[key]
                                                }
                                            </td>
                                        )
                                    }
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                    <Paging currentPage={currentPage} lastPage={lastPageNumber} handlePageChange={handlePageChange}/>
                </div>
            ) : (<div className="no_data_exist">
                <h3>사용자 정보가 존재하지 않습니다.</h3>
            </div>)}
            {
                isUserDetailModalOpen &&
                <Modal_UserDetails showModal={isUserDetailModalOpen} onClose={closeUserDetailModal}
                                   user={selectedItem} reRender={reRender} setReRender={setReRender}/>
            }
        </div>
    )
}