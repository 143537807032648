// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333;
    border-radius: 50%;
    width: 40px;
    margin: 0 auto;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Spinner.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,uBAAuB;IACvB,kBAAkB;IAClB,WAAW;IACX,cAAc;IACd,YAAY;IACZ,kCAAkC;AACtC;;AAEA;IACI;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".spinner {\n    border: 4px solid rgba(0, 0, 0, 0.1);\n    border-left-color: #333;\n    border-radius: 50%;\n    width: 40px;\n    margin: 0 auto;\n    height: 40px;\n    animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n    to {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
