import "../styles/CodeManagement.css";
import {useEffect, useState} from "react";
import Modal_CodeRegister from "./Modal_CodeRegister";
import {deleteCode, getCodeList} from "../api/Api";
import {useNavigate} from "react-router-dom";
import Modal_ModifyCodeValue from "./Modal_ModifyCodeValue";

export default function CodeManagement() {
    const tableHeader = [
        {text: "코드", value: "code_key"},
        {text: "코드값", value: "code_value"}
    ]
    const navigate = useNavigate();
    const headerKey = tableHeader.map((header) => header.value);
    const [isCodeRegModalOpen, setIsCodeRegModalOpen] = useState(false);
    const [isCodeModModalOpen, setIsCodeModModalOpen] = useState(false);
    const [lv1, setLv1] = useState([]);
    const [lv2, setLv2] = useState([]);
    const [lv3, setLv3] = useState([]);
    const [lv4, setLv4] = useState([]);
    const [selectedLv1, setSelectedLv1] = useState(""); // 선택된 level 1 코드
    const [selectedLv2, setSelectedLv2] = useState(""); // 선택된 level 2 코드
    const [selectedLv3, setSelectedLv3] = useState(""); // 선택된 level 3 코드
    const [selectedParentCode, setSelectedParentCode] = useState(""); // 등록 버튼을 누른 부모 코드 식별
    const [selectedLevel, setSelectedLevel] = useState(0);
    const [selectedCode, setSelectedCode] = useState({code_key: "", code_value: ""});

    const itemKey = 'code_key';
    const [selection1, setSelection1] = useState(new Set());
    const [selection2, setSelection2] = useState(new Set());
    const [selection3, setSelection3] = useState(new Set());
    const [selection4, setSelection4] = useState(new Set());

    const [reRender, setReRender] = useState(false);

    const getCodes = async (setList, code) => {
        const codeList = await getCodeList(code);
        setList(codeList);
    }

    useEffect(() => {
        getCodes(setLv1, 'root');
        setSelection1(new Set());
        setSelection2(new Set());
        setSelection3(new Set());
        setSelection4(new Set());
        setSelectedLv1("");
        setSelectedLv2("");
        setSelectedLv3("");
        setSelectedParentCode("");
        setLv2([]);
        setLv3([]);
        setLv4([]);

    }, []);
    const onChangeSelect = (value, level) => {
        let newSelection;
        switch (level) {
            case 1:
                newSelection = new Set(selection1);
                if (newSelection.has(value)) {
                    newSelection.delete(value);
                } else {
                    newSelection.add(value);
                }
                setSelection1(newSelection);
                break;
            case 2:
                newSelection = new Set(selection2);
                if (newSelection.has(value)) {
                    newSelection.delete(value);
                } else {
                    newSelection.add(value);
                }
                setSelection2(newSelection);
                break;
            case 3:
                newSelection = new Set(selection3);
                if (newSelection.has(value)) {
                    newSelection.delete(value);
                } else {
                    newSelection.add(value);
                }
                setSelection3(newSelection);
                break;
            case 4:
                newSelection = new Set(selection4);
                if (newSelection.has(value)) {
                    newSelection.delete(value);
                } else {
                    newSelection.add(value);
                }
                setSelection4(newSelection);
                break;
            default:
                break;
        }
    };

    // disabled가 true인 item만 반환하는 함수
    const getAbledItems = (items) => {
        return items.filter(({disabled}) => !disabled);
    };
    const onChangeSelectAll = (e, level) => {
        switch (level) {
            case 1:
                if (e.target.checked) {
                    // checked가 true인 경우 전체 선택
                    const allCheckedSelection = new Set(
                        // 활성화된 행의 배열을 순회하며 itemKey로 요소에 접근해 데이터를 저장
                        getAbledItems(lv1).map((item) => item[itemKey])
                    );
                    setSelection1(allCheckedSelection);
                } else {
                    // checked가 false인 경우 전체 선택 해제
                    setSelection1(new Set());
                }
                break;
            case 2:
                if (e.target.checked) {
                    // checked가 true인 경우 전체 선택
                    const allCheckedSelection = new Set(
                        // 활성화된 행의 배열을 순회하며 itemKey로 요소에 접근해 데이터를 저장
                        getAbledItems(lv2).map((item) => item[itemKey])
                    );
                    setSelection2(allCheckedSelection);
                } else {
                    // checked가 false인 경우 전체 선택 해제
                    setSelection2(new Set());
                }
                break;
            case 3:
                if (e.target.checked) {
                    // checked가 true인 경우 전체 선택
                    const allCheckedSelection = new Set(
                        // 활성화된 행의 배열을 순회하며 itemKey로 요소에 접근해 데이터를 저장
                        getAbledItems(lv3).map((item) => item[itemKey])
                    );
                    setSelection3(allCheckedSelection);
                } else {
                    // checked가 false인 경우 전체 선택 해제
                    setSelection3(new Set());
                }
                break;
            case 4:
                if (e.target.checked) {
                    // checked가 true인 경우 전체 선택
                    const allCheckedSelection = new Set(
                        // 활성화된 행의 배열을 순회하며 itemKey로 요소에 접근해 데이터를 저장
                        getAbledItems(lv4).map((item) => item[itemKey])
                    );
                    setSelection4(allCheckedSelection);
                } else {
                    // checked가 false인 경우 전체 선택 해제
                    setSelection4(new Set());
                }
                break;
            default:
                break;
        }
    };
    // 전체 선택 상태 여부
    const isSelectedAll = (level) => {
        switch (level) {
            case 1:
                if (selection1.size === 0) {
                    return false;
                }
                return selection1.size === getAbledItems(lv1).length;
            case 2:
                if (selection2.size === 0) {
                    return false;
                }
                return selection2.size === getAbledItems(lv2).length;
            case 3:
                if (selection3.size === 0) {
                    return false;
                }
                return selection3.size === getAbledItems(lv3).length;
            case 4:
                if (selection4.size === 0) {
                    return false;
                }
                return selection4.size === getAbledItems(lv4).length;
            default:
                break;
        }
    };
    const lv1Select = (item, index) => {
        if(selectedLv1 !== item){
            setSelectedLv1(item);
            getCodes(setLv2, item.code_key);
            const allTableBodies = document.querySelectorAll('[id^="table-body1_"]');
            allTableBodies.forEach(body => {
                body.style.backgroundColor = "white";  // 초기 스타일로 변경
            });
            document.getElementById(`table-body1_${index}`).style.backgroundColor = "lightgray";
        }
    }
    const lv2Select = (item, index) => {
        if(selectedLv2 !== item){
            setSelectedLv2(item);
            getCodes(setLv3, item.code_key);
            const allTableBodies = document.querySelectorAll('[id^="table-body2_"]');
            allTableBodies.forEach(body => {
                body.style.backgroundColor = "white";  // 초기 스타일로 변경
            });
            document.getElementById(`table-body2_${index}`).style.backgroundColor = "lightgray";
        }
    }
    const lv3Select = (item, index) => {
        if(selectedLv3 !== item){
            setSelectedLv3(item);
            getCodes(setLv4, item.code_key);
            const allTableBodies = document.querySelectorAll('[id^="table-body3_"]');
            allTableBodies.forEach(body => {
                body.style.backgroundColor = "white";  // 초기 스타일로 변경
            });
            document.getElementById(`table-body3_${index}`).style.backgroundColor = "lightgray";
        }

    }

    const lv4Select = (index) => {
        const allTableBodies = document.querySelectorAll('[id^="table-body4_"]');
        allTableBodies.forEach(body => {
            body.style.backgroundColor = "white";  // 초기 스타일로 변경
        });
        document.getElementById(`table-body4_${index}`).style.backgroundColor = "lightgray";
    }

    const deleteSelection = async (level) => {
        let code = [];
        switch (level) {
            case 1:
                if (selection1.size === 0) {
                    alert("삭제하실 코드를 선택해주세요.");
                    return;
                }
                selection1.forEach((item) => (code = [...code, item]));
                break;
            case 2:
                if (selection2.size === 0) {
                    alert("삭제하실 코드를 선택해주세요.");
                    return;
                }
                selection2.forEach((item) => (code = [...code, item]));
                break;
            case 3:
                if (selection3.size === 0) {
                    alert("삭제하실 코드를 선택해주세요.");
                    return;
                }
                selection3.forEach((item) => (code = [...code, item]));
                break;
            case 4:
                if (selection4.size === 0) {
                    alert("삭제하실 코드를 선택해주세요.");
                    return;
                }
                selection4.forEach((item) => (code = [...code, item]));
                break;
            default:
                break;
        }
        const isConfirmed = window.confirm("선택하신 코드를 삭제하시겠습니까?");
        if (isConfirmed) {
            const deletion = await deleteCode(code);
            if (deletion === 200) {
                alert("코드가 삭제되었습니다.");
                switch (level) {
                    case 1:
                        await getCodes(setLv1, 'root');
                        setSelection1(new Set());
                        break;
                    case 2:
                        await getCodes(setLv2, selectedLv1.code_key);
                        setSelection2(new Set());
                        break;
                    case 3:
                        await getCodes(setLv3, selectedLv2.code_key);
                        setSelection3(new Set());
                        break;
                    case 4:
                        await getCodes(setLv4, selectedLv3.code_key);
                        setSelection4(new Set());
                        break;
                    default:
                        break;
                }
            } else if (deletion === 401) {
                navigate("/");
            }
        }
    }

    useEffect(() => {
        setLv3([]);
        setLv4([]);
        setSelectedLv2("");
        setSelectedLv3("");
        setSelection2(new Set());
        setSelection3(new Set());
        setSelection4(new Set());
        const allTableBodies = document.querySelectorAll('[id^="table-body2_"]');
        allTableBodies.forEach(body => {
            body.style.backgroundColor = "white";  // 초기 스타일로 변경
        });
    }, [lv2]);

    useEffect(() => {
        setLv4([]);
        setSelection3(new Set());
        setSelection4(new Set());
        setSelectedLv3("");
        const allTableBodies = document.querySelectorAll('[id^="table-body3_"]');
        allTableBodies.forEach(body => {
            body.style.backgroundColor = "white";  // 초기 스타일로 변경
        });
    }, [lv3]);

    const openCodeRegModal = (code, level) => {
        if (code === "") {
            alert("상위 레벨의 코드를 선택하여 주시기 바랍니다.");
            return;
        }
        setIsCodeRegModalOpen(true);
        setSelectedParentCode(code);
        setSelectedLevel(level);
    };
    const openCodeModModal = (item) => {
        setSelectedCode({code_key: item.code_key, code_value: item.code_value});
        setIsCodeModModalOpen(true);
    }
    const closeModal = (setIsModalOpen) => {
        setIsModalOpen(false);
    };

    return (
        <div className="page">
            <span style={{display: "flex", justifyContent: "flex-end", padding: '4px 8px 0 0'}}>* 코드값을 수정하시려면 해당 코드를 더블클릭해 주세요.</span>
            <div className="codeManagement">
                <div className="codeLevel" id="codeLevel1">
                    <div className="codeLevelHeader">
                        <p>LEVEL 1</p>
                        {/*<div className="codeLevelBtns">*/}
                        {/*    <button className="deleteSelectBtn"><span></span></button>*/}
                        {/*    <button className="codeRegBtn"><span></span></button>*/}
                        {/*</div>*/}
                    </div>
                    <div className="codeLevelBody">
                        <table className="list_table">
                            <thead>
                            <tr className="table-header">
                                {
                                    <th><input type="checkbox" checked={isSelectedAll(1)}
                                               onChange={(e) => onChangeSelectAll(e, 1)}/></th>
                                }
                                {
                                    tableHeader.map((header) =>
                                        <th key={header.text}>
                                            {header.text}
                                        </th>
                                    )
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                lv1 && lv1.map((item, index) => (
                                    <tr key={index} className="table-body" onClick={() => lv1Select(item, index)}
                                        id={`table-body1_${index}`}>
                                        {
                                            <td>
                                                <input type="checkbox" disabled={item.disabled}
                                                       checked={selection1.has(item[itemKey])}
                                                       onChange={() => onChangeSelect(item[itemKey], 1)}/>
                                            </td>
                                        }
                                        {
                                            headerKey.map((key) =>
                                                <td key={key + index} style={{cursor: "pointer"}}>
                                                    {item[key]}
                                                </td>)
                                        }
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="codeLevel" id="codeLevel2">
                    <div className="codeLevelHeader">
                        <p>LEVEL 2</p>
                        <div className="codeLevelBtns">
                            <button className="deleteSelectBtn" onClick={() => deleteSelection(2)}>선택삭제</button>
                            <button className="codeRegBtn" onClick={() => openCodeRegModal(selectedLv1, 2)}>등록</button>
                        </div>
                    </div>
                    <div className="codeLevelBody">
                        <table className="list_table">
                            <thead>
                            <tr className="table-header">
                                {
                                    <th><input type="checkbox" checked={isSelectedAll(2)}
                                               onChange={(e) => onChangeSelectAll(e, 2)}/></th>
                                }
                                {
                                    tableHeader.map((header) =>
                                        <th key={header.text}>
                                            {header.text}
                                        </th>
                                    )
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                lv2 && lv2.map((item, index) => (
                                    <tr key={index} className="table-body" onClick={() => lv2Select(item, index)}
                                        id={`table-body2_${index}`}>
                                        {
                                            <td>
                                                <input type="checkbox" disabled={item.disabled}
                                                       checked={selection2.has(item[itemKey])}
                                                       onChange={() => onChangeSelect(item[itemKey], 2)}/>
                                            </td>
                                        }
                                        {
                                            headerKey.map((key) =>
                                                <td key={key + index} onDoubleClick={() => {
                                                    openCodeModModal(item);
                                                    setSelectedParentCode(selectedLv1);
                                                    setSelectedLevel(2)
                                                }}
                                                    style={{cursor: "pointer"}}>
                                                    {item[key]}
                                                </td>)
                                        }
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="codeLevel" id="codeLevel3">
                    <div className="codeLevelHeader">
                        <p>LEVEL 3</p>
                        <div className="codeLevelBtns">
                            <button className="deleteSelectBtn" onClick={() => deleteSelection(3)}>선택삭제</button>
                            <button className="codeRegBtn" onClick={() => openCodeRegModal(selectedLv2, 3)}>등록</button>
                        </div>
                    </div>
                    <div className="codeLevelBody">
                        <table className="list_table">
                            <thead>
                            <tr className="table-header">
                                {
                                    <th><input type="checkbox" checked={isSelectedAll(3)}
                                               onChange={(e) => onChangeSelectAll(e, 3)}/></th>
                                }
                                {
                                    tableHeader.map((header) =>
                                        <th key={header.text}>
                                            {header.text}
                                        </th>
                                    )
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                lv3 && lv3.map((item, index) => (
                                    <tr key={index} className="table-body" onClick={() => lv3Select(item, index)}
                                        id={`table-body3_${index}`}>
                                        {
                                            <td>
                                                <input type="checkbox" disabled={item.disabled}
                                                       checked={selection3.has(item[itemKey])}
                                                       onChange={() => onChangeSelect(item[itemKey], 3)}/>
                                            </td>
                                        }
                                        {
                                            headerKey.map((key) =>
                                                <td key={key + index} onDoubleClick={() => {
                                                    setSelectedLevel(3);
                                                    setSelectedParentCode(selectedLv2);
                                                    openCodeModModal(item);
                                                }}
                                                    style={{cursor: "pointer"}}>
                                                    {item[key]}
                                                </td>)
                                        }
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="codeLevel" id="codeLevel4">
                    <div className="codeLevelHeader">
                        <p>LEVEL 4</p>
                        <div className="codeLevelBtns">
                            <button className="deleteSelectBtn" onClick={() => deleteSelection(4)}>선택삭제</button>
                            <button className="codeRegBtn" onClick={() => openCodeRegModal(selectedLv3, 4)}>등록</button>
                        </div>
                    </div>
                    <div className="codeLevelBody">
                        <table className="list_table">
                            <thead>
                            <tr className="table-header">
                                {
                                    <th><input type="checkbox" checked={isSelectedAll(4)}
                                               onChange={(e) => onChangeSelectAll(e, 4)}/></th>
                                }
                                {
                                    tableHeader.map((header) =>
                                        <th key={header.text}>
                                            {header.text}
                                        </th>
                                    )
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                lv4.map((item, index) => (
                                    <tr key={index} className="table-body" id={`table-body4_${index}`}
                                        onClick={() => lv4Select(index)}>
                                        {
                                            <td>
                                                <input type="checkbox" disabled={item.disabled}
                                                       checked={selection4.has(item[itemKey])}
                                                       onChange={() => onChangeSelect(item[itemKey], 4)}/>
                                            </td>
                                        }
                                        {
                                            headerKey.map((key) =>
                                                <td key={key + index} onDoubleClick={() => {
                                                    openCodeModModal(item);
                                                    setSelectedParentCode(selectedLv3);
                                                    setSelectedLevel(4)
                                                }}
                                                    style={{cursor: "pointer"}}>
                                                    {item[key]}
                                                </td>)
                                        }
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                {
                    isCodeModModalOpen &&
                    <Modal_ModifyCodeValue code_key={selectedCode.code_key} code_value={selectedCode.code_value}
                                           onClose={() => closeModal(setIsCodeModModalOpen)} level={selectedParentCode}
                                           getCodes={getCodes}
                                           setLv={selectedLevel === 1 ? setLv1 : selectedLevel === 2 ? setLv2 : selectedLevel === 3 ? setLv3 : selectedLevel === 4 ? setLv4 : 0}/>
                }
                {
                    isCodeRegModalOpen &&
                    <Modal_CodeRegister showModal={isCodeRegModalOpen} onClose={() => closeModal(setIsCodeRegModalOpen)}
                                        level={selectedParentCode} getCodes={getCodes}
                                        setLv={selectedLevel === 1 ? setLv1 : selectedLevel === 2 ? setLv2 : selectedLevel === 3 ? setLv3 : selectedLevel === 4 ? setLv4 : 0}/>
                }
            </div>
        </div>
    )
}