import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Client from "./components/Client";
import LogInPage from "./components/LogInPage";
import JoinTerms from "./components/JoinTerms";
import JoinForm from "./components/JoinForm";
import RequestListPage from "./page/RequestListPage";
import MyInfo from "./components/MyInfo";
import UserManagementPage from "./page/UserManagementPage";
import CodeManagement from "./components/CodeManagement";
import InventoryLogPage from "./page/InventoryLogPage";
import GuaranteeListPage from "./page/GuaranteeListPage";
import NoticePage from "./page/NoticePage";
import PopupManagementPage from "./page/PopupManagementPage";
import {useRecoilValue} from "recoil";
import {IsLogin, userInfo} from "./components/UserInfo";
import FindUserInfo from "./components/FindUserInfo";


function App() {
    const user = useRecoilValue(userInfo);
    const isLogin = useRecoilValue(IsLogin);
    return (
        <div className="App">
            <BrowserRouter>
                <Header user={user}/>
                <Routes>
                    <Route path="/" element={<Client/>}/>
                    <Route path="/login/loginForm" element={isLogin ? <Navigate to="/req/list"/> : <LogInPage/>}/>
                    <Route path="/join/search" element={<FindUserInfo/>}/>
                    <Route path="/join/terms" element={<JoinTerms/>}/>
                    <Route path="/join/form" element={<JoinForm/>}/>
                    <Route
                        path="/myInfo/info"
                        element={isLogin ? user.authority_type !== 'admin' ? <MyInfo user={user}/> : <Navigate to="/"/> : <Navigate to="/"/>}
                    />
                    <Route path="/req/list" element={isLogin ? <RequestListPage user={user}/> :
                        <Navigate to="/login/loginForm"/>}/>
                    <Route path="/grant/list"
                           element={isLogin ? user.authority_type === 'admin' ? <GuaranteeListPage/> : <Navigate to ="/"/> : <Navigate to="/"/>}/>
                    <Route path="/req/stock/list"
                           element={isLogin ? user.authority_type === 'admin' ? <InventoryLogPage/> : <Navigate to ="/"/> : <Navigate to="/"/>}/>
                    <Route path="/admin/user/list"
                           element={isLogin ? user.authority_type === 'admin' ? <UserManagementPage user={user}/> : <Navigate to ="/"/> : <Navigate to="/"/>}/>
                    <Route path="/code/info" element={isLogin ? user.authority_type === 'admin' ? <CodeManagement/> : <Navigate to="/"/> : <Navigate to ="/"/>}/>
                    <Route path="/popup/list"
                           element={isLogin ? user.authority_type === 'admin' ? <PopupManagementPage/> : <Navigate to="/"/> : <Navigate to="/"/>}/>
                    <Route path="/req/noti/list" element={isLogin ? user.authority_type === 'admin' ? <NoticePage/> : <Navigate to="/"/> : <Navigate to="/"/>}/>
                </Routes>
                <Footer/>
            </BrowserRouter>
        </div>
    );
}

export default App;
