import * as React from 'react';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/system';

const ModifiedContainer = styled('div')`
    .MuiStack-root {
        padding-top: 0;
        max-width: 160px;
        background-color: white;
        overflow: hidden;
    }

    .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
        padding: 0;
        font-size: 24px;
    }

    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        padding: 0;
        border-radius: 0;
        //background-color: white;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.15008px;
        //border: 1px solid rgba(0,0,0,0.23);
        width: 150px;
        justify-content: space-evenly;
    }

    .MuiSvgIcon-root {
        /* 아이콘 크기, 색상, 기타 스타일 속성 설정 */
        width: 22px;
        height: 22px;
    }

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        width: 158px;
        padding: 0;
    }
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
        width: 100%;
        justify-content: flex-start;
    }
    .css-4jnixx-MuiStack-root>.MuiTextField-root{
        width: 100%;
        min-width: 0;
    }

    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #1976d2;
        border-width: 1px;
    }
    @media screen and (max-width: 1000px){
        .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
            width: auto;
        }
        
        .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
            display: block;
        }
    }
    
`;

export default function DateInput({date, setDate, disabled = false}) {

    return (
        <ModifiedContainer>
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                    <DatePicker
                        format={"YYYY-MM-DD"}
                        value={date}
                        disabled={disabled}
                        onChange={(newValue) => setDate(newValue)}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </div></ModifiedContainer>
    )
}