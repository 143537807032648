// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client {
    padding: 160px 0;
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .clientLogo {
        width: 150px;
    }
}

@media screen and (max-width: 280px) {
    .clientLogo {
        width: 100px;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Client.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".client {\n    padding: 160px 0;\n    cursor: pointer;\n}\n\n@media screen and (max-width: 900px) {\n    .clientLogo {\n        width: 150px;\n    }\n}\n\n@media screen and (max-width: 280px) {\n    .clientLogo {\n        width: 100px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
