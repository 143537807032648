import SearchBar_Request from "../components/SearchBar_Request";
import RequestList from "../components/RequestList";
import excelIcon from "../free-icon-sheet-11891828.png";
import {useEffect, useState} from "react";
import Modal_RequestWrite from "../components/Modal_RequestWrite";
import "../styles/Page.css";
import {getNoticeUTIL, getRequestExcel} from "../api/Api";
import {useNavigate} from "react-router-dom";
import Modal_Notice from "../components/Modal_Notice";

export default function RequestListPage({user}) {
    const navigate = useNavigate();
    const [searchCondition, setSearchCondition] = useState({});
    const [isRequestWriteModalOpen, setIsRequestWriteModalOpen] = useState(false);
    const [isNoticeModalOpen, setIsNoticeModalOpen] = useState(false);
    const [noti, setNoti] = useState();
    const [reRender, setReRender] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const openRequestWriteModal = () => {
        setIsRequestWriteModalOpen(true);
    };

    const closeRequestWriteModal = () => {
        setIsRequestWriteModalOpen(false);
    };

    const openNoticeModal = () => {
        setIsNoticeModalOpen(true);
    };

    const closeNoticeModal = () => {
        setIsNoticeModalOpen(false);
    };

    const downloadRequestExcel = async () => {
        const download = await getRequestExcel(user.authority_type, searchCondition);
        if (download) {
            navigate("/");
        }
    }

    const fetchNotice = async () => {
        const response = await getNoticeUTIL();
        setIsNoticeModalOpen(response?.notice_dump?.activation);
        setNoti(response?.notice_dump?.content)
    }

    useEffect(() => {
        const today = new Date().toISOString().split('T')[0]; // YYYY-MM-DD 형식의 오늘 날짜
        const lastPopupDate = localStorage.getItem('lastPopupDate');
        if (lastPopupDate === today) {
            setIsNoticeModalOpen(false);
        }
        else{
            fetchNotice();
        }
    }, []);


    return (
        <div className="page">
            <SearchBar_Request role={user.authority_type} searchCondition={searchCondition}
                               setSearchCondition={setSearchCondition} setCurrentPage={setCurrentPage}/>
            <div className="printExcelBtn">
                <button onClick={downloadRequestExcel}>
                    <img src={excelIcon} alt="엑셀"/><span>엑셀 다운로드</span>
                </button>
            </div>
            {(user.authority_type === 'dealer') &&
                <button id="writeRequestBtn" className="blackBtn" onClick={() => openRequestWriteModal()}>요청서
                    작성</button>
            }
            <RequestList role={user.authority_type} searchCondition={searchCondition} reRender={reRender}
                         setReRender={setReRender} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
            {isNoticeModalOpen && (
                <Modal_Notice showModal={isNoticeModalOpen} onClose={closeNoticeModal} noti={noti}/>
            )}
            {isRequestWriteModalOpen && (
                <Modal_RequestWrite showModal={isRequestWriteModalOpen} onClose={closeRequestWriteModal} user={user}
                                    reRender={reRender} setReRender={setReRender}/>)}
        </div>
    )
}