import "../styles/Modal_Registration.css";
import * as React from "react";
import {useEffect, useState} from "react";
import {getCodeList, registerUser} from "../api/Api";
import Modal_SearchAddress from "./Modal_SearchAddress";
import {useNavigate} from "react-router-dom";

export default function Modal_Registration({showModal, onClose, reRender, setReRender}) {
    const navigate = useNavigate();
    const [id, setId] = useState("");
    const [pw, setPw] = useState("");
    const [pwCheck, setPwCheck] = useState("");
    const [name, setName] = useState("");
    const [authType, setAuthType] = useState("");
    const [affList1, setAffList1] = useState([]);
    const [affList2, setAffList2] = useState([]);
    const [affList3, setAffList3] = useState([]);
    const [dealerAff1, setDealerAff1] = useState("");
    const [dealerAff2, setDealerAff2] = useState("");
    const [dealerAff3, setDealerAff3] = useState("");
    const [agencyAff1, setAgencyAff1] = useState("");
    const [agencyAff2, setAgencyAff2] = useState("");
    const [email, setEmail] = useState("");
    const [emailDomain, setEmailDomain] = useState("");
    const [firstCompanyPhone, setFirstCompanyPhone] = useState("");
    const [secondCompanyPhone, setSecondCompanyPhone] = useState("");
    const [thirdCompanyPhone, setThirdCompanyPhone] = useState("");
    const [firstPhone, setFirstPhone] = useState("");
    const [secondPhone, setSecondPhone] = useState("");
    const [thirdPhone, setThirdPhone] = useState("");
    const [addressNum, setAddressNum] = useState("");
    const [address, setAddress] = useState("");
    const [detailAddress, setDetailAddress] = useState("");
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const handleBackgroundClick = (e) => {
        if (e.target.classList.contains("modal-wrapper")) {
            onClose();
        }
    };

    const onChangeHandler = (e, setState) => {
        setState(e.target.value);
    }

    const openAddressModal = () => {
        setIsSearchModalOpen(true);
    }

    const closeAddressModal = () => {
        setIsSearchModalOpen(false);
    }

    const getAff1List = async () => {
        if (authType === 'dealer' || authType === 'financial') {
            const firstList = await getCodeList('DA');
            setAffList1(firstList);
        } else if (authType === 'agency') {
            const firstList = await getCodeList('SA');
            setAffList1(firstList);
        }
    }

    const getAff2List = async () => {
        if (authType === 'dealer' || authType === 'financial') {
            const secondList = await getCodeList(dealerAff1);
            setAffList2(secondList);
        } else if (authType === 'agency') {
            const secondList = await getCodeList(agencyAff1);
            setAffList2(secondList);
        }
    }

    const getAff3List = async () => {
        if (authType === 'dealer' || authType === 'financial') {
            const thirdList = await getCodeList(dealerAff2);
            setAffList3(thirdList);
        }
    }

    useEffect(() => {
        setAffList1([]);
        setAffList2([]);
        setAffList3([]);
        setDealerAff1("");
        setDealerAff2("");
        setDealerAff3("");
        setAgencyAff1("");
        setAgencyAff2("");
        getAff1List();
    }, [authType]);

    useEffect(() => {
        setAffList2([]);
        setAffList3([]);
        setDealerAff2("");
        setDealerAff3("");
        setAgencyAff2("");
        getAff2List();
    }, [dealerAff1, agencyAff1]);

    useEffect(() => {
        setDealerAff3("");
        setAffList3([]);
        getAff3List();
    }, [dealerAff2]);

    const handleRegUser = async () => {
        if (id.length === 0) {
            alert("ID를 입력해주세요.");
            return;
        }
        if (pw.length < 8) {
            alert("비밀번호는 8자리 이상이어야 합니다.");
            return;
        }
        if (pw !== pwCheck) {
            alert("비밀번호가 일치하지 않습니다.");
            return;
        }
        if (name.length === 0) {
            alert("사용자명을 입력해주세요.");
            return;
        }
        if (authType.length === 0) {
            alert("사용자 그룹을 선택해주세요.");
            return;
        }
        if (authType === 'dealer' || authType === 'financial') {
            if (dealerAff1.length === 0 || dealerAff2.length === 0 || dealerAff3.length === 0) {
                alert("딜러사 / 전시장을 선택해주세요.");
                return;
            }
        }
        if (authType === 'agency') {
            if (agencyAff1.length === 0 || agencyAff2.length === 0) {
                alert("대리점을 선택해주세요.");
                return;
            }
        }
        if ((email && !emailDomain) || (!email && emailDomain)) {
            alert("이메일 주소를 정확히 입력해주세요.");
            return;
        }
        if (firstCompanyPhone) {
            if (!secondCompanyPhone || !thirdCompanyPhone) {
                alert("전화번호를 정확히 입력해주세요.");
                return;
            }
        }
        if (secondCompanyPhone) {
            if (!firstCompanyPhone || !thirdCompanyPhone) {
                alert("전화번호를 정확히 입력해주세요.");
                return;
            }
        }
        if (thirdCompanyPhone) {
            if (!secondCompanyPhone || !firstCompanyPhone) {
                alert("전화번호를 정확히 입력해주세요.");
                return;
            }
        }
        if (firstPhone.length === 0 || secondPhone.length === 0 || thirdPhone.length === 0) {
            alert("휴대전화 번호를 입력해주세요.");
            return;
        }
        const isConfirmed = window.confirm("사용자를 등록하시겠습니까?");
        if (isConfirmed) {
            const userData = {
                user_id: id,
                password: pw,
                name: name,
                authority_type: authType,
                affiliation_code: authType === 'agency' ? agencyAff2 : authType === 'dealer' || authType === 'financial' ? dealerAff3 : "",
                email: email + "@" + emailDomain,
                company_phone_number: firstCompanyPhone + "-" + secondCompanyPhone + "-" + thirdCompanyPhone,
                phone_number: firstPhone + "-" + secondPhone + "-" + thirdPhone,
                address: addressNum + "/" + address + "/" + detailAddress,
            }
            const response = await registerUser(userData);
            if (response === 200) {
                alert("사용자 등록이 완료되었습니다.");
                setReRender(!reRender);
                onClose();
            } else if (response === 401) {
                navigate("/");
            }
        }
    }
    return (
        <div className="modal-wrapper">
            <div className="modal" id="regModal">
                <div className="header">
                    <p>사용자 등록</p>
                    <span className="close" onClick={onClose}>&times;</span>
                </div>
                <div className="content">
                    <div className="modal-inputs">
                        <div className="modal-inputs-category">ID</div>
                        <div><input value={id} onChange={(e) => onChangeHandler(e, setId)}/></div>
                        <div className="modal-inputs-category">비밀번호</div>
                        <div><input type="password" value={pw} onChange={(e) => onChangeHandler(e, setPw)}/></div>
                        <div className="modal-inputs-category">비밀번호 확인</div>
                        <div>
                            <input type="password" value={pwCheck}
                                   onChange={(e) => onChangeHandler(e, setPwCheck)}/>
                            {(pw !== pwCheck) && (pwCheck.length !== 0) ? (
                                <p
                                    style={{
                                        color: "#EA2837",
                                        fontSize: "12.8px",
                                        margin: "1.2px 0",
                                    }}
                                >
                                    비밀번호가 일치하지 않습니다.
                                </p>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="modal-inputs-category">사용자명</div>
                        <div><input value={name} onChange={(e) => onChangeHandler(e, setName)}/></div>
                        <div className="modal-inputs-category">사용자그룹</div>
                        <div>
                            <select value={authType} onChange={(e) => onChangeHandler(e, setAuthType)}>
                                <option value="">선택</option>
                                <option value="agency">대리점</option>
                                <option value="dealer">지점</option>
                                <option value="financial">지점장</option>
                                <option value="admin">본사</option>
                            </select>
                        </div>
                        <div className="modal-inputs-category">딜러사/전시장</div>
                        {
                            (authType === 'dealer' || authType === 'financial') &&
                            <div>
                                <select value={dealerAff1} onChange={(e) => onChangeHandler(e, setDealerAff1)}>
                                    <option value="">선택</option>
                                    {
                                        affList1.map((item, index) => {
                                            return <option key={index}
                                                           value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select value={dealerAff2} onChange={(e) => onChangeHandler(e, setDealerAff2)}>
                                    <option value="">선택</option>
                                    {
                                        affList2.map((item, index) => {
                                            return <option key={index}
                                                           value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select value={dealerAff3} onChange={(e) => onChangeHandler(e, setDealerAff3)}>
                                    <option value="">선택</option>
                                    {
                                        affList3.map((item, index) => {
                                            return <option key={index}
                                                           value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                            </div>
                        }
                        {
                            (authType !== 'dealer' && authType !== 'financial') &&
                            <div></div>
                        }
                        <div className="modal-inputs-category">대리점</div>
                        {
                            (authType === 'agency') &&
                            <div>
                                <select value={agencyAff1} onChange={(e) => onChangeHandler(e, setAgencyAff1)}>
                                    <option value="">선택</option>
                                    {
                                        affList1.map((item, index) => {
                                            return <option key={index}
                                                           value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select value={agencyAff2} onChange={(e) => onChangeHandler(e, setAgencyAff2)}>
                                    <option value="">선택</option>
                                    {
                                        affList2.map((item, index) => {
                                            return <option key={index}
                                                           value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                            </div>
                        }
                        {
                            (authType !== 'agency') &&
                            <div></div>
                        }
                        <div className="modal-inputs-category">이메일</div>
                        <div>
                            <input value={email} onChange={(e) => onChangeHandler(e, setEmail)}/>
                            <span>@</span>
                            <input value={emailDomain} onChange={(e) => onChangeHandler(e, setEmailDomain)}/>
                        </div>
                        <div className="modal-inputs-category">전화번호</div>
                        <div>
                            <input value={firstCompanyPhone}
                                   onChange={(e) => onChangeHandler(e, setFirstCompanyPhone)}/>
                            <span>-</span>
                            <input value={secondCompanyPhone}
                                   onChange={(e) => onChangeHandler(e, setSecondCompanyPhone)}/>
                            <span>-</span>
                            <input value={thirdCompanyPhone}
                                   onChange={(e) => onChangeHandler(e, setThirdCompanyPhone)}/>
                        </div>
                        <div className="modal-inputs-category">휴대폰번호</div>
                        <div>
                            <input value={firstPhone} onChange={(e) => onChangeHandler(e, setFirstPhone)}/>
                            <span>-</span>
                            <input value={secondPhone} onChange={(e) => onChangeHandler(e, setSecondPhone)}/>
                            <span>-</span>
                            <input value={thirdPhone} onChange={(e) => onChangeHandler(e, setThirdPhone)}/>
                        </div>
                        <div className="modal-inputs-category">주소</div>
                        <div id="addressInput">
                            <div>
                                <input placeholder="우편번호" value={addressNum}
                                       onChange={(e) => onChangeHandler(e, setAddressNum)}/>
                                <button id="searchAddressBtn" className="blackBtn" onClick={openAddressModal}>주소검색
                                </button>
                            </div>
                            <input placeholder="주소" value={address} onChange={(e) => onChangeHandler(e, setAddress)}/>
                            <input placeholder="상세주소" value={detailAddress}
                                   onChange={(e) => onChangeHandler(e, setDetailAddress)}/>
                        </div>
                    </div>
                    <div className="regModalController">
                        <button className="grayBtn" onClick={onClose}>취소</button>
                        <button className="blackBtn" onClick={handleRegUser}>저장</button>
                    </div>
                </div>
            </div>
            {
                isSearchModalOpen && <Modal_SearchAddress showModal={isSearchModalOpen} onClose={closeAddressModal}
                                                          setAddressNumber={setAddressNum} setAddressText={setAddress}
                                                          setDetailAddress={setDetailAddress}/>
            }
        </div>
    )
}