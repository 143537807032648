import {useEffect, useState} from "react";
import dayjs from "dayjs";
import DateInput from "./DateInput";
import "../styles/Modal_WarrantyDetails.css";
import * as React from "react";
import {createWarranty, getCodeList, getUserInfo, getWarrantyData, modifyWarranty} from "../api/Api";
import {useRecoilState} from "recoil";
import {userInfo} from "./UserInfo";
import Modal_SearchAddress from "./Modal_SearchAddress";
import {useNavigate} from "react-router-dom";

export default function Modal_WarrantyDetails({
                                                  showModal,
                                                  onClose,
                                                  item,
                                                  situation,
                                                  reRender,
                                                  setReRender,
                                                  closeRequestModal
                                              }) {
    const navigate = useNavigate();
    const [user, setUser] = useRecoilState(userInfo);
    const [customerName, setCustomerName] = useState("");
    const [vehicleType, setVehicleType] = useState("");
    const [carNumber, setCarNumber] = useState(""); // 차량번호
    const [firstPhone, setFirstPhone] = useState("");
    const [secondPhone, setSecondPhone] = useState("");
    const [thirdPhone, setThirdPhone] = useState("");
    const [addressNumber, setAddressNumber] = useState("");
    const [address, setAddress] = useState("");
    const [detailAddress, setDetailAddress] = useState("");
    const [doneDate, setDoneDate] = useState(dayjs());
    const [productList, setProductList] = useState([]);
    const [frontFilm, setFrontFilm] = useState("");
    const [side1Film, setSide1Film] = useState("");
    const [side2Film, setSide2Film] = useState("");
    const [side3Film, setSide3Film] = useState("");
    const [backFilm, setBackFilm] = useState("");
    const [sunRoofFilm, setSunRoofFilm] = useState("");
    const [densityList, setDensityList] = useState([]);
    const [density_front, setDensity_front] = useState("");
    const [density_side1, setDensity_side1] = useState("");
    const [density_side2, setDensity_side2] = useState("");
    const [density_side3, setDensity_side3] = useState("");
    const [density_back, setDensity_back] = useState("");
    const [density_sunRoof, setDensity_sunRoof] = useState("");
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

    const handleBackgroundClick = (e) => {
        if (e.target.classList.contains("modal-wrapper")) {
            e.stopPropagation();
            onClose();
        }
    };
    const onChangeHandler = (e, setState) => {
        const value = e.target.value;
        setState(value);
    };
    const openAddressModal = () => {
        setIsSearchModalOpen(true);
    }

    const closeAddressModal = () => {
        setIsSearchModalOpen(false);
    }

    const getProductList = async () => {
        const productList = await getCodeList('PD');
        setProductList(productList);
    }

    const getDensityList = async () => {
        const densityList = await getCodeList('PD001');
        setDensityList(densityList);
    }
    const getWarranty = async () => {
        let warranty = await getWarrantyData(item.id);
        if (warranty) {
            if (warranty.status === 200) {
                warranty = warranty.data.guarantee_detail;
                setCustomerName(warranty.customer_name);
                setVehicleType(warranty.vehicle_value.split("/")[1]);
                setCarNumber(warranty.car_number);
                setFirstPhone(warranty.customer_phone_number.split("-")[0]);
                setSecondPhone(warranty.customer_phone_number.split("-")[1]);
                setThirdPhone(warranty.customer_phone_number.split("-")[2]);
                setAddressNumber(warranty.customer_address.split("_")[0]);
                setAddress(warranty.customer_address.split("_")[1]);
                setDetailAddress(warranty.customer_address.split("_")[2]);
                setDoneDate(dayjs(warranty.completed_date * 1000));
                setFrontFilm(warranty['applied_tinting_density_code'].split("/")[0] === "" ? "" : warranty['applied_tinting_density_code'].split("/")[0].substring(0, 5));
                setSide1Film(warranty['applied_tinting_density_code'].split("/")[1] === "" ? "" : warranty['applied_tinting_density_code'].split("/")[1].substring(0, 5));
                setSide2Film(warranty['applied_tinting_density_code'].split("/")[2] === "" ? "" : warranty['applied_tinting_density_code'].split("/")[2].substring(0, 5));
                setSide3Film(warranty['applied_tinting_density_code'].split("/")[3] === "" ? "" : warranty['applied_tinting_density_code'].split("/")[3].substring(0, 5));
                setBackFilm(warranty['applied_tinting_density_code'].split("/")[4] === "" ? "" : warranty['applied_tinting_density_code'].split("/")[4].substring(0, 5));
                setSunRoofFilm(warranty['applied_tinting_density_code'].split("/")[5] === "" ? "" : warranty['applied_tinting_density_code'].split("/")[5].substring(0, 5));
                setDensity_front(warranty['applied_tinting_density_code'].split("/")[0]);
                setDensity_side1(warranty['applied_tinting_density_code'].split("/")[1]);
                setDensity_side2(warranty['applied_tinting_density_code'].split("/")[2]);
                setDensity_side3(warranty['applied_tinting_density_code'].split("/")[3]);
                setDensity_back(warranty["applied_tinting_density_code"].split("/")[4]);
                setDensity_sunRoof(warranty["applied_tinting_density_code"].split("/")[5]);
            } else if (warranty.status === 401) {
                navigate("/");
            }
        }
    }

    useEffect(() => {
        if (frontFilm === "") {
            setDensity_front("");
        }
        if (side1Film === "") {
            setDensity_side1("");
        }
        if (side2Film === "") {
            setDensity_side2("");
        }
        if (side3Film === "") {
            setDensity_side3("");
        }
        if (backFilm === "") {
            setDensity_back("");
        }
        if (sunRoofFilm === "") {
            setDensity_sunRoof("");
        }
    }, [frontFilm, side1Film, side2Film, side3Film, backFilm, sunRoofFilm]);

    useEffect(() => {
        getProductList();
        getDensityList();
        if (situation === '수정') {
            getWarranty();
        } else if (situation === '생성') {
            setCustomerName(item.customer_name);
            setVehicleType(item.vehicle_value.split("/")[1]);
            setFrontFilm(item.product_code);
            setSide1Film(item.product_code);
            setSide2Film(item.product_code);
            setSide3Film(item.product_code);
            setBackFilm(item.product_code);
            setDensity_front(item.tinting_density.split("/")[0]);
            setDensity_side1(item.tinting_density.split("/")[1]);
            setDensity_side2(item.tinting_density.split("/")[2]);
            setDensity_side3(item.tinting_density.split("/")[3]);
            setDensity_back(item.tinting_density.split("/")[4]);
        } else if (situation === '조회') {
            setCustomerName(item.customer_name);
            setVehicleType(item.vehicle_value.split("/")[1]);
            setCarNumber(item.car_number);
            setFirstPhone(item.customer_phone_number.split('-')[0]);
            setSecondPhone(item.customer_phone_number.split('-')[1]);
            setThirdPhone(item.customer_phone_number.split('-')[2]);
            setAddressNumber(item.customer_address.split('_')[0]);
            setAddress(item.customer_address.split('_')[1]);
            setDetailAddress(item.customer_address.split('_')[2]);
            setDoneDate(dayjs(item.completed_date * 1000));
            setFrontFilm(item['applied_tinting_density_code'].split("/")[0] === "" ? "" : item['applied_tinting_density_code'].split("/")[0].substring(0, 5));
            setSide1Film(item['applied_tinting_density_code'].split("/")[1] === "" ? "" : item['applied_tinting_density_code'].split("/")[1].substring(0, 5));
            setSide2Film(item['applied_tinting_density_code'].split("/")[2] === "" ? "" : item['applied_tinting_density_code'].split("/")[2].substring(0, 5));
            setSide3Film(item['applied_tinting_density_code'].split("/")[3] === "" ? "" : item['applied_tinting_density_code'].split("/")[3].substring(0, 5));
            setBackFilm(item['applied_tinting_density_code'].split("/")[4] === "" ? "" : item['applied_tinting_density_code'].split("/")[4].substring(0, 5));
            setSunRoofFilm(item['applied_tinting_density_code'].split("/")[5] === "" ? "" : item['applied_tinting_density_code'].split("/")[5].substring(0, 5));
            setDensity_front(item['applied_tinting_density_code'].split("/")[0]);
            setDensity_side1(item['applied_tinting_density_code'].split("/")[1]);
            setDensity_side2(item['applied_tinting_density_code'].split("/")[2]);
            setDensity_side3(item['applied_tinting_density_code'].split("/")[3]);
            setDensity_back(item["applied_tinting_density_code"].split("/")[4]);
            setDensity_sunRoof(item["applied_tinting_density_code"].split("/")[5]);
        }
    }, [situation]);

    const handleSaveWarranty = async () => {
        if (!doneDate) {
            alert("시공일자를 입력해주세요.");
            return;
        }
        if (doneDate.format("YYYY-MM-DD") === "Invalid Date") {
            alert("시공일자를 입력해주세요.");
            return;
        }
        if (!(frontFilm || side1Film || side2Film || side3Film || backFilm || sunRoofFilm)) {
            alert("제품 및 사양을 한 개 이상 입력해주세요.");
            return;
        }
        if (frontFilm) {
            if (!density_front) {
                alert("전면 제품 사양을 입력해주세요.");
                return;
            }
        }
        if (side1Film) {
            if (!density_side1) {
                alert("측면 1열 제품 사양을 입력해주세요.");
                return;
            }
        }
        if (side2Film) {
            if (!density_side2) {
                alert("측면 2열 제품 사양을 입력해주세요.");
                return;
            }
        }
        if (side3Film) {
            if (!density_side3) {
                alert("측면 3열 제품 사양을 입력해주세요.");
                return;
            }
        }
        if (backFilm) {
            if (!density_back) {
                alert("후면 제품 사양을 입력해주세요.");
                return;
            }
        }
        if (sunRoofFilm) {
            if (!density_sunRoof) {
                alert("썬루프 제품 사양을 입력해주세요.");
                return;
            }
        }
        const isConfirmed = window.confirm("저장하시겠습니까?");
        if (isConfirmed) {
            const warrantyData = {
                job_request_id: item.id,
                customer_address: addressNumber + "_" + address + "_" + detailAddress,
                customer_phone_number: firstPhone + "-" + secondPhone + "-" + thirdPhone,
                completed_date: doneDate.format("YYYY-MM-DD"),
                car_number: carNumber,
                applied_tinting_density_code: density_front + "/" + density_side1 + "/" + density_side2 + "/" + density_side3 + "/" + density_back + "/" + density_sunRoof,
            }
            let warranty;
            if (situation === '생성') {
                warranty = await createWarranty(warrantyData);
            } else if (situation === '수정') {
                warranty = await modifyWarranty(warrantyData);
            }
            if (warranty === 200) {
                alert("처리되었습니다.");
                const myInfo = await getUserInfo();
                if (myInfo.status === 200) {
                    setUser(myInfo.data.user_info);
                    setReRender(!reRender);
                    closeRequestModal();
                    onClose();
                } else if (myInfo.status === 401) {
                    navigate("/");
                }
            } else if (warranty === 401) {
                navigate("/");
            }
        }
    }

    return (
        <div className="modal-wrapper" id="warrantyModal-wrapper">
            <div className="modal" id="warrantyModal">
                <div className="header">
                    <p>시공보증서 상세</p>
                    <span className="close" onClick={onClose}>&times;</span>
                </div>
                <div className="content">
                    <div className="modal-details">
                        <div className="modal-details-category">고객성명</div>
                        <div>{customerName}</div>
                        <div className="modal-details-category">고객차종</div>
                        <div>{vehicleType}</div>
                        <div className="modal-details-category">차량번호</div>
                        {
                            situation !== '조회' &&
                            <div>
                                <input value={carNumber} onChange={(e) => onChangeHandler(e, setCarNumber)}/>
                            </div>
                        }
                        {
                            situation === '조회' && <div>{carNumber}</div>
                        }
                        <div className="modal-details-category">고객 연락처</div>
                        {
                            situation !== '조회' &&
                            <div>
                                <input value={firstPhone}
                                       onChange={(e) => onChangeHandler(e, setFirstPhone)}/><span>-</span>
                                <input value={secondPhone}
                                       onChange={(e) => onChangeHandler(e, setSecondPhone)}/><span>-</span>
                                <input value={thirdPhone} onChange={(e) => onChangeHandler(e, setThirdPhone)}/>
                            </div>
                        }
                        {
                            situation === '조회' &&
                            <div>
                                {firstPhone}-{secondPhone}-{thirdPhone}
                            </div>
                        }
                        <div className="modal-details-category">고객주소</div>
                        <div id="addressInput">
                            <div>
                                <input value={addressNumber} onChange={(e) => onChangeHandler(e, setAddressNumber)}
                                       placeholder="우편번호" disabled={(situation === '조회')}/>
                                {
                                    situation !== "조회" && <button id="searchAddressBtn" className="blackBtn"
                                                                  onClick={openAddressModal}>주소검색</button>
                                }
                            </div>
                            <input value={address} onChange={(e) => onChangeHandler(e, setAddress)} placeholder="주소"
                                   disabled={(situation === '조회')}/>
                            <input value={detailAddress} onChange={(e) => onChangeHandler(e, setDetailAddress)}
                                   placeholder="상세주소" disabled={(situation === '조회')}/>
                        </div>
                        <div className="modal-details-category">시공일자</div>
                        <div id="request-input-date">
                            {
                                situation === '조회' &&
                                <div>{doneDate.format("YYYY-MM-DD")}</div>
                            }
                            {
                                situation !== '조회' &&
                                <DateInput date={doneDate} setDate={setDoneDate}/>
                            }
                        </div>
                        <div className="modal-details-category">시공위치</div>
                        <div className="warranty-tintingPosition">
                            <span>전면</span> <span>측면 1열</span> <span>측면 2열</span> <span>측면 3열</span> <span>후면</span>
                            <span>썬루프</span>
                        </div>
                        <div className="modal-details-category">제품명</div>
                        <div className="warranty-tintingFilm">
                            <select value={frontFilm} onChange={(e) => onChangeHandler(e, setFrontFilm)}
                                    disabled={(situation === '조회')}>
                                <option value="">선택</option>
                                {
                                    productList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                            <select value={side1Film} onChange={(e) => onChangeHandler(e, setSide1Film)}
                                    disabled={(situation === '조회')}>
                                <option value="">선택</option>
                                {
                                    productList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                            <select value={side2Film} onChange={(e) => onChangeHandler(e, setSide2Film)}
                                    disabled={(situation === '조회')}>
                                <option value="">선택</option>
                                {
                                    productList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                            <select value={side3Film} onChange={(e) => onChangeHandler(e, setSide3Film)}
                                    disabled={(situation === '조회')}>
                                <option value="">선택</option>
                                {
                                    productList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                            <select value={backFilm} onChange={(e) => onChangeHandler(e, setBackFilm)}
                                    disabled={(situation === '조회')}>
                                <option value="">선택</option>
                                {
                                    productList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                            <select value={sunRoofFilm} onChange={(e) => onChangeHandler(e, setSunRoofFilm)}
                                    disabled={(situation === '조회')}>
                                <option value="">선택</option>
                                {
                                    productList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="modal-details-category">제품사양</div>
                        <div className="warranty-tintingDensity">
                            <select
                                value={density_front}
                                onChange={(e) => onChangeHandler(e, setDensity_front)}
                                disabled={(situation === '조회' || frontFilm === "")}>
                                <option value="">선택</option>
                                {
                                    densityList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                            <select
                                value={density_side1}
                                onChange={(e) => onChangeHandler(e, setDensity_side1)}
                                disabled={(situation === '조회' || side1Film === "")}>
                                <option value="">선택</option>
                                {
                                    densityList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                            <select
                                value={density_side2}
                                onChange={(e) => onChangeHandler(e, setDensity_side2)}
                                disabled={(situation === '조회' || side2Film === "")}>
                                <option value="">선택</option>
                                {
                                    densityList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                            <select
                                value={density_side3}
                                onChange={(e) => onChangeHandler(e, setDensity_side3)}
                                disabled={(situation === '조회' || side3Film === "")}>
                                <option value="">선택</option>
                                {
                                    densityList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                            <select
                                value={density_back}
                                onChange={(e) => onChangeHandler(e, setDensity_back)}
                                disabled={(situation === '조회' || backFilm === "")}>
                                <option value="">선택</option>
                                {
                                    densityList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                            <select
                                value={density_sunRoof}
                                onChange={(e) => onChangeHandler(e, setDensity_sunRoof)}
                                disabled={(situation === '조회' || sunRoofFilm === "")}>
                                <option value="">선택</option>
                                {
                                    densityList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    {
                        <div className="detailController">
                            <button className="blackBtn" onClick={onClose}>닫기</button>
                            {(situation === '생성' || situation === '수정') && <button className="blackBtn"
                                                                                  onClick={handleSaveWarranty}>저장</button>}
                        </div>
                    }
                </div>
                {
                    isSearchModalOpen && <Modal_SearchAddress showModal={isSearchModalOpen} onClose={closeAddressModal}
                                                              setAddressNumber={setAddressNumber}
                                                              setAddressText={setAddress}
                                                              setDetailAddress={setDetailAddress}/>
                }
            </div>
        </div>
    )
}