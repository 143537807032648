import "../styles/Modal_CodeRegister.css";
import {useState} from "react";
import {addCode} from "../api/Api";
import {useNavigate} from "react-router-dom";

export default function Modal_CodeRegister({
                                               showModal,
                                               onClose,
                                               level,
                                               getCodes,
                                               setLv,
                                           }) {
    const navigate = useNavigate();
    const [codeValue, setCodeValue] = useState("");
    const [codeKey, setCodeKey] = useState(level.code_key);
    const [film, setFilm] = useState("");
    const isVehicleLV3 = level.code_key.substring(0, 2) === 'AT' && level.code_key.length > 2 && level.code_key.length <= 5 ;
    if (!showModal) {
        return null;
    }
    // const handleBackgroundClick = (e) => {
    //     // 모달 외부를 클릭하면 모달을 닫음
    //     if (e.target.classList.contains("modal-wrapper")) {
    //         onClose();
    //     }
    // };

    const handleRegCode = async () => {
        if (codeKey.length === 0) {
            alert("코드를 입력하세요.");
            return;
        }
        if (codeValue.length === 0) {
            alert("코드값을 입력하세요.");
            return;
        }
        if (level !== 'root') {
            if (!codeKey.includes(level.code_key) || codeKey.length <= level.code_key.length) {
                alert("상위 코드값과 일치하지 않습니다.");
                return;
            }
        }
        if (isNaN(film)) {
            alert("필름 길이는 0 이상의 숫자여야 합니다.");
            return;
        }

        if (Number(film) < 0) {
            alert("필름 길이는 0 이상의 숫자여야 합니다.");
            return;
        }
        const isConfirmed = window.confirm("등록하시겠습니까?");
        let postData;
        if (isConfirmed) {
            isVehicleLV3 ? postData = {
                code: codeKey,
                parent_code: level.code_key,
                code_value: codeValue,
                outcome_quantity: film,
            } : postData = {
                code: codeKey,
                parent_code: level.code_key,
                code_value: codeValue,
            }
            const regCode = await addCode(postData);
            if (regCode === 200) {
                alert("코드가 등록되었습니다.");
                await getCodes(setLv, level.code_key);
                onClose();
            } else if (regCode === 401) {
                navigate("/");
            }
        }
    }

    return (
        <div className="modal-wrapper" id="regCodeModal-wrapper">
            <div className="modal" id="regCodeModal">
                <div className="header">
                    <p>코드 등록</p>
                    <span className="close" onClick={onClose}>&times;</span>
                </div>
                <div className="content">
                    <div className="regCodeInputs">
                        <div>GROUP_CD</div>
                        <input value={level["code_key"]}/>
                        <div>GROUP_VAL</div>
                        <input value={level["code_value"]}/>
                        <div>CD</div>
                        <input placeholder="공통코드를 입력하세요" value={codeKey} onChange={(e) => setCodeKey(e.target.value)} autoFocus={true}/>
                        <div>VALUE</div>
                        <input placeholder="공통코드값을 입력하세요" value={codeValue}
                               onChange={(e) => setCodeValue(e.target.value)}/>
                        {
                            isVehicleLV3 &&
                            <div>필름 길이</div>
                        }
                        {
                            isVehicleLV3 &&
                            <input type="text" placeholder="필름 길이를 입력하세요" value={film}
                                   onChange={(e) => setFilm(e.target.value)}/>
                        }
                    </div>
                    <button id="regSaveBtn" className="blackBtn" onClick={handleRegCode}>저장</button>
                </div>
            </div>
        </div>
    )
}