// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagingBtn {
    width: 100%;
    margin: 48px auto 96px auto;
    display: flex;
    justify-content: center;
}

.pagingBtn button {
    border: 1px solid white;
    font-size: 16px;
    padding: 8px 12px;
    margin: 0 6.4px;
    background-color: #FFFFFF;
    line-height: 16px;
    color: black;
}

.pagingBtn button:hover {
    background-color: #f0f0f0;
}

.pagingBtn button:disabled {
    background-color: white;
    cursor: not-allowed;
}


.firstPage:before {
    content: "\\2039\\2039";
}

.previousPage:before {
    content: "\\2039";
}

.nextPage:before {
    content: "\\203A";
}

.lastPage:before {
    content: "\\203A\\203A";
}

.firstPage, .previousPage, .nextPage, .lastPage {
    color: #969696;
}

.firstPage:before, .previousPage:before, .nextPage:before, .lastPage:before {
    font-size: 20.8px;
}

@media screen and (max-width: 600px) {
    .pagingBtn button {
        font-size: 12px;
        padding: 4px 9px;
        margin: 0 3px;
    }


    .firstPage:before, .previousPage:before, .nextPage:before, .lastPage:before {
        font-size: 14px;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Paging.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,2BAA2B;IAC3B,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;IACvB,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,yBAAyB;IACzB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;AACvB;;;AAGA;IACI,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI;QACI,eAAe;QACf,gBAAgB;QAChB,aAAa;IACjB;;;IAGA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".pagingBtn {\n    width: 100%;\n    margin: 48px auto 96px auto;\n    display: flex;\n    justify-content: center;\n}\n\n.pagingBtn button {\n    border: 1px solid white;\n    font-size: 16px;\n    padding: 8px 12px;\n    margin: 0 6.4px;\n    background-color: #FFFFFF;\n    line-height: 16px;\n    color: black;\n}\n\n.pagingBtn button:hover {\n    background-color: #f0f0f0;\n}\n\n.pagingBtn button:disabled {\n    background-color: white;\n    cursor: not-allowed;\n}\n\n\n.firstPage:before {\n    content: \"\\2039\\2039\";\n}\n\n.previousPage:before {\n    content: \"\\2039\";\n}\n\n.nextPage:before {\n    content: \"\\203A\";\n}\n\n.lastPage:before {\n    content: \"\\203A\\203A\";\n}\n\n.firstPage, .previousPage, .nextPage, .lastPage {\n    color: #969696;\n}\n\n.firstPage:before, .previousPage:before, .nextPage:before, .lastPage:before {\n    font-size: 20.8px;\n}\n\n@media screen and (max-width: 600px) {\n    .pagingBtn button {\n        font-size: 12px;\n        padding: 4px 9px;\n        margin: 0 3px;\n    }\n\n\n    .firstPage:before, .previousPage:before, .nextPage:before, .lastPage:before {\n        font-size: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
