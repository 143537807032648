import SmithLogo from "../logo_request.png";
import "../styles/Header.css";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "./Nav";
import AccessInfo from "./AccessInfo";

export default function Header({ user }) {
  const navigate = useNavigate();
  const location = useLocation();

  if (location.pathname === "/" || location.pathname === "/login/loginForm" || location.pathname === "/join/search" || location.pathname === "/join/terms" || location.pathname === "/join/form") {
    return (
      <div className="login-header">
        <img className="smithLogo" src={SmithLogo} alt="스미스클럽" onClick={()=>navigate("/")}/>
      </div>
    );
  } else {
    return (
      <div className="main-header">
        <AccessInfo user={user} />
        <div className={`logo_nav_pkg_${user.authority_type}`}>
          <img
            className="smithLogo"
            src={SmithLogo}
            onClick={() => navigate("/")}
            alt="스미스클럽"
          />
          <Nav role={user.authority_type}></Nav>
        </div>
      </div>
    );
  }
}
