// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#nav_admin {
    width: 100%;
    margin: 32px 0 0 0;
    font-size: min(16px, 1.5Vw);
    color: black;
    justify-content: space-between;
}

#nav_etc {
    font-size: min(17px, 1.8Vw);
    color: black;
    padding-top: 5px;
}

.nav {
    display: flex;
}

.nav a {
    border-top: 2px solid #c8c8c8;
    text-decoration: none;
    position: relative;
    color: black;
}

.nav a.active {
    top: -1px;
    border-top: 4px solid #323232;
    transition: border-top 0.3s ease-in-out;
}

.nav a:not(.active) {
    border-top: 2px solid #c8c8c8;
    transition: border-top 0.3s ease-in-out;
}

.nav a:visited {
    color: black;
}

#nav_admin a {
    padding: 16px 10px;
    width: 14.3%;
}

#nav_etc a {
    padding: 16px 32px;
}

.navBtn {
    width: 28px;
    height: 28px;
    display: none;
}

.navBtn span {
    width: 100%;
    height: 3px;
    border-radius: 10px;
    background-color: black;
}

@media screen and (max-width: 600px) {
    .nav{
        display: none;
    }

    .navBtn {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}

`, "",{"version":3,"sources":["webpack://./src/styles/Nav.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,2BAA2B;IAC3B,YAAY;IACZ,8BAA8B;AAClC;;AAEA;IACI,2BAA2B;IAC3B,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,6BAA6B;IAC7B,qBAAqB;IACrB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,6BAA6B;IAC7B,uCAAuC;AAC3C;;AAEA;IACI,6BAA6B;IAC7B,uCAAuC;AAC3C;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;QACb,sBAAsB;QACtB,6BAA6B;IACjC;AACJ","sourcesContent":["#nav_admin {\n    width: 100%;\n    margin: 32px 0 0 0;\n    font-size: min(16px, 1.5Vw);\n    color: black;\n    justify-content: space-between;\n}\n\n#nav_etc {\n    font-size: min(17px, 1.8Vw);\n    color: black;\n    padding-top: 5px;\n}\n\n.nav {\n    display: flex;\n}\n\n.nav a {\n    border-top: 2px solid #c8c8c8;\n    text-decoration: none;\n    position: relative;\n    color: black;\n}\n\n.nav a.active {\n    top: -1px;\n    border-top: 4px solid #323232;\n    transition: border-top 0.3s ease-in-out;\n}\n\n.nav a:not(.active) {\n    border-top: 2px solid #c8c8c8;\n    transition: border-top 0.3s ease-in-out;\n}\n\n.nav a:visited {\n    color: black;\n}\n\n#nav_admin a {\n    padding: 16px 10px;\n    width: 14.3%;\n}\n\n#nav_etc a {\n    padding: 16px 32px;\n}\n\n.navBtn {\n    width: 28px;\n    height: 28px;\n    display: none;\n}\n\n.navBtn span {\n    width: 100%;\n    height: 3px;\n    border-radius: 10px;\n    background-color: black;\n}\n\n@media screen and (max-width: 600px) {\n    .nav{\n        display: none;\n    }\n\n    .navBtn {\n        display: flex;\n        flex-direction: column;\n        justify-content: space-around;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
