import InventoryLog from "../components/InventoryLog";
import SearchBar_Inventory from "../components/SearchBar_Inventory";
import {useState} from "react";

export default function InventoryLogPage(){
    const [searchCondition, setSearchCondition] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    return (
        <div className="page">
            <SearchBar_Inventory searchCondition={searchCondition} setSearchCondition={setSearchCondition} setCurrentPage={setCurrentPage}/>
            <InventoryLog searchCondition={searchCondition} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </div>
    )
}