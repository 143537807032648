import * as React from "react";
import "../styles/Modal_ResetPassword.css";
import {useState} from "react";
import {resetPassword} from "../api/Api";
import {useNavigate} from "react-router-dom";

export default function Modal_ResetPassword({userId, onClose}) {
    const navigate = useNavigate();
    const [newPw, setNewPw] = useState("");
    const [newPwCheck, setNewPwCheck] = useState("");
    const handleBackgroundClick = (e) => {
        if (e.target.classList.contains("modal-wrapper")) {
            e.stopPropagation();
            onClose();
        }
    };

    const handleResetPw = async() => {
        if(newPw.length < 8){
            alert("비밀번호는 8자리 이상이어야 합니다.");
            return;
        }
        if(newPwCheck !== newPw){
            alert("비밀번호가 일치하지 않습니다.");
            return;
        }
        const reset = await resetPassword({user_id: userId, new_password: newPw});
        if(reset === 200){
            alert("비밀번호가 재설정되었습니다.");
            onClose();
        } else if(reset === 401){
            navigate("/");
        }
    }

    return (
        <div className="modal-wrapper" id="resetPwModal-wrapper">
            <div className="modal" id="resetPwModal" >
                <div className="header">
                    <p>비밀번호 초기화</p>
                    <span className="close" onClick={onClose}>&times;</span>
                </div>
                <div className="content">
                    <label>새로운 비밀번호 <input type="password" value={newPw} onChange={(e)=>setNewPw(e.target.value)}/></label>
                    <label>새로운 비밀번호 확인 <input type="password" value={newPwCheck} onChange={(e)=>setNewPwCheck(e.target.value)}/></label>
                    <button id="saveBtn" className="blackBtn" onClick={handleResetPw}>저장</button>
                </div>
            </div>
        </div>
    )
}