import PopupList from "../components/PopupList";
import {useEffect, useState} from "react";
import Modal_PopupWrite from "../components/Modal_PopupWrite";
import {useNavigate} from "react-router-dom";

export default function PopupManagementPage() {
    const navigate = useNavigate();
    const [isPopupWriteModalOpen, setIsPopupWriteModalOpen] = useState(false);
    const openPopupWriteModal = () => {
        setIsPopupWriteModalOpen(true);
    };

    const closePopupWriteModal = () => {
        setIsPopupWriteModalOpen(false);
    };

    useEffect(() => {
        alert("기능 준비중입니다.");
        navigate(-1);
    }, []);
    return (
        <div className="page">
            <button id="writePopupBtn" className="blackBtn" onClick={openPopupWriteModal}>팝업 작성</button>
            <PopupList/>
            {isPopupWriteModalOpen && <Modal_PopupWrite showModal={isPopupWriteModalOpen} onClose={closePopupWriteModal}/>}
        </div>
    )
}