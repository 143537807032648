import "../styles/MyInfo.css";
import {useState} from "react";
import Modal_Password from "./Modal_Password";
import {useRecoilState} from "recoil";
import {userInfo} from "./UserInfo";
import {modifyUserInfo} from "../api/Api";
import Modal_SearchAddress from "./Modal_SearchAddress";
import * as React from "react";
import {useNavigate} from "react-router-dom";

export default function MyInfo({user}) {
    const navigate = useNavigate();
    const [myInfo, setMyInfo] = useRecoilState(userInfo);
    const [id, setId] = useState(user.user_id);
    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email ? user.email.split('@')[0] : "");
    const [emailDomain, setEmailDomain] = useState(user.email ? user.email.split('@')[1] : "");
    const [firstCompanyPhone, setFirstCompanyPhone] = useState(user.company_phone ? user.company_phone.split("-")[0] : "");
    const [secondCompanyPhone, setSecondCompanyPhone] = useState(user.company_phone ? user.company_phone.split("-")[1] : "");
    const [thirdCompanyPhone, setThirdCompanyPhone] = useState(user.company_phone ? user.company_phone.split("-")[2] : "");
    const [firstPhone, setFirstPhone] = useState(user.phone ? user.phone.split('-')[0] : "");
    const [secondPhone, setSecondPhone] = useState(user.phone ? user.phone.split('-')[1] : "");
    const [thirdPhone, setThirdPhone] = useState(user.phone ? user.phone.split('-')[2] : "");
    const [addressNumber, setAddressNumber] = useState(user.address?.split("/")[0] ? user.address.split("/")[0] : "");
    const [address, setAddress] = useState(user.address?.split("/")[1] ? user.address.split("/")[1] : "");
    const [detailAddress, setDetailAddress] = useState(user.address?.split("/")[2] ? user.address.split("/")[2] : "");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

    const modifyMyInfo = async () => {
        if (!name) {
            alert("사용자명을 입력해주세요.");
            return;
        }
        if ((email && !emailDomain) || (!email && emailDomain)) {
            alert("이메일 주소를 정확히 입력해주세요.");
            return;
        }
        if (firstCompanyPhone) {
            if (!secondCompanyPhone || !thirdCompanyPhone) {
                alert("전화번호를 정확히 입력해주세요.");
                return;
            }
        }
        if (secondCompanyPhone) {
            if (!firstCompanyPhone || !thirdCompanyPhone) {
                alert("전화번호를 정확히 입력해주세요.");
                return;
            }
        }
        if (thirdCompanyPhone) {
            if (!secondCompanyPhone || !firstCompanyPhone) {
                alert("전화번호를 정확히 입력해주세요.");
                return;
            }
        }
        if (!firstPhone || !secondPhone || !thirdPhone) {
            alert("휴대폰번호를 입력해주세요.");
            return;
        }
        const isConfirmed = window.confirm("저장하시겠습니까?");
        if (isConfirmed) {
            const modify_info = await modifyUserInfo({
                name: name,
                company_phone: `${firstCompanyPhone}-${secondCompanyPhone}-${thirdCompanyPhone}`,
                phone: `${firstPhone}-${secondPhone}-${thirdPhone}`,
                address: `${addressNumber}/${address}/${detailAddress}`,
                email: `${email}@${emailDomain}`
            });
            if (modify_info === 200) {
                setMyInfo({
                    ...myInfo,
                    name: name,
                    phone: `${firstPhone}-${secondPhone}-${thirdPhone}`,
                    address: `${addressNumber}/${address}/${detailAddress}`,
                    company_phone: `${firstCompanyPhone}-${secondCompanyPhone}-${thirdCompanyPhone}`,
                    email: `${email}@${emailDomain}`
                });
                alert("정보 수정이 완료되었습니다.");
            } else if (modify_info === 401) {
                navigate("/");
            }
        }
    }

    const onChangeHandler = (e, setState) => {
        const value = e.target.value;
        setState(value);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openAddressModal = () => {
        setIsSearchModalOpen(true);
    }

    const closeAddressModal = () => {
        setIsSearchModalOpen(false);
    }
    return (
        <div className="myInfo">
            <div className="myInfoHeader">내 정보 수정</div>
            <ul>
                <li>
                    <label>ID</label>
                    <div>
                        <input value={id} disabled={true}/>
                    </div>
                </li>
                <li>
                    <label>비밀번호</label>
                    <div>
                        <button id="modifyPwBtn" className="blackBtn" onClick={openModal}>비밀번호 변경</button>
                    </div>
                </li>
                <li>
                    <label>사용자명</label>
                    <div>
                        <input value={name} onChange={(e) => onChangeHandler(e, setName)}/>
                    </div>
                </li>
                <li>
                    <label>소속</label>
                    <p>{user.affiliation_value?.split("/").slice(1).join("/")}</p>
                </li>
                <li>
                    <label>이메일</label>
                    <div>
                        <input value={email} onChange={(e) => onChangeHandler(e, setEmail)}/>
                        <span>@</span>
                        <input value={emailDomain} onChange={(e) => onChangeHandler(e, setEmailDomain)}/>
                    </div>
                </li>
                <li>
                    <label>전화번호</label>
                    <div>
                        <input value={firstCompanyPhone}
                               onChange={(e) => onChangeHandler(e, setFirstCompanyPhone)}/><span>-</span>
                        <input value={secondCompanyPhone}
                               onChange={(e) => onChangeHandler(e, setSecondCompanyPhone)}/><span>-</span>
                        <input value={thirdCompanyPhone} onChange={(e) => onChangeHandler(e, setThirdCompanyPhone)}/>
                    </div>
                </li>
                <li>
                    <label>휴대폰번호</label>
                    <div>
                        <input value={firstPhone} onChange={(e) => onChangeHandler(e, setFirstPhone)}/><span>-</span>
                        <input value={secondPhone} onChange={(e) => onChangeHandler(e, setSecondPhone)}/><span>-</span>
                        <input value={thirdPhone} onChange={(e) => onChangeHandler(e, setThirdPhone)}/>
                    </div>
                </li>
                <li>
                    <label>주소</label>
                    <div id="addressInput">
                        <div>
                            <input value={addressNumber} onChange={(e) => onChangeHandler(e, setAddressNumber)}
                                   placeholder="우편번호"/>
                            <button id="searchAddressBtn" className="blackBtn" onClick={openAddressModal}>주소 검색</button>
                        </div>
                        <input value={address} onChange={(e) => onChangeHandler(e, setAddress)} placeholder="주소"/>
                        <input value={detailAddress} onChange={(e) => onChangeHandler(e, setDetailAddress)}
                               placeholder="상세주소"/>
                    </div>
                </li>
            </ul>
            <div className="myInfoController">
                <button id="saveMyInfoBtn" className="blackBtn" onClick={modifyMyInfo}>저장</button>
            </div>
            {isModalOpen && <Modal_Password showModal={isModalOpen} onClose={closeModal}/>}
            {
                isSearchModalOpen && <Modal_SearchAddress showModal={isSearchModalOpen} onClose={closeAddressModal}
                                                          setAddressNumber={setAddressNumber}
                                                          setAddressText={setAddress}
                                                          setDetailAddress={setDetailAddress}/>
            }
        </div>
    )
}