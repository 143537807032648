import {useState} from "react";
import {modifyCode} from "../api/Api";
import {useNavigate} from "react-router-dom";
import Modal_AgencyConnection from "./Modal_AgencyConnection";

export default function Modal_ModifyCodeValue({code_value, code_key, onClose, getCodes, setLv, level}) {
    const [newValue, setNewValue] = useState("");
    const navigate = useNavigate();
    const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
    // const handleBackgroundClick = (e) => {
    //     if (e.target.classList.contains("modal-wrapper")) {
    //         onClose();
    //     }
    // };

    const handleModifyCodeValue = async () => {
        if (!newValue) {
            alert("변경할 코드값을 입력해주세요.");
            return;
        }
        const isConfirmed = window.confirm("저장하시겠습니까?");
        if (isConfirmed) {
            const patchData = {code: code_key, new_code_value: newValue}
            const mod = await modifyCode(patchData);
            if (mod === 200) {
                alert("수정이 완료되었습니다.");
                // console.log(level.code_key);
                await getCodes(setLv, level.code_key);
                onClose();
            } else if (mod === 401) {
                navigate("/");
            }
        }
    }

    const openModal = () => {
        setIsConnectModalOpen(true);
    }
    const closeModal = () => {
        setIsConnectModalOpen(false);
    };

    return (
        <div className="modal-wrapper">
            <div className="modal" id="regCodeModal">
                <div className="header">
                    <p>코드 수정</p>
                    <span className="close" onClick={onClose}>&times;</span>
                </div>
                <div className="content">
                    {code_key.length >= 11 && code_key.includes("DA") && <button onClick={openModal}>대리점 연결 관리</button>}
                    <div className="regCodeInputs">
                        <div>CD</div>
                        <input disabled={true} value={code_key}/>
                        <div>VALUE</div>
                        <input disabled={true} value={code_value}/>
                        <div>NEW VALUE</div>
                        <input placeholder="변경할 코드값을 입력하세요" value={newValue}
                               onChange={(e) => setNewValue(e.target.value)} autoFocus={true}/>
                    </div>
                    <button id="regSaveBtn" className="blackBtn" onClick={handleModifyCodeValue}>저장</button>
                </div>
            </div>
            {isConnectModalOpen && <Modal_AgencyConnection onClose={closeModal} affiliation_code={code_key}/>}
        </div>
    )
}