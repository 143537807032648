// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viewGuaranteeBtn {
    background-color: #B1B1B1;
    border: none;
    color: white;
    padding: 8px 10px;
    display: inline-block;
    border-radius: 4px;
    font-weight: bold;
    font-size: min(1.1Vw, 13px);
}

.viewGuaranteeBtn:hover {
    background-color: #a6a6a6;
}`, "",{"version":3,"sources":["webpack://./src/styles/GuaranteeList.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB;IACjB,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".viewGuaranteeBtn {\n    background-color: #B1B1B1;\n    border: none;\n    color: white;\n    padding: 8px 10px;\n    display: inline-block;\n    border-radius: 4px;\n    font-weight: bold;\n    font-size: min(1.1Vw, 13px);\n}\n\n.viewGuaranteeBtn:hover {\n    background-color: #a6a6a6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
