import "../styles/Modal.css";
import "../styles/Modal_RequestWrite.css";
import DateInput from "./DateInput";
import * as React from "react";
import dayjs from "dayjs";
import {createRequest, getAgencyCodeList, getCodeList, getConnection, getFinancialList} from "../api/Api";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export default function Modal_RequestWrite({showModal, onClose, user, reRender, setReRender}) {
    const navigate = useNavigate();
    const [selectedDesiredDate, setSelectedDesiredDate] = React.useState(dayjs());
    const [customer, setCustomer] = useState("");
    const [vehicleCompanyList, setVehicleCompanyList] = useState([]);
    const [vehicleCompany, setVehicleCompany] = useState("");
    const [vehicleType, setVehicleType] = useState("");
    const [vehicleList, setVehicleList] = useState([]);
    const [contractNumber, setContractNumber] = useState("");
    const [vehicleNumber, setVehicleNumber] = useState("");
    const [productList, setProductList] = useState([]);
    const [tintingFilm, setTintingFilm] = useState("");
    const [densityList, setDensityList] = useState([]);
    const [tintingDensity_front, setTintingDensity_front] = useState("");
    const [tintingDensity_side1, setTintingDensity_side1] = useState("");
    const [tintingDensity_side2, setTintingDensity_side2] = useState("");
    const [tintingDensity_side3, setTintingDensity_side3] = useState("");
    const [tintingDensity_back, setTintingDensity_back] = useState("");
    const [requestDetails, setRequestDetails] = useState("");
    const [responsibleFc, setResponsibleFc] = useState("");
    const [fcList, setFcList] = useState([]);
    const [agencyList, setAgencyList] = useState([]);
    const [agency, setAgency] = useState("");

    const handleBackgroundClick = (e) => {
        if (e.target.classList.contains("modal-wrapper")) {
            onClose();
        }
    };

    const getFc = async () => {
        const fc = await getFinancialList(user.affiliation_code);
        if (fc) {
            if (fc.status === 200) {
                setFcList(fc.data.fc_list);
            } else if (fc.status === 401) {
                navigate("/");
            }
        }
    }

    const getVehicleCompanyList = async () => {
        const list = await getCodeList('AT');
        setVehicleCompanyList(list);
    }

    const getVehicleList = async () => {
        const list = await getCodeList(vehicleCompany);
        setVehicleList(list);
    }

    const getProductList = async () => {
        const list = await getCodeList('PD');
        setProductList(list);
    }

    const getDensityList = async () => {
        const densityList = await getCodeList(tintingFilm);
        setDensityList(densityList);
    }

    const getAgencyList = async () => {
        const response = await getConnection('dealer', user.affiliation_code);
        if (response.status === 200) {
            setAgencyList([...response.data.dasa_relation,{ code_key: '', code_value: '요청사항 확인' }]);
        } else setAgencyList([]);
    }

    useEffect(() => {
        getFc();
        getVehicleCompanyList();
        getVehicleList();
        getProductList();
        getDensityList();
        getAgencyList();
    }, []);

    useEffect(() => {
        if (productList.length > 0) {
            getDensityList();
        }
    }, [tintingFilm]);

    useEffect(() => {
        if (vehicleCompanyList.length > 0) {
            getVehicleList();
        }
    }, [vehicleCompany]);

    useEffect(() => {
        if (agencyList && agencyList.length === 1) {
            setAgency(agencyList[0].code_key);
        }
    }, [agencyList]);

    const handleSaveRequest = async (e) => {
        e.preventDefault();
        if (customer.length === 0) {
            alert("계약자명을 입력해주세요.");
            return;
        }
        if (!selectedDesiredDate) {
            alert("시공희망일을 선택해주세요.");
            return;
        }
        if (selectedDesiredDate.format("YYYY-MM-DD") === "Invalid Date") {
            alert("시공희망일을 선택해주세요.");
            return;
        }
        if (selectedDesiredDate.format('YYYY-MM-DD') < dayjs().format('YYYY-MM-DD')) {
            alert("과거의 일자를 선택할 수 없습니다. 유효한 시공희망일자를 선택해주세요.");
            return;
        }
        if (vehicleType.length === 0) {
            alert("차종을 선택해주세요.");
            return;
        }
        // if (contractNumber.length === 0) {
        //     alert("계약번호를 입력해주세요.");
        //     return;
        // }
        if (vehicleNumber.length === 0) {
            alert("차대번호를 입력해주세요.");
            return;
        }
        if (vehicleNumber.length < 8 || vehicleNumber.length > 8) {
            alert("차대번호 끝 8자리를 입력해주세요.");
            return;
        }
        if (!tintingFilm) {
            alert("틴팅 필름을 선택해주세요.");
            return;
        }
        if (!tintingDensity_front) {
            alert("전면 틴팅 농도를 선택해주세요.");
            return;
        }
        if (!tintingDensity_side1 || !tintingDensity_side2 || !tintingDensity_back) {
            alert("측후면 틴팅 농도를 선택해주세요.");
            return;
        }
        if (!agency) {
            alert("시공 대리점을 선택해주세요.");
            return;
        }
        if (!responsibleFc) {
            alert("전시장 담당자를 선택해주세요.");
            return;
        }
        const isConfirmed = window.confirm("요청서를 등록하시겠습니까?");
        if (isConfirmed) {
            const request = {
                wished_agency_affiliation: agency,
                responsible_fc_id: responsibleFc,
                customer_name: customer,
                wished_date: selectedDesiredDate.format("YYYY-MM-DD"),
                vehicle_info_number: vehicleNumber,
                vehicle_code: vehicleType,
                contract_number: contractNumber,
                product_code: tintingFilm,
                tinting_density: tintingDensity_front + "/" + tintingDensity_side1 + "/" + tintingDensity_side2 + "/" + tintingDensity_side3 + "/" + tintingDensity_back,
                request_details: requestDetails,
            };
            const newRequest = await createRequest({request});
            if (newRequest === 200) {
                alert("시공 요청서가 등록되었습니다.");
                setReRender(!reRender);
                onClose();
                navigate("/req/list");
            } else if (newRequest === 401) {
                navigate("/");
            }
        }
    };
    const onChangeHandler = (e, setState) => {
        const value = e.target.value;
        setState(value);
    };
    return (
        <div className="modal-wrapper">
            <div className="modal" id="request-details-modal">
                <div className="header">
                    <p>시공요청서 작성</p>
                    <span className="close" onClick={onClose}>
            &times;
          </span>
                </div>
                <div className="content">
                    <div className="modal-inputs">
                        <div className="modal-inputs-category">딜러사/전시장</div>
                        <div>{user.affiliation_value}</div>
                        <div className="modal-inputs-category">영업직원</div>
                        <div>{user.name}</div>
                        <div className="modal-inputs-category">연락처</div>
                        <div>{user.phone}</div>
                        <div className="modal-inputs-category">계약자명</div>
                        <div>
                            <input
                                value={customer}
                                onChange={(e) => onChangeHandler(e, setCustomer)}
                            />
                        </div>
                        <div className="modal-inputs-category">시공희망일</div>
                        <div id="request-input-date">
                            <DateInput
                                date={selectedDesiredDate}
                                setDate={setSelectedDesiredDate}
                            />
                        </div>
                        <div className="modal-inputs-category">차종</div>
                        <div>
                            <select value={vehicleCompany} onChange={(e) => onChangeHandler(e, setVehicleCompany)}>
                                <option value="">선택</option>
                                {
                                    vehicleCompanyList && vehicleCompanyList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                            <select
                                value={vehicleType}
                                onChange={(e) => onChangeHandler(e, setVehicleType)}
                            >
                                <option value="">선택</option>
                                {
                                    vehicleList && vehicleList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                        </div>
                        {/*<div className="modal-inputs-category">계약번호</div>*/}
                        {/*<input*/}
                        {/*    value={contractNumber}*/}
                        {/*    maxLength={10}*/}
                        {/*    onChange={(e) => onChangeHandler(e, setContractNumber)}*/}
                        {/*/>*/}
                        <div className="modal-inputs-category">차대번호</div>
                        <div>
                            <input
                                value={vehicleNumber}
                                maxLength={8}
                                onChange={(e) => onChangeHandler(e, setVehicleNumber)}
                            />
                            <p className="caution">차대번호 끝 8자리</p>
                        </div>
                        <div className="modal-inputs-category">틴팅필름</div>
                        <div>
                            <select
                                value={tintingFilm}
                                onChange={(e) => onChangeHandler(e, setTintingFilm)}
                            >
                                <option value="">선택</option>
                                {
                                    productList && productList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="modal-inputs-category">틴팅위치</div>
                        <div id="input_tintingPosition">
                            <span>전면</span> <span>측면 1열</span> <span>측면 2열</span> <span>측면 3열</span> <span>후면</span>
                        </div>
                        <div className="modal-inputs-category">틴팅농도</div>
                        <div id="input_tintingDensity">
                            <select
                                value={tintingDensity_front}
                                onChange={(e) => onChangeHandler(e, setTintingDensity_front)}
                            >
                                <option value="">선택</option>
                                {
                                    densityList && densityList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                            <select
                                value={tintingDensity_side1}
                                onChange={(e) => onChangeHandler(e, setTintingDensity_side1)}
                            >
                                <option value="">선택</option>
                                {
                                    densityList && densityList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                            <select
                                value={tintingDensity_side2}
                                onChange={(e) => onChangeHandler(e, setTintingDensity_side2)}
                            >
                                <option value="">선택</option>
                                {
                                    densityList && densityList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                            <select
                                value={tintingDensity_side3}
                                onChange={(e) => onChangeHandler(e, setTintingDensity_side3)}
                            >
                                <option value="">선택</option>
                                {
                                    densityList && densityList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                            <select
                                value={tintingDensity_back}
                                onChange={(e) => onChangeHandler(e, setTintingDensity_back)}
                            >
                                <option value="">선택</option>
                                {
                                    densityList && densityList.map((item, index) => {
                                        return <option value={item['code_key']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="modal-inputs-category">요청사항</div>
                        <textarea
                            placeholder="시공점명 및 기타 요청사항을 기입해 주시기 바랍니다."
                            rows={5}
                            value={requestDetails}
                            onChange={(e) => onChangeHandler(e, setRequestDetails)}
                        />
                        <div className="modal-inputs-category">희망 대리점</div>
                        <div>
                            <select value={agency} onChange={(e) => onChangeHandler(e, setAgency)}>
                                <option value="">선택</option>
                                {
                                    agencyList?.map((item, index) => {
                                        return <option value={item['code_value']}>{item['code_value']}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="modal-inputs-category">전시장 담당자</div>
                        <div>
                            <select value={responsibleFc}
                                    onChange={(e) => onChangeHandler(e, setResponsibleFc)}>
                                <option value="">선택</option>
                                {
                                    fcList && fcList.map((item, index) => {
                                        return <option value={item['user_id']}>{item['name']}</option>
                                    })
                                }
                            </select>
                            <p className="caution">* 시공 요청서는 계약 발생 해당 월 내에 접수되어야 시공이 가능합니다. *</p>
                        </div>
                    </div>
                    <div className="detailController">
                        <button className="blackBtn" onClick={onClose}>닫기</button>
                        <button className="blackBtn" onClick={handleSaveRequest}>저장</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
