import "../styles/Modal_Password.css";
import {useState} from "react";
import {modifyPassword} from "../api/Api";
import {useNavigate} from "react-router-dom";

export default function Modal_Password({showModal, onClose}) {
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [checkNewPw, setCheckNewPw] = useState("");

    if (!showModal) {
        return null;
    }

    const modifyPw = async (e) => {
        e.preventDefault();

        if (password.length < 8 || newPassword.length < 8) {
            alert("비밀번호는 8자리 이상이어야 합니다.");
            return;
        }
        if (newPassword !== checkNewPw) {
            alert("신규 비밀번호가 일치하지 않습니다.");
            return;
        }
        const pwInfo = {
            password: password,
            new_password: newPassword,
        }
        const modify_pw = await modifyPassword(pwInfo);
        if (modify_pw === 200) {
            alert("비밀번호가 변경되었습니다.");
            onClose();
        } else if(modify_pw === 401){
            navigate("/");
        }
    }
    const handleBackgroundClick = (e) => {
        // 모달 외부를 클릭하면 모달을 닫음
        if (e.target.classList.contains("modal-wrapper")) {
            onClose();
        }
    };
    return (
        <div className="modal-wrapper" id="modifyPwModal-wrapper">
            <div className="modal" id="modifyPwModal">
                <div className="header">
                    <p>비밀번호 변경</p>
                    <span className="close" onClick={onClose}>&times;</span>
                </div>
                <div className="content">
                    <div className="modifyPwInputs">
                        <div>기존 비밀번호</div>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                        <div>새로운 비밀번호</div>
                        <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
                        <div>새로운 비밀번호 확인</div>
                        <input type="password" value={checkNewPw} onChange={(e) => setCheckNewPw(e.target.value)}/>
                    </div>
                    <button id="modifyPwBtn" className="blackBtn" onClick={modifyPw}>저장</button>
                </div>
            </div>
        </div>
    )
}