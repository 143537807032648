import "../styles/Paging.css";
import {useMediaQuery} from 'react-responsive';

export default function Paging({currentPage, lastPage, handlePageChange}) {
    const totalPages =  lastPage;
    const pages = Array.from({length: totalPages}, (_, index) => index + 1);

    const isMobile = useMediaQuery({query: "(max-width:600px"});
    // 그룹당 페이지 수
    const pagesPerGroup = isMobile ? 5 : 10;

    // 현재 페이지가 속한 그룹 계산
    const currentGroup = Math.ceil(currentPage / pagesPerGroup);

    // 그룹의 첫 번째 페이지
    const firstPageInGroup = (currentGroup - 1) * pagesPerGroup + 1;

    // 그룹의 마지막 페이지
    const lastPageInGroup = Math.min(currentGroup * pagesPerGroup, totalPages);
    return (
        <div className="pagingBtn">
            <button className="firstPage" onClick={(e) => handlePageChange(e, 1)} disabled={currentPage === 1}/>
            <button className="previousPage"
                    onClick={(e) => currentPage > 1 ? handlePageChange(e, currentPage - 1) : ""} disabled={currentPage === 1}/>
            {pages.slice(firstPageInGroup - 1, lastPageInGroup).map((pageNumber) => (
                <button
                    key={pageNumber}
                    onClick={(e) => handlePageChange(e, pageNumber)}
                    style={{
                        borderColor: currentPage === pageNumber ? "#2a89fc" : "white",
                        color: currentPage === pageNumber ? "#2a89fc" : "black",
                    }}
                    disabled={currentPage === pageNumber}
                >
                    {pageNumber}
                </button>
            ))}
            <button className="nextPage"
                    onClick={(e) => currentPage < lastPage ? handlePageChange(e, currentPage + 1) : ""} disabled={currentPage === lastPage}/>
            <button className="lastPage"
                    onClick={(e) => handlePageChange(e, lastPage)} disabled={currentPage === lastPage}/>
        </div>
    )
}