import "../styles/SearchBar.css";
import {useState} from "react";

export default function SearchBar_User({searchCondition, setSearchCondition, setCurrentPage}) {
    const [authorityType, setAuthorityType] = useState("");
    const [userName, setUserName] = useState("");
    const [userId, setUserId] = useState("");
    const [isActive, setIsActive] = useState(null);
    const [affiliation, setAffiliation] = useState("");
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const onChangeHandler = (e, setState) => {
        const value = e.target.value;
        setState(value);
    };
    const handleSearch = () => {
        setSearchCondition({
            authority_type: authorityType,
            user_name: userName,
            id: userId,
            is_active: isActive,
            affiliation: affiliation,
        });
        setCurrentPage(1);
    }

    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
    };

    return (
        <div className="searchBar">
            <button id="search_switch" className="blackBtn"
                    onClick={toggleSearch}>{isSearchOpen ? "검색 항목 닫기" : "검색 항목 열기"}</button>
                <div className={`search_admin ${isSearchOpen ? "open" : ''}`}>
                    <div className="search_input">
                        <div>
                            <label>사용자그룹</label>
                            <select id="search_userGroup" className="search_values" value={authorityType}
                                    onChange={(e) => onChangeHandler(e, setAuthorityType)}>
                                <option value="">선택</option>
                                <option value="agency">대리점</option>
                                <option value="dealer">지점</option>
                                <option value="financial">지점장</option>
                                <option value="admin">본사</option>
                            </select>
                        </div>
                        <div><label>사용자명</label><input type="text" id="search_name" className="search_values"
                                                       value={userName}
                                                       onChange={(e) => onChangeHandler(e, setUserName)}/>
                        </div>
                        <div><label>아이디</label><input type="text" id="search_id" className="search_values"
                                                      value={userId} onChange={(e) => onChangeHandler(e, setUserId)}/>
                        </div>
                        <div><label>삭제여부</label>
                            <select id="search_deletion" className="search_values" value={isActive}
                                    onChange={(e) => onChangeHandler(e, setIsActive)}>
                                <option value="">선택</option>
                                <option value={false}>예</option>
                                <option value={true}>아니오</option>
                            </select>
                        </div>
                        <div><label>소속</label><input type="text" id="search_affiliation"
                                                          className="search_values" value={affiliation} onChange={(e)=>onChangeHandler(e, setAffiliation)}/>
                        </div>
                    </div>
                    <button id="searchBtn" className="blackBtn" onClick={handleSearch}>검색</button>
                </div>
        </div>
    )
}