import {useState} from "react";
import {useNavigate} from "react-router-dom";
import "../styles/JoinTerms.css";

const JoinTerms = () => {
    const navigate = useNavigate();
    const [serviceTerms, setServiceTerms] = useState(false);
    const [privacyTerms, setPrivacyTerms] = useState(false);

    const cancelBtnClick = () => {
        navigate("/");
    };

    const nextBtnClick = () => {
        if (serviceTerms === false) {
            alert("서비스 이용약관에 동의해주세요.");
        } else if (privacyTerms === false) {
            alert("개인정보 취급방침 및 이용약관에 동의해주세요.");
        } else if (serviceTerms + privacyTerms === 2) {
            navigate("/join/form");
        }
    };

    return (
        <div className="join-term-wrapper">
            <div className="agreeBtn" id="allAgreeBtn">
                <input
                    type="checkbox"
                    checked={serviceTerms + privacyTerms === 2}
                    onChange={() => {
                        setPrivacyTerms(!(serviceTerms * privacyTerms));
                        setServiceTerms(!(serviceTerms * privacyTerms));
                    }}
                ></input>
                서비스 이용약관, 개인정보 취급방침 및 이용약관에 모두 동의합니다.
            </div>
            <h3>1. 서비스 이용약관</h3>
            <div className="terms_txt" id="terms-service">
                제1조(목적)
                <br/>
                <br/>이 약관은 (주)스미스클럽이 운영하는 파트너 업무포탈에서 제공하는
                인터넷 관련 서비스(이하 "서비스"라 한다)를 이용함에 있어 이용자의 권리의무 및 책임사항을 규정함을 목적으로 합니다.
                <br/>
                <br/>
                제2조(정의)
                <br/>
                <br/>① "스미스클럽 프로모션 시스템"이란 (주)스미스클럽이 제공하는 업무 관련 내용(이하 "서비스"이라 함)을 이용자에게 제공하기 위하여
                컴퓨터 등 정보통신설비를 이용하여 서비스 하는 웹사이트를 말합니다.
                <br/>② "이용자"란 "스미스클럽 프로모션 시스템"에 접속하여 이 약관에
                따라 "(주)스미스클럽이"이 제공하는 서비스를 받는 회원을 말합니다.
                <br/>③ '회원'이라 함은 "(주)스미스클럽"에 개인정보를 제공하여
                회원등록을 한 자로서, "스미스클럽 프로모션 시스템"의 정보를 지속적으로
                제공받으며, (주)스미스클럽이 제공하는 서비스를 계속적으로 이용할 수
                있는 자를 말합니다.
                <br/>
                <br/>
                제3조(서비스의 제공 및 변경)
                <br/>
                <br/>
                ① "스미스클럽 프로모션 시스템"은 다음과 같은 업무를 수행합니다.
                <br/>
                1. 프로모션 시공요청서를 접수하는 서비스
                <br/>
                2. 프로모션 시공 내역을 조회하는 서비스
                <br/>
                3. 기타 영업 활동에 필요한 정보
                <br/>
                <br/>
                제4조(서비스의 중단)
                <br/>
                <br/>① “스미스클럽 프로모션 시스템”은 컴퓨터 등 정보통신설비의
                보수점검 교체 및 고장 · 통신 두절 등의 사유가 발생한 경우에는 서비스의
                제공을 일시적으로 중단할 수 있습니다.
                <br/>
                <br/>
                제5조(회원가입)
                <br/>
                <br/> ① 이용자는 "스미스클럽 프로모션 시스템"이 정한 가입 양식에 따라
                회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서
                회원가입을 신청합니다.
                <br/>② 회원가입계약의 성립시기는 "스미스클럽 프로모션 시스템"의
                승낙이 회원에게 도달한 시점으로 합니다.
                <br/>
                <br/>
                제6조(회원 탈퇴 및 자격 상실 등)
                <br/>
                <br/>① 회원은 "스미스클럽 프로모션 시스템"에 언제든지 탈퇴를 요청할
                수 있으며 "스미스클럽 프로모션 시스템"은 즉시 회원탈퇴를 처리합니다.
                <br/>② 회원이 다음 각호의 사유에 해당하는 경우, "스미스클럽 프로모션
                시스템"은 회원자격을 제한 및 정지시킬 수 있습니다.
                <br/>
                1. 가입 신청 시 허위 내용을 등록한 경우
                <br/>
                2. 파트너사의 회사에서 퇴사한 경우
                <br/>
                3. "스미스클럽 프로모션 시스템"을 이용하여 법령 또는 이 약관이
                금지하거나 공서양속에 반하는 행위를 하는 경우
                <br/>
                <br/>본 약관은 2016년 09월 01일부터 적용됩니다.
            </div>
            <div className="agreeBtn" id="serviceAgreeBtn">
                <input
                    type="checkbox"
                    checked={serviceTerms}
                    onChange={() => {
                        setServiceTerms(!serviceTerms);
                    }}
                ></input>
                서비스 이용약관에 모두 동의합니다.
            </div>
            <h3>2. 개인정보 취급방침 및 이용약관</h3>
            <div className="terms_txt" id="terms-privacy">
                '㈜스미스클럽'(이하 '회사'라고 함)은 파트너사 회원님들의 개인정보를
                매우 중요시하며, [개인정보보호법]을 준수하고 있습니다. 본
                개인정보처리방침은 회원님들께서 제공하시는 개인정보가 어떠한 용도와
                방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고
                있는지 알려드립니다. 회사는 개인정보보호처리방침을 개정하는 경우
                웹사이트(또는 개별공지)를 통하여 고지할 것입니다.
                <br/>
                <br/>
                1. 개인정보의 수집에 대한 동의
                <br/>
                <br/>
                회사는 회원님의 개인정보 수집과 관련하여 회사의 개인정보보호정책 또는
                이용약관의 내용에 대해 「동의」 또는 「동의하지 않음」을 선택할 수
                있는 절차를 마련하여 회원님이 「동의」 버튼을 클릭하면 개인정보 수집에
                대해 동의한 것으로 봅니다.
                <br/>
                <br/>
                2. 수집하는 개인정보의 항목 및 수집방법 회원 가입 시 필수적으로 필요한
                개인정보를 얻고 있으며 이 외에 선택 항목에 대해서는 정보 제공여부를
                회원님께서 선택하실 수 있습니다. 회원님의 자발적 개인정보 등록(아이디,
                비밀번호, 성명, 회사명, 주소, 연락처, E-Mail)외에 서버의 로그파일이나
                쿠키 등을 이용하여 회원의 방문 및 이용내역을 수집합니다.
                <br/>
                <br/>
                3. 개인정보의 수집 및 이용목적 회원가입을 통해 입수한 회원 개인정보는
                회원관리 및 서비스의 관리, 마케팅 및 영업정보의 제공, 세미나 등의
                이벤트 초대와 제공, 회원의 서비스 이용에 대한 통계 등의 목적을 위해
                활용합니다.
                <br/>
                <br/>
                4. 개인정보의 보유 이용기간 및 폐기 회원가입 후 회사가 제공하는
                서비스를 받는 동안 회원님의 개인정보는 회사가 지속적으로 보유하며
                회원님께 서비스를 제공하기 위하여 이용합니다. 회원님께서 가입 해지를
                요청한 경우 및 자격 상실 시에는 개인정보는 즉시 삭제되며, 어떤 이유나
                방법으로도 재생되거나 이용할 수 없도록 처리됩니다. 개인정보 수집목적이
                달성된 경우 파기를 원칙으로 하고 있으나, 상법, 전자상거래 등에서의
                소비자 보호에 관한 법률, 국세기본법 등 규정에 의하여 회원님의
                개인정보를 보유 할 수 있습니다.
                <br/>
                <br/>
                5. 이용자 및 법정대리인의 권리와 그 행사방법 이용자 및 법정대리인은
                개인정보와 관련하여 전화, 서면 등을 이용한 개인정보 열람, 정정．삭제,
                처리정지 등의 권리를 행사할 수 있습니다.
                <br/>
                <br/>
                6. 개인정보의 제3자 제공 회사는 회원 본인의 동의 없이 개인정보를 다른
                개인이나 기업, 기관과 공유하지 않는 것을 원칙으로 하고 있습니다. 다만,
                회원님께서 가입 약관을 위배하거나 타인에게 피해를 주는 등 부정한 행위
                등으로 법적인 조치를 취하고자 하는 경우 또는 사법기관의 요청이 있을
                시에는 회원님의 동의 없이 관련기간에 제공할 수 있습니다.
                <br/>
                <br/>
                7. 개인정보 처리의 위탁 회사는 회원님들의 동의 없이 개인정보 취급을
                외부 업체에 위탁하지 않습니다. 향후 서비스향상 및 보다 질 높은 서비스
                제공을 위해 외부 전문업체에 위탁하여 운영하게 될 경우, 위탁 업무
                내용에 대해 회원님들께 통지하고 필요한 경우 사전 동의를 받도록
                하겠습니다.
                <br/>
                <br/>
                8. 개인정보 보호책임자 개인정보보호처리방침 에 대하여 회원님들께서
                궁금하신 사항이 있으시면 아래의 관리 담당자 앞으로 연락 주시기
                바랍니다.
                <br/>
                <br/>
                [개인정보 보호책임자] 이름 : 김헌균 소속 및 직위 : 스미스클럽 상무
                전화번호 : 031-903-2121 이메일 : hg.kim@smithclub.net
                <br/>
                <br/>
                [개인정보 관리담당자] 이름 : 임정수 소속 및 직위 : 경영지원팀 차장
                전화번호 : 031-903-2121(내선4번) 이메일 : js.lim@smithclub.net
                <br/>
                <br/>
                9. 개인정보의 안전성 확보조치 회원님께서 제공하신 모든 정보는 방화벽
                등 보안장비에 의해 안전하게 보호되고 있습니다. 또한 개인정보를
                처리하는 인원을 최소한으로 제한하고 정기적인 교육과 비밀번호 갱신을
                통해 개인정보가 유출되지 않도록 안전하게 관리하고 있습니다.
                <br/>
                <br/>
                10. 정책 변경에 따른 공지의무 이 개인정보처리방침은 법령, 정책 또는
                보안기술의 변경에 따라, 내용의 추가 · 삭제 및 수정이 있을 시에는
                변경되는 개인정보처리방침을 시행하기 최소 7일전에 웹사이트 등에
                변경이유 및 내용 등을 공지하도록 하겠습니다.
                <br/>
                <br/>
                공고일자 : 2016년 09월 01일 <br/>
                시행일자 : 2016년 09월 01일
            </div>
            <div className="agreeBtn" id="privacyAgreeBtn">
                <input
                    type="checkbox"
                    checked={privacyTerms}
                    onChange={() => {
                        setPrivacyTerms(!privacyTerms);
                    }}
                ></input>
                개인정보 취급방침 및 이용약관에 동의합니다.
            </div>
            <div className="joinTermController">
                <button className="grayBtn"
                        onClick={cancelBtnClick}
                >
                    취소
                </button>
                <button className="blackBtn"
                        onClick={nextBtnClick}
                >
                    다음
                </button>
            </div>
        </div>
    );
};

export default JoinTerms;

