import {useState} from "react";

export default function SearchBar_Guarantee({searchCondition, setSearchCondition, setCurrentPage}){
    const [dealers, setDealers] = useState("");
    const [exhibitionHall, setExhibitionHall] = useState("");
    const [salesPerson, setSalesPerson] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [agency, setAgency] = useState("");
    const [carNumber, setCarNumber] = useState("");
    const [warrantyNumber, setWarrantyNumber] = useState("");
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
    };

    const onChangeHandler = (e, setState) => {
        setState(e.target.value);
    }
    const handleGuaranteeSearch = () => {
        setSearchCondition({
            dealer_affiliation:dealers,
            dealer_location:exhibitionHall,
            sales_person_name:salesPerson,
            customer_name:customerName,
            agency_affiliation:agency,
            car_number:carNumber,
            warranty_number:warrantyNumber,
        });
        setCurrentPage(1);
    }
    return (
        <div className="searchBar">
            <button id="search_switch" className="blackBtn"
                    onClick={toggleSearch}>{isSearchOpen ? "검색 항목 닫기" : "검색 항목 열기"}</button>
                <div className={`search_admin ${isSearchOpen ? "open" : ''}`}>
                    <div className="search_input">
                        <div><label>딜러사</label><input type="text" id="search_dealers" className="search_values" value={dealers} onChange={(e)=>onChangeHandler(e, setDealers)}/></div>
                        <div><label>전시장</label><input type="text" id="search_exhibitionHall" className="search_values" value={exhibitionHall} onChange={(e)=>onChangeHandler(e, setExhibitionHall)}/></div>
                        <div><label>영업직원</label><input type="text" id="search_salesPerson" className="search_values" value={salesPerson} onChange={(e)=>onChangeHandler(e, setSalesPerson)}/></div>
                        <div><label>고객명</label><input type="text" id="search_customerName" className="search_values" value={customerName} onChange={(e)=>onChangeHandler(e, setCustomerName)}/></div>
                        <div><label>시공점명</label><input type="text" id="search_agency" className="search_values" value={agency} onChange={(e)=>onChangeHandler(e, setAgency)}/></div>
                        <div><label>차량번호</label><input type="text" id="search_carNumber" className="search_values" value={carNumber} onChange={(e)=>onChangeHandler(e, setCarNumber)}/></div>
                        <div><label>보증번호</label><input type="text" id="search_warrantyNumber" className="search_values" value={warrantyNumber} onChange={(e)=>onChangeHandler(e, setWarrantyNumber)}/></div>
                    </div>
                    <button id="searchBtn" className="blackBtn" onClick={handleGuaranteeSearch}>검색</button>
                </div>
        </div>
    )
}