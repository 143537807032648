// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accessInfo .inventoryStatus {
    display: flex;
    height: 32px;
    margin-bottom: 32px;
    align-items: center;
    border: 1px solid #be976b;
}

.accessInfo .inventoryStatus > div {
    height: 32px;
    line-height: 32px;
}

.accessInfo .inventoryStatus > div:first-child {
    font-weight: bold;
    background-color: #be976b;
    color: white;
    padding: 0 25.6px;
    font-size: 19.2px;
}

.accessInfo .inventoryStatus > div:nth-child(2) {
    background-color: #F5F5F5;
    padding: 0 16px;
    font-size: 12px;
}

.accessInfo .inventoryStatus > div:nth-child(3) {
    padding: 0 16px;
    font-size: 12px;
}

.accessInfo {
    display: flex;
    justify-content: space-between;
}

.accessController {
    display: flex;
    justify-content: right;
    align-items: center;
    height: 32px;
    margin-bottom: 32px;
    font-size: 16px;
}

.accessController #logoutBtn {
    margin-left: 16px;
    padding: 5px 13px;
    font-size: 13px;
}

.accessController p {
    margin: 0;
    color: #347DE6;
    font-weight: bold;
}


@media (max-width: 600px) {
    .accessInfo {
        display: none;
    }

    .accessController {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/AccessInfo.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,cAAc;IACd,iBAAiB;AACrB;;;AAGA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".accessInfo .inventoryStatus {\n    display: flex;\n    height: 32px;\n    margin-bottom: 32px;\n    align-items: center;\n    border: 1px solid #be976b;\n}\n\n.accessInfo .inventoryStatus > div {\n    height: 32px;\n    line-height: 32px;\n}\n\n.accessInfo .inventoryStatus > div:first-child {\n    font-weight: bold;\n    background-color: #be976b;\n    color: white;\n    padding: 0 25.6px;\n    font-size: 19.2px;\n}\n\n.accessInfo .inventoryStatus > div:nth-child(2) {\n    background-color: #F5F5F5;\n    padding: 0 16px;\n    font-size: 12px;\n}\n\n.accessInfo .inventoryStatus > div:nth-child(3) {\n    padding: 0 16px;\n    font-size: 12px;\n}\n\n.accessInfo {\n    display: flex;\n    justify-content: space-between;\n}\n\n.accessController {\n    display: flex;\n    justify-content: right;\n    align-items: center;\n    height: 32px;\n    margin-bottom: 32px;\n    font-size: 16px;\n}\n\n.accessController #logoutBtn {\n    margin-left: 16px;\n    padding: 5px 13px;\n    font-size: 13px;\n}\n\n.accessController p {\n    margin: 0;\n    color: #347DE6;\n    font-weight: bold;\n}\n\n\n@media (max-width: 600px) {\n    .accessInfo {\n        display: none;\n    }\n\n    .accessController {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
