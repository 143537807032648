// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#noticeModal {
    width: 60%;
    height: 80%;
}

#noticeModal .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#noticeModal .quill {
    max-height: 84%;
    display: block;
    overflow-x: unset;
}

#noticeModal .ql-toolbar.ql-snow {
    height: 10px;
    background-color: rgba(192, 192, 192, 0.3);
}

#noticeModal .ql-container.ql-snow {
    resize: none;
    height: calc(100% - 18px);
}

#noticeModal .noticeController {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
}

#noticeModal .noticeController span {
    font-size: 14px;
}

@media screen and (max-width: 900px) {
    #noticeModal {
        width: 90%;
        height: 90%;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Modal_Notice.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,0CAA0C;AAC9C;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,QAAQ;AACZ;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,UAAU;QACV,WAAW;IACf;AACJ","sourcesContent":["#noticeModal {\n    width: 60%;\n    height: 80%;\n}\n\n#noticeModal .content {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n#noticeModal .quill {\n    max-height: 84%;\n    display: block;\n    overflow-x: unset;\n}\n\n#noticeModal .ql-toolbar.ql-snow {\n    height: 10px;\n    background-color: rgba(192, 192, 192, 0.3);\n}\n\n#noticeModal .ql-container.ql-snow {\n    resize: none;\n    height: calc(100% - 18px);\n}\n\n#noticeModal .noticeController {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    gap: 4px;\n}\n\n#noticeModal .noticeController span {\n    font-size: 14px;\n}\n\n@media screen and (max-width: 900px) {\n    #noticeModal {\n        width: 90%;\n        height: 90%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
