import "../styles/Modal_AgencySelect.css";
import {useState} from "react";
import {allocateAgency, searchAgency} from "../api/Api";
import {useNavigate} from "react-router-dom";

export default function Modal_AgencySelect({showModal, onClose, items, reRender, setReRender, detailModalClose}) {
    const navigate = useNavigate();
    const tableHeaders = [
        {text: "지역", value: "location"},
        {text: "대리점", value: "agency_affiliation"},
        {text: "담당자", value: "name"}
    ]
    const headerKey = tableHeaders.map((header) => header.value);
    const [searchValue, setSearchValue] = useState("");
    const [agencyList, setAgencyList] = useState([]);
    const [selectedAgency, setSelectedAgency] = useState("");
    const [message, setMessage] = useState("");

    if (!showModal) {
        return null;
    }
    const handleBackgroundClick = (e) => {
        if (e.target.classList.contains("modal-wrapper")) {
            e.stopPropagation();
            onClose();
        }
    };

    const handleSearchAgency = async () => {
        setMessage("");
        const result = await searchAgency(searchValue);
        if (result) {
            if (result.status === 200) {
                setAgencyList(result.data.agency_list);
                if (result.data.agency_list.length === 0) {
                    setMessage("검색 결과가 존재하지 않습니다.");
                }
            } else if (result.status === 401) {
                navigate("/");
            }
        }
    }

    const handleClickAgency = (agency, index) => {
        setSelectedAgency(agency);
        const allTableBodies = document.querySelectorAll('[id^="table-body_"]');
        allTableBodies.forEach(body => {
            body.style.backgroundColor = "white";  // 초기 스타일로 변경
        });
        document.getElementById(`table-body_${index}`).style.backgroundColor = "lightgray";
    }

    const handleAllocAgency = async () => {
        if(!selectedAgency){
            alert("배정할 대리점을 선택해주세요.");
            return;
        }
        if (items instanceof Set) {
            let isAllOk = true;
            for(const item of items){
                const patchData = {
                    job_request_id: item.id,
                    agency_affiliation_code: selectedAgency,
                };
                const result = await allocateAgency(patchData);
                if (result === 401) {
                    alert("세션이 만료되었습니다. 다시 로그인해주세요.");
                    navigate("/");
                    isAllOk = false;
                    break;
                } else if (result === 410) {
                    alert("권한이 유효하지 않습니다.");
                    isAllOk = false;
                    break;
                } else if (result === 425) {
                    alert(`${item.customer_name}님의 요청서를 배정할 수 없습니다. 대리점의 재고가 부족합니다.`);
                    isAllOk = false;
                } else if (result === 430) {
                } else if(result !== 200){
                    alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
                    isAllOk = false;
                    break;
                }
            }
            if(isAllOk){
                alert("대리점 배정이 완료되었습니다.");
                setReRender(!reRender);
                onClose();
                if (detailModalClose) {
                    detailModalClose();
                }
            }
        } else {
            const patchData = {
                job_request_id: items.id,
                agency_affiliation_code: selectedAgency,
            }
            const alloc = await allocateAgency(patchData);
            if (alloc === 200) {
                alert("대리점 배정이 완료되었습니다.");
                setReRender(!reRender);
                onClose();
                detailModalClose();
            } else if (alloc === 410) {
                alert("권한이 유효하지 않습니다.");
            } else if (alloc === 401) {
                alert("세션이 만료되었습니다. 다시 로그인해주세요.");
                document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
                navigate("/");
            } else if (alloc === 425) {
                alert("대리점의 재고가 부족하여 배정할 수 없습니다.");
            } else if (alloc === 430) {
            } else {
                alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
            }
        }
    }

    const onSubmitSearch = (e) => {
        if (e.key === "Enter") {
          handleSearchAgency()
        }
      };

    return (
        <div className="modal-wrapper">
            <div className="modal" id="agencySelectModal">
                <div className="header">
                    <p>대리점 배정</p>
                    <span className="close" onClick={onClose}>&times;</span>
                </div>
                <div className="content">
                    <div className="agencySearch">
                        <span>대리점 검색</span>
                        <input value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyPress={onSubmitSearch}/>
                        <button className="grayBtn" onClick={handleSearchAgency}>검색</button>
                    </div>
                    <div>
                        <table className="list_table">
                            <thead>
                            <tr className="table-header">
                                {
                                    tableHeaders.map((header) =>
                                        <th key={header.text}>
                                            {header.text}
                                        </th>
                                    )
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                agencyList.length > 0 ? agencyList.map((item, index) => (
                                    <tr key={index} className="table-body" id={`table-body_${index}`}
                                        onClick={() => handleClickAgency(item.affiliation_code, index)}>
                                        {
                                            headerKey.map((key) =>
                                                <td key={key + index}>
                                                    {key === 'location' ? item['affiliation_value'].split("/")[0] : key === 'agency_affiliation' ? item['affiliation_value'].split("/")[1] : item[key]}
                                                </td>
                                            )
                                        }
                                    </tr>
                                )) : (<tr style={{height: '60px'}}>
                                    <td colSpan="3">{message}</td>
                                </tr>)
                            }
                            </tbody>
                        </table>
                        <div className="agencyAllocController">
                            {/* <button className="grayBtn" onClick={() => setAgencyList([])}>취소</button> */}
                            <button className="grayBtn" onClick={onClose}>취소</button>
                            <button className="blackBtn" onClick={handleAllocAgency}>배정</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}