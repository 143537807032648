import excelIcon from "../free-icon-sheet-11891828.png";
import GuaranteeList from "../components/GuaranteeList";
import SearchBar_Guarantee from "../components/SearchBar_Guarantee";
import {useState} from "react";
import {getWarrantyExcel} from "../api/Api";
import {useNavigate} from "react-router-dom";

export default function GuaranteeListPage() {
    const navigate = useNavigate();
    const [searchCondition, setSearchCondition] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const downloadWarrantyExcel = async () => {
        const download = await getWarrantyExcel(searchCondition);
        if (download) {
            navigate("/");
        }
    }

    return (
        <div className="page">
            <SearchBar_Guarantee searchCondition={searchCondition} setSearchCondition={setSearchCondition} setCurrentPage={setCurrentPage}/>
            <div className="printExcelBtn">
                <button onClick={downloadWarrantyExcel}>
                    <img src={excelIcon} alt="엑셀"/><span>엑셀 다운로드</span>
                </button>
            </div>
            <GuaranteeList searchCondition={searchCondition} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </div>
    )
}