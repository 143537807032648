// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noti-header {
    background-color: #676767;
    color: white;
    text-align: left;
    padding: 16px 17.6px;
    margin: 48px 0 24px 0;
    font-weight: bold;
    font-size: 17.6px;
}

.noti-body {
    display: grid;
    grid-template-columns: 11% auto;
    gap: 16px;
}

.noti-body > label {
    background-color: #f5f5f5;
    text-align: left;
    color: #646464;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 14.4px;
    padding: 4.8px 9.6px;
}

.activeController {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 14.4px;
}

.activeController > div{
    display: flex;
    align-items: center;
    margin-right: 16px;
}

.activeController input {
    margin: 0 5px 0 0;
}

#saveNotiBtn {
    font-size: 14.4px;
    padding: 8px 24px;
    margin: 80px auto 48px auto;
    width: 160px;
}

@media screen and (max-width: 900px) {
    .noti-header{
        font-size: 13px;
    }
    .noti-body > label{
        font-size: 12px;
    }
}
@media screen and (max-width: 600px) {
    .noti-header{
        font-size: 17.6px;
        padding: 16px 14px;
    }
    .noti-body{
        display: flex;
        flex-direction: column;
        padding: 0 5px;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Notice.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,oBAAoB;IACpB,qBAAqB;IACrB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,+BAA+B;IAC/B,SAAS;AACb;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;IACd,wBAAmB;IAAnB,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,2BAA2B;IAC3B,YAAY;AAChB;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ;AACA;IACI;QACI,iBAAiB;QACjB,kBAAkB;IACtB;IACA;QACI,aAAa;QACb,sBAAsB;QACtB,cAAc;IAClB;AACJ","sourcesContent":[".noti-header {\n    background-color: #676767;\n    color: white;\n    text-align: left;\n    padding: 16px 17.6px;\n    margin: 48px 0 24px 0;\n    font-weight: bold;\n    font-size: 17.6px;\n}\n\n.noti-body {\n    display: grid;\n    grid-template-columns: 11% auto;\n    gap: 16px;\n}\n\n.noti-body > label {\n    background-color: #f5f5f5;\n    text-align: left;\n    color: #646464;\n    height: fit-content;\n    font-size: 14.4px;\n    padding: 4.8px 9.6px;\n}\n\n.activeController {\n    display: flex;\n    align-items: center;\n    text-align: left;\n    font-size: 14.4px;\n}\n\n.activeController > div{\n    display: flex;\n    align-items: center;\n    margin-right: 16px;\n}\n\n.activeController input {\n    margin: 0 5px 0 0;\n}\n\n#saveNotiBtn {\n    font-size: 14.4px;\n    padding: 8px 24px;\n    margin: 80px auto 48px auto;\n    width: 160px;\n}\n\n@media screen and (max-width: 900px) {\n    .noti-header{\n        font-size: 13px;\n    }\n    .noti-body > label{\n        font-size: 12px;\n    }\n}\n@media screen and (max-width: 600px) {\n    .noti-header{\n        font-size: 17.6px;\n        padding: 16px 14px;\n    }\n    .noti-body{\n        display: flex;\n        flex-direction: column;\n        padding: 0 5px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
