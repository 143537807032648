import "../styles/Modal_UserDetails.css";
import * as React from "react";
import {useEffect, useState} from "react";
import Modal_ResetPassword from "./Modal_ResetPassword";
import {addStock, getCodeList, modifyUser} from "../api/Api";
import Modal_SearchAddress from "./Modal_SearchAddress";
import {useNavigate} from "react-router-dom";

export default function Modal_UserDetails({showModal, onClose, user, reRender, setReRender}) {
    const navigate = useNavigate();
    const [name, setName] = useState(user.name);
    const [authorityType, setAuthorityType] = useState(user.authority_type);
    const [deletion, setDeletion] = useState(user.is_active);
    const [stock, setStock] = useState(user.film_stock);
    const [additionalStock, setAdditionalStock] = useState("");
    const [email, setEmail] = useState(user.email.split("@")[0]);
    const [emailDomain, setEmailDomain] = useState(user.email.split("@")[1]);
    const [affList1, setAffList1] = useState([]);
    const [affList2, setAffList2] = useState([]);
    const [affList3, setAffList3] = useState([]);
    const [selectedAff1, setSelectedAff1] = useState("");
    const [selectedAff2, setSelectedAff2] = useState("");
    const [selectedAff3, setSelectedAff3] = useState("");
    const [firstCompanyPhone, setFirstCompanyPhone] = useState(user.company_phone_number.split("-")[0]);
    const [secondCompanyPhone, setSecondCompanyPhone] = useState(user.company_phone_number.split("-")[1]);
    const [thirdCompanyPhone, setThirdCompanyPhone] = useState(user.company_phone_number.split("-")[2]);
    const [firstPhone, setFirstPhone] = useState(user.phone_number.split("-")[0]);
    const [secondPhone, setSecondPhone] = useState(user.phone_number.split("-")[1]);
    const [thirdPhone, setThirdPhone] = useState(user.phone_number.split("-")[2]);
    const [addressNum, setAddressNum] = useState(user.address.split("/")[0]);
    const [address, setAddress] = useState(user.address.split("/")[1]);
    const [detailAddress, setDetailAddress] = useState(user.address.split("/")[2]);
    const [isPwModalOpen, setIsPwModalOpen] = useState(false);
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

    const openAddressModal = () => {
        setIsSearchModalOpen(true);
    }

    const closeAddressModal = () => {
        setIsSearchModalOpen(false);
    }

    const handleBackgroundClick = (e) => {
        if (e.target.classList.contains("modal-wrapper")) {
            onClose();
        }
    };
    const openPwModal = () => {
        setIsPwModalOpen(true);
    }

    const closePwModal = () => {
        setIsPwModalOpen(false);
    }

    const onChangeHandler = (e, setState) => {
        const value = e.target.value;
        setState(value);
    };

    const getAff1List = async () => {
        if (authorityType === 'dealer' || authorityType === 'financial') {
            const firstList = await getCodeList('DA');
            return firstList;
        } else if (authorityType === 'agency') {
            const firstList = await getCodeList('SA');
            return firstList;
        } else {
            return [];
        }
    }

    const getAff2List = async (aff1) => {
        const secondList = await getCodeList(aff1);
        return secondList;
    }

    const getAff3List = async (aff2) => {
        const thirdList = await getCodeList(aff2);
        return thirdList;
    }

    const initializer1 = async () => {
        const first_list = await getAff1List();
        setSelectedAff1(user.affiliation_code.substring(0, 5));
        setAffList1(first_list);
        const second_list = await getAff2List(user.affiliation_code.substring(0, 5));
        setSelectedAff2(user.affiliation_code.substring(0, 8));
        setAffList2(second_list);
        if (authorityType === 'dealer' || authorityType === 'financial') {
            const third_list = await getAff3List(user.affiliation_code.substring(0, 8));
            setSelectedAff3(user.affiliation_code);
            setAffList3(third_list);
        }
    }

    useEffect(() => {
        initializer1();
    }, []);

    const initializer2 = async () => {
        setSelectedAff2("");
        setSelectedAff3("");
        const second_list = await getAff2List(selectedAff1);
        setAffList2(second_list);
    }

    useEffect(() => {
        initializer2();
    }, [selectedAff1]);

    const initializer3 = async () => {
        setSelectedAff3("");
        const third_list = await getAff3List(selectedAff2);
        setAffList3(third_list);
    }

    useEffect(() => {
        initializer3();
    }, [selectedAff2]);

    const initializer4 = async () => {
        setSelectedAff1("");
        setSelectedAff2("");
        setSelectedAff3("");
        const first_list = await getAff1List();
        setAffList1(first_list);
    }

    useEffect(() => {
        initializer4();
    }, [authorityType]);


    const handleAddStock = async () => {
        if (isNaN(additionalStock)) {
            alert("추가 수량을 확인해주세요.");
            return;
        }

        if (Number(additionalStock) + stock < 0) {
            alert("추가 수량을 확인해주세요.");
            return;
        }
        const isConfirmed = window.confirm("재고를 추가하시겠습니까?");
        if (isConfirmed) {
            const patchData = {
                user_id: user.user_id,
                stock: additionalStock,
                product_code: "PD001"
            }
            const response = await addStock(patchData);
            if (response === 200) {
                alert("재고가 추가되었습니다.");
                setStock(stock + parseFloat(additionalStock));
                setAdditionalStock("");
                setReRender(!reRender);
            } else if (response === 401) {
                navigate("/");
            }
        }
    }

    const handleModifyUser = async () => {
        if (name.length === 0) {
            alert("사용자명을 입력해주세요.");
            return;
        }
        if (authorityType.length === 0) {
            alert("사용자 그룹을 선택해주세요.");
            return;
        }
        if (authorityType === 'dealer' || authorityType === 'financial') {
            if (selectedAff1.length === 0 || selectedAff2.length === 0 || selectedAff3.length === 0) {
                alert("딜러사 / 전시장을 선택해주세요.");
                return;
            }
        }
        if (authorityType === 'agency') {
            if (selectedAff1.length === 0 || selectedAff2.length === 0) {
                alert("대리점을 선택해주세요.");
                return;
            }
        }
        if ((email && !emailDomain) || (!email && emailDomain)) {
            alert("이메일 주소를 정확히 입력해주세요.");
            return;
        }
        if (firstCompanyPhone) {
            if (!secondCompanyPhone || !thirdCompanyPhone) {
                alert("전화번호를 정확히 입력해주세요.");
                return;
            }
        }
        if (secondCompanyPhone) {
            if (!firstCompanyPhone || !thirdCompanyPhone) {
                alert("전화번호를 정확히 입력해주세요.");
                return;
            }
        }
        if (thirdCompanyPhone) {
            if (!secondCompanyPhone || !firstCompanyPhone) {
                alert("전화번호를 정확히 입력해주세요.");
                return;
            }
        }
        if (firstPhone.length === 0 || secondPhone.length === 0 || thirdPhone.length === 0) {
            alert("휴대전화 번호를 입력해주세요.");
            return;
        }
        const isConfirmed = window.confirm("저장하시겠습니까?");
        if (isConfirmed) {
            const patchData = {
                user_id: user.user_id,
                name: name,
                authority_type: authorityType,
                affiliation_code: authorityType === "agency" ? selectedAff2 : authorityType === "dealer" || authorityType === "financial" ? selectedAff3 : "",
                email: email + "@" + emailDomain,
                company_phone_number: firstCompanyPhone + "-" + secondCompanyPhone + "-" + thirdCompanyPhone,
                phone_number: firstPhone + "-" + secondPhone + "-" + thirdPhone,
                address: addressNum + "/" + address + "/" + detailAddress,
                is_active: deletion,
            }
            const modUser = await modifyUser(patchData);
            if (modUser === 200) {
                alert("사용자 수정이 완료되었습니다.");
                setReRender(!reRender);
                onClose();
            } else if (modUser === 401) {
                navigate("/");
            }
        }
    }

    return (
        <div className="modal-wrapper">
            <div className="modal" id="userModal">
                <div className="header">
                    <p>사용자 수정</p>
                    <span className="close" onClick={onClose}>&times;</span>
                </div>
                <div className="content">
                    <div className="modal-inputs">
                        <div className="modal-inputs-category">ID</div>
                        <div><input defaultValue={user.user_id} disabled={true}/></div>
                        <div className="modal-inputs-category">비밀번호</div>
                        <div>
                            <button id="resetPwBtn" className="blackBtn" onClick={openPwModal}>비밀번호 초기화</button>
                            {
                                isPwModalOpen && <Modal_ResetPassword userId={user.user_id} onClose={closePwModal}/>
                            }
                        </div>
                        <div className="modal-inputs-category">사용자명</div>
                        <div><input value={name} onChange={(e) => onChangeHandler(e, setName)}/></div>
                        <div className="modal-inputs-category">사용자그룹</div>
                        <div>
                            <select value={authorityType} onChange={(e) => onChangeHandler(e, setAuthorityType)}>
                                <option value="">선택</option>
                                <option value="agency">대리점</option>
                                <option value="dealer">지점</option>
                                <option value="financial">지점장</option>
                                <option value="admin">본사</option>
                            </select>
                        </div>
                        <div className="modal-inputs-category">삭제여부</div>
                        <div>
                            <select value={deletion} onChange={(e) => onChangeHandler(e, setDeletion)}>
                                <option value={true}>아니오</option>
                                <option value={false}>예</option>
                            </select>
                        </div>
                        <div className="modal-inputs-category">딜러사/전시장</div>
                        {
                            (authorityType === 'dealer' || authorityType === 'financial') &&
                            <div className="modal-inputs-affiliation" id="dealer-affiliation">
                                <select value={selectedAff1} onChange={(e) => onChangeHandler(e, setSelectedAff1)}>
                                    <option value="">선택</option>
                                    {
                                        affList1 && affList1.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select value={selectedAff2} onChange={(e) => onChangeHandler(e, setSelectedAff2)}>
                                    <option value="">선택</option>
                                    {
                                        affList2 && affList2.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select value={selectedAff3} onChange={(e) => onChangeHandler(e, setSelectedAff3)}>
                                    <option value="">선택</option>
                                    {
                                        affList3 && affList3.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                            </div>
                        }
                        {
                            (authorityType !== 'dealer' && authorityType !== 'financial') &&
                            <div></div>
                        }
                        <div className="modal-inputs-category">대리점</div>
                        {
                            authorityType !== 'agency' &&
                            <div></div>
                        }
                        {
                            (authorityType === 'agency') &&
                            <div className="modal-inputs-affiliation" id="agency-affiliation">
                                <select value={selectedAff1} onChange={(e) => onChangeHandler(e, setSelectedAff1)}>
                                    <option value="">선택</option>
                                    {
                                        affList1 && affList1.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select value={selectedAff2} onChange={(e) => onChangeHandler(e, setSelectedAff2)}>
                                    <option value="">선택</option>
                                    {
                                        affList2 && affList2.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                            </div>
                        }
                        {
                            authorityType === 'agency' &&
                            <div className="modal-inputs-category">필름 재고</div>
                        }
                        {
                            authorityType === 'agency' &&
                            <div>
                                <input style={{width: '20%', marginRight: '16px', maxWidth:'140px'}} value={stock}
                                       onChange={(e) => onChangeHandler(e, setStock)}/>
                                <input type="text" placeholder="추가 수량" value={additionalStock}
                                       onChange={(e) => onChangeHandler(e, setAdditionalStock)} style={{width: "20%", maxWidth:'140px'}}/>
                                (m)
                                <button id="addStockBtn" className="blackBtn" onClick={handleAddStock}>재고 추가</button>
                            </div>
                        }
                        <div className="modal-inputs-category">이메일</div>
                        <div>
                            <input value={email} onChange={(e) => onChangeHandler(e, setEmail)}/><span>@</span>
                            <input value={emailDomain} onChange={(e) => onChangeHandler(e, setEmailDomain)}/></div>
                        <div className="modal-inputs-category">전화번호</div>
                        <div>
                            <input value={firstCompanyPhone}
                                   onChange={(e) => onChangeHandler(e, setFirstCompanyPhone)}/><span>-</span>
                            <input value={secondCompanyPhone}
                                   onChange={(e) => onChangeHandler(e, setSecondCompanyPhone)}/><span>-</span>
                            <input value={thirdCompanyPhone}
                                   onChange={(e) => onChangeHandler(e, setThirdCompanyPhone)}/>
                        </div>
                        <div className="modal-inputs-category">휴대폰번호</div>
                        <div>
                            <input value={firstPhone}
                                   onChange={(e) => onChangeHandler(e, setFirstPhone)}/><span>-</span>
                            <input value={secondPhone}
                                   onChange={(e) => onChangeHandler(e, setSecondPhone)}/><span>-</span>
                            <input value={thirdPhone} onChange={(e) => onChangeHandler(e, setThirdPhone)}/></div>
                        <div className="modal-inputs-category">주소</div>
                        <div id="addressInput">
                            <div>
                                <input value={addressNum} onChange={(e) => onChangeHandler(e, setAddressNum)}
                                       placeholder="우편번호"/>
                                <button id="searchAddressBtn" className="blackBtn" onClick={openAddressModal}>주소검색
                                </button>
                            </div>
                            <input value={address} onChange={(e) => onChangeHandler(e, setAddress)} placeholder="주소"/>
                            <input value={detailAddress} onChange={(e) => onChangeHandler(e, setDetailAddress)}
                                   placeholder="상세주소"/>
                        </div>
                    </div>
                    <div className="userModifyController">
                        <button className="grayBtn" onClick={onClose}>취소</button>
                        <button className="blackBtn" onClick={handleModifyUser}>저장</button>
                    </div>
                </div>
                {
                    isSearchModalOpen && <Modal_SearchAddress showModal={isSearchModalOpen} onClose={closeAddressModal}
                                                              setAddressNumber={setAddressNum}
                                                              setAddressText={setAddress}
                                                              setDetailAddress={setDetailAddress}/>
                }
            </div>
        </div>
    )
}