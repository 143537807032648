// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#request-details-modal {
    border: 2px solid black;
}

.modal-details > textarea {
    height: 80px;
}

#request-detail-date input {
    width: 60%;
    margin: 0;
    border: none;
    padding: 5px 16px 5px 10px;
    font-size: 13px;
    text-align: left;
    height: auto;
    box-sizing: content-box;
}

.detailController {
    display: flex;
    width: 100%;
    margin: 48px auto 0 auto;
    align-items: center;
    justify-content: space-evenly;
}

.detailController .blackBtn {
    padding: 8px 0;
    width: 95px;
}

@media screen and (max-width: 900px) {
    .detailController {
        margin: 20px auto;
    }

    #request-detail-date input {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .detailController .blackBtn {
        padding: 8px 0;
        width: 30%;
        font-size: 12px;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Modal_RequestDetails.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,SAAS;IACT,YAAY;IACZ,0BAA0B;IAC1B,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,WAAW;IACX,wBAAwB;IACxB,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,cAAc;IACd,WAAW;AACf;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,cAAc;QACd,UAAU;QACV,eAAe;IACnB;AACJ","sourcesContent":["#request-details-modal {\n    border: 2px solid black;\n}\n\n.modal-details > textarea {\n    height: 80px;\n}\n\n#request-detail-date input {\n    width: 60%;\n    margin: 0;\n    border: none;\n    padding: 5px 16px 5px 10px;\n    font-size: 13px;\n    text-align: left;\n    height: auto;\n    box-sizing: content-box;\n}\n\n.detailController {\n    display: flex;\n    width: 100%;\n    margin: 48px auto 0 auto;\n    align-items: center;\n    justify-content: space-evenly;\n}\n\n.detailController .blackBtn {\n    padding: 8px 0;\n    width: 95px;\n}\n\n@media screen and (max-width: 900px) {\n    .detailController {\n        margin: 20px auto;\n    }\n\n    #request-detail-date input {\n        width: 80%;\n    }\n}\n\n@media screen and (max-width: 600px) {\n    .detailController .blackBtn {\n        padding: 8px 0;\n        width: 30%;\n        font-size: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
