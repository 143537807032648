import "../styles/Modal.css";
import "../styles/Modal_RequestDetails.css";
import printer from "../free-icon-printer-2432596.png"
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import DateInput from "./DateInput";
import * as React from "react";
import {
    allocateAgency,
    getCodeList,
    getConnection,
    getFinancialList,
    modifyRequestDetail,
    modifyRequestStatus,
    getAgencyCodeList
} from "../api/Api";
import Modal_AgencySelect from "./Modal_AgencySelect";
import Modal_WarrantyDetails from "./Modal_WarrantyDetails";
import {useRecoilValue} from "recoil";
import {userInfo} from "./UserInfo";
import ReqPrintingFormat from "./PrintReq";
import {useRef} from "react";
import {useReactToPrint} from 'react-to-print';
import {useNavigate} from "react-router-dom";

export default function Modal_RequestDetails({showModal, onClose, request, role, reRender, setReRender}) {
    const navigate = useNavigate();
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const [customerName, setCustomerName] = useState(request?.customer_name ? request.customer_name : "");
    const [wishedDate, setWishedDate] = useState(dayjs(request?.wished_date * 1000) ? dayjs(request?.wished_date * 1000) : "");
    const [vehicleCompanyList, setVehicleCompanyList] = useState([])
    const [vehicleCompany, setVehicleCompany] = useState(request?.vehicle_code ? request.vehicle_code.substring(0, 5) : "");
    const [vehicleList, setVehicleList] = useState([]);
    const [vehicleType, setVehicleType] = useState(request?.vehicle_code ? request.vehicle_code : "");
    const [contractNumber, setContractNumber] = useState(request?.contract_number ? request.contract_number : "");
    const [vehicleNumber, setVehicleNumber] = useState(request?.vehicle_info_number ? request.vehicle_info_number : "");
    const [productList, setProductList] = useState([]);
    const [tintingFilm, setTintingFilm] = useState(request?.product_code ? request.product_code : "");
    const [densityList, setDensityList] = useState([]);
    const [tintingDensity_front, setTintingDensity_front] = useState(request?.tinting_density.split("/")[0] ? request.tinting_density.split("/")[0] : "");
    const [tintingDensity_side1, setTintingDensity_side1] = useState(request?.tinting_density.split("/")[1] ? request.tinting_density.split("/")[1] : "");
    const [tintingDensity_side2, setTintingDensity_side2] = useState(request?.tinting_density.split("/")[2] ? request.tinting_density.split("/")[2] : "");
    const [tintingDensity_side3, setTintingDensity_side3] = useState(request?.tinting_density.split("/")[3] ? request.tinting_density.split("/")[3] : "");
    const [tintingDensity_back, setTintingDensity_back] = useState(request?.tinting_density.split("/")[4] ? request.tinting_density.split("/")[4] : "");
    const [requestDetail, setRequestDetail] = useState(request?.request_details ? request.request_details : "");
    const [responsibleFc, setResponsibleFc] = useState(request?.responsible_fc_id ? request.responsible_fc_id : "");
    const [fcList, setFcList] = useState([]);
    const [agencyList, setAgencyList] = useState([]);
    const [agency, setAgency] = useState(request?.agency_affiliation_code ? request.agency_affiliation_code : "");
    const dealerCode = request?.dealer_affiliation_code ? request.dealer_affiliation_code : "";
    const [remark, setRemark] = useState(request?.remark ? request.remark : "");
    const [status, setStatus] = useState(request?.process_status ? request.process_status : "");
    const [modifiedStatus, setModifiedStatus] = useState("");
    const [rejectionReason, setRejectionReason] = useState(request?.rejection_reason ? request.rejection_reason : "");
    const [isAgencySelectModalOpen, setIsAgencySelectModalOpen] = useState(false);
    const [isWarrantyModalOpen, setIsWarrantyModalOpen] = useState(false);
    const [warrantySituation, setWarrantySituation] = useState(""); // agency 시공완료 또는 수정 클릭 시 보증서 모달 진입 구분

    const getFc = async () => {
        const fc = await getFinancialList(request.dealer_affiliation_code);
        if (fc) {
            if (fc.status === 200) {
                setFcList(fc.data.fc_list);
            } else if (fc.status === 401) {
                navigate("/");
            }
        }
    }
    const getVehicleList = async () => {
        const vehicleList = await getCodeList(vehicleCompany);
        if (vehicleList) {
            setVehicleList(vehicleList);
        }
    }

    const getVehicleCompanyList = async () => {
        const vehicleCompanyList = await getCodeList('AT');
        if (vehicleCompanyList) {
            setVehicleCompanyList(vehicleCompanyList);
        }

    }
    const getProductList = async () => {
        const productList = await getCodeList('PD');
        if (productList) {
            setProductList(productList);
        }
    }

    const getDensityList = async () => {
        const densityList = await getCodeList(tintingFilm);
        if (densityList) {
            setDensityList(densityList);
        }
    }

    const getAgencyList = async () => {
        if (role === 'dealer') {
            const response = await getConnection(role, dealerCode);
            if (response.status === 200) {
                setAgencyList(response.data.dasa_relation);
            } else setAgencyList([]);
        } else if (role === 'admin'){
            const response = await getAgencyCodeList();
            if (response.status === 200) {
                setAgencyList(response.data.agency_list);
            } else setAgencyList([]);
        }
    }

    useEffect(() => {
        getFc();
        getVehicleList();
        getProductList();
        getDensityList();
        getVehicleCompanyList();
        getAgencyList();
    }, []);

    const handleBackgroundClick = (e) => {
        if (e.target.classList.contains("modal-wrapper")) {
            onClose();
        }
    };
    const onChangeHandler = (e, setState) => {
        const value = e.target.value;
        setState(value);
    };

    const handleModifyRequest = async () => {
        let patchData;
        if (customerName.length === 0) {
            alert("계약자명을 입력해주세요.");
            return;
        }
        if (!wishedDate) {
            alert("시공희망일을 선택해주세요.");
            return;
        }
        if (wishedDate.format("YYYY-MM-DD") === "Invalid Date") {
            alert("시공희망일을 선택해주세요.");
            return;
        }
        if (vehicleType.length === 0) {
            alert("차종을 선택해주세요.");
            return;
        }
        // if (contractNumber.length === 0) {
        //     alert("계약번호를 입력해주세요.");
        //     return;
        // }
        if (vehicleNumber.length === 0) {
            alert("차대번호를 입력해주세요.");
            return;
        }
        if (vehicleNumber.length < 8 || vehicleNumber.length > 8) {
            alert("차대번호 끝 8자리를 입력해주세요.");
            return;
        }
        if (tintingFilm.length === 0) {
            alert("틴팅 필름을 선택해주세요.");
            return;
        }
        if (!tintingDensity_front) {
            alert("전면 틴팅 농도를 선택해주세요.");
            return;
        }
        if (!tintingDensity_side1 || !tintingDensity_side2 || !tintingDensity_back) {
            alert("측후면 틴팅 농도를 선택해주세요.");
            return;
        }
        // if (!agency) {
        //     alert("시공 대리점을 선택해주세요.");
        //     return;
        // }
        if (!responsibleFc) {
            alert("담당 FC를 선택해주세요.");
            return;
        }
        const isConfirmed = window.confirm("시공 요청서를 저장하시겠습니까?");
        if (isConfirmed) {
            if (role === 'admin') {
                patchData = {
                    job_request_id: request.id,
                    customer_name: customerName,
                    vehicle_info_number: vehicleNumber,
                    wished_date: wishedDate.format('YYYY-MM-DD'),
                    vehicle_code: vehicleType,
                    tinting_code: tintingFilm,
                    tinting_density: tintingDensity_front + "/" + tintingDensity_side1 + "/" + tintingDensity_side2 + "/" + tintingDensity_side3 + "/" + tintingDensity_back,
                    remark: remark,
                    process_status: modifiedStatus ? modifiedStatus : "",
                    // agency_affiliation_code: agency,
                }
            } else if (role === 'dealer') {
                patchData = {
                    job_request_id: request.id,
                    responsible_fc_id: responsibleFc,
                    customer_name: customerName,
                    vehicle_info_number: vehicleNumber,
                    wished_date: wishedDate.format('YYYY-MM-DD'),
                    vehicle_code: vehicleType,
                    tinting_code: tintingFilm,
                    tinting_density: tintingDensity_front + "/" + tintingDensity_side1 + "/" + tintingDensity_side2 + "/" + tintingDensity_side3 + "/" + tintingDensity_back,
                    // agency_affiliation_code: agency,
                    request_details: requestDetail,
                }
            }

            const modify_request = await modifyRequestDetail(role, patchData);
            if (modify_request === 200) {
                alert("시공 요청서가 저장되었습니다.");
                setReRender(!reRender);
                onClose();
            } else if (modify_request === 401) {
                navigate("/");
            }
        }
    }

    const handleModifyStatus = async (status) => {
        let modifyData;
        if (status === '반려') {
            if (!rejectionReason) {
                alert("반려 사유를 입력해주세요.");
                return;
            }
        }
        const isConfirmed = window.confirm(`${status}처리 하시겠습니까?`);
        if (isConfirmed) {
            if (status === '반려') {
                modifyData = {
                    job_request_id: request.id,
                    process_status: '반려',
                    rejection_reason: rejectionReason
                }
            } else if (status === '승인') {
                modifyData = {
                    job_request_id: request.id,
                    process_status: '승인',
                    rejection_reason: ""
                }
            } else if (status === '취소') {
                modifyData = {
                    job_request_id: request.id,
                    process_status: '취소',
                    rejectionReason: rejectionReason,
                }
            }

            const mod = await modifyRequestStatus(modifyData);
            if (mod === 200) {
                alert(`${status} 처리되었습니다.`);
                setReRender(!reRender);
                onClose();
            } else if (mod === 401) {
                navigate("/");
            }
        }
    }

    const openAgencySelectModal = () => {
        setIsAgencySelectModalOpen(true);
    };

    const closeAgencySelectModal = () => {
        setIsAgencySelectModalOpen(false);
    };

    const openWarrantyModal = (situation) => {
        setWarrantySituation(situation);
        setIsWarrantyModalOpen(true);
    }

    const closeWarrantyModal = () => {
        setIsWarrantyModalOpen(false);
    }

    if (role === 'admin') {
        return (
            <div className="modal-wrapper">
                <div className="modal" id="request-details-modal">
                    <div className="header">
                        <p>시공요청서 상세</p>
                        <span className="close" onClick={onClose}>&times;</span>
                    </div>
                    <div className="content">
                        <div className="modal-details" id="request-details-admin">
                            <div className="modal-details-category">상태</div>
                            <div>{status}</div>
                            <div className="modal-details-category">딜러사/전시장</div>
                            <div>{request.dealer_affiliation_value.split("/")[1]} / {request.dealer_affiliation_value.split("/")[2]}</div>
                            <div className="modal-details-category">영업직원</div>
                            <div>{request.name}</div>
                            <div className="modal-details-category">영업직원 연락처</div>
                            <div>{request.phone_number}</div>
                            <div className="modal-details-category">계약자명</div>
                            <div><input value={customerName} onChange={(e) => onChangeHandler(e, setCustomerName)}/>
                            </div>
                            <div className="modal-details-category">시공희망일</div>
                            <div id="request-input-date">
                                <DateInput date={wishedDate} setDate={setWishedDate}/>
                            </div>
                            <div className="modal-details-category">차종</div>
                            <div>
                                <select disabled={true} value={vehicleCompany}>
                                    <option value="">선택</option>
                                    {
                                        vehicleCompanyList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select value={vehicleType} onChange={(e) => onChangeHandler(e, setVehicleType)}>
                                    <option value="">선택</option>
                                    {
                                        vehicleList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                            </div>
                            {/*<div className="modal-details-category">계약번호</div>*/}
                            {/*<div><input value={contractNumber} onChange={(e) => onChangeHandler(e, setContractNumber)}*/}
                            {/*            maxLength={10}/>*/}
                            {/*</div>*/}
                            <div className="modal-details-category">차대번호</div>
                            <div>
                                <input value={vehicleNumber} onChange={(e) => onChangeHandler(e, setVehicleNumber)}
                                       maxLength={8}/>
                                <p className="caution">차대번호 끝
                                    8자리</p>
                            </div>
                            <div className="modal-details-category">틴팅필름</div>
                            <div>
                                <select value={tintingFilm} onChange={(e) => onChangeHandler(e, setTintingFilm)}>
                                    <option value="">선택</option>
                                    {
                                        productList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="modal-details-category">틴팅위치</div>
                            <div id="input_tintingPosition">
                                <span>전면</span> <span>측면 1열</span> <span>측면 2열</span> <span>측면 3열</span> <span>후면</span>
                            </div>
                            <div className="modal-details-category">틴팅농도</div>
                            <div id="input_tintingDensity">
                                <select
                                    value={tintingDensity_front}
                                    onChange={(e) => onChangeHandler(e, setTintingDensity_front)}
                                >
                                    <option value="">선택</option>
                                    {
                                        densityList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select
                                    value={tintingDensity_side1}
                                    onChange={(e) => onChangeHandler(e, setTintingDensity_side1)}>
                                    <option value="">선택</option>
                                    {
                                        densityList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select
                                    value={tintingDensity_side2}
                                    onChange={(e) => onChangeHandler(e, setTintingDensity_side2)}>
                                    <option value="">선택</option>
                                    {
                                        densityList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select
                                    value={tintingDensity_side3}
                                    onChange={(e) => onChangeHandler(e, setTintingDensity_side3)}>
                                    <option value="">선택</option>
                                    {
                                        densityList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select
                                    value={tintingDensity_back}
                                    onChange={(e) => onChangeHandler(e, setTintingDensity_back)}>
                                    <option value="">선택</option>
                                    {
                                        densityList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="modal-details-category">요청사항</div>
                            <textarea value={requestDetail} disabled={true}/>
                            <div className="modal-details-category">시공 대리점</div>
                            <div>{request.agency_affiliation_value}</div>
                            {/* <div> */}
                                {/* <select disabled={status !== '시공진행' && status !== '승인' && status !== '시공완료'}
                                        value={agency}
                                        onChange={(e) => onChangeHandler(e, setAgency)}>
                                    <option value="">선택</option>
                                    {
                                        agencyList?.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select> */}
                            {/* </div> */}
                            <div className="modal-details-category">전시장 담당자</div>
                            <div>
                                <select
                                    value={responsibleFc}
                                    onChange={(e) => onChangeHandler(e, setResponsibleFc)}
                                    disabled={true}
                                >
                                    <option value="">선택</option>
                                    {
                                        fcList.map((item, index) => {
                                            return <option value={item.user_id}>{item.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            {request.process_status === "반려" && <div className="modal-details-category">반려사유</div>}
                            {request.process_status === "반려" &&
                                <textarea defaultValue={rejectionReason} disabled={true}/>}
                            <div className="modal-details-category">기타</div>
                            <textarea value={remark} onChange={(e) => onChangeHandler(e, setRemark)}/>
                            <div className="modal-details-category">상태변경</div>
                            <div>
                                <select value={modifiedStatus} onChange={(e) => onChangeHandler(e, setModifiedStatus)}>
                                    <option value="">선택</option>
                                    <option value="취소">취소</option>
                                    <option value="승인대기">승인대기</option>
                                </select>
                            </div>
                        </div>
                        <div className="detailController">
                            <button className="blackBtn" onClick={onClose}>닫기</button>
                            <button className="blackBtn" onClick={handleModifyRequest}>저장</button>
                            {
                                (status === '승인' || status === '시공진행' || status === '시공완료') && (
                                    <button className="blackBtn" onClick={openAgencySelectModal}>배정</button>
                                )
                            }
                            {/*{*/}
                            {/*    (status === '승인') && (*/}
                            {/*        <button className="blackBtn" onClick={()=>handleModifyStatus('시공진행')}>시공 진행</button>*/}
                            {/*    )*/}
                            {/*}*/}
                            {/* <ReactToPrint
                                trigger={() => <button className="printBtn"><img src={printer} alt="프린터"/><p>인쇄</p></button>}
                                content={() => componentRef.current}/>
                            <ReqPrintingFormat ref={componentRef}/> */}
                            {/*<button onClick={handlePrint} className="printBtn"><img src={printer} alt="프린터"/><p>인쇄</p>*/}
                            {/*</button>*/}
                            {/*<div style={{display: "none"}}><ReqPrintingFormat*/}
                            {/*    dealer_affiliation={request.dealer_affiliation_value.split("/")[1]}*/}
                            {/*    dealer_name={request.name}*/}
                            {/*    dealer_contact_number={request.phone_number}*/}
                            {/*    customerName={request.customer_name}*/}
                            {/*    wished_date={wishedDate.format("YYYY-MM-DD")}*/}
                            {/*    vehicleType={request.vehicle_value.split("/")[1]}*/}
                            {/*    contractNumber={contractNumber}*/}
                            {/*    vehicleNumber={vehicleNumber}*/}
                            {/*    agency_affiliation={request.agency_affiliation_value}*/}
                            {/*    tintingFilm={request.product_value}*/}
                            {/*    tintingDensity_front={request.tinting_density_value.split("/")[0]}*/}
                            {/*    tintingDensity_side={request.tinting_density_value.split("/")[1]}*/}
                            {/*    registrationDate={dayjs(request.registration_date * 1000).format("YYYY-MM-DD")}*/}
                            {/*    fc={request.responsible_fc_id}*/}
                            {/*    ref={componentRef}/></div>*/}
                        </div>
                    </div>
                </div>
                {
                    <Modal_AgencySelect showModal={isAgencySelectModalOpen} onClose={closeAgencySelectModal}
                                        items={request} reRender={reRender} setReRender={setReRender}
                                        detailModalClose={onClose}/>
                }
            </div>
        )
    } else if (role === 'dealer') {
        return (
            <div className="modal-wrapper" onClick={handleBackgroundClick}>
                <div className="modal" id="request-details-modal">
                    <div className="header">
                        <p>시공요청서 상세</p>
                        <span className="close" onClick={onClose}>&times;</span>
                    </div>
                    <div className="content">
                        <div className="modal-details" id="request-details-dealer">
                            <div className="modal-details-category">상태</div>
                            <div>{status}</div>
                            <div className="modal-details-category">딜러사/전시장</div>
                            <div>{request.dealer_affiliation_value.split("/")[1]} / {request.dealer_affiliation_value.split("/")[2]}</div>
                            <div className="modal-details-category">영업직원</div>
                            <div>{request.name}</div>
                            <div className="modal-details-category">영업직원 연락처</div>
                            <div>{request.phone_number}</div>
                            <div className="modal-details-category">계약자명</div>
                            <div><input value={customerName} onChange={(e) => onChangeHandler(e, setCustomerName)}
                                        disabled={status !== '승인대기' && status !== '반려'}/></div>
                            <div className="modal-details-category">시공희망일</div>
                            <div id="request-detail-date">
                                {
                                    (status === '승인대기' || status === '반려') &&
                                    <DateInput date={wishedDate} setDate={setWishedDate}/>
                                }
                                {
                                    (status !== '승인대기' && status !== '반려') &&
                                    <div>{wishedDate.format("YYYY-MM-DD")}</div>
                                }
                            </div>
                            <div className="modal-details-category">차종</div>
                            <div>
                                <select disabled={true} value={vehicleCompany}>
                                    <option value="">선택</option>
                                    {
                                        vehicleCompanyList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select value={vehicleType} onChange={(e) => onChangeHandler(e, setVehicleType)}
                                        disabled={status !== '승인대기' && status !== '반려'}>
                                    <option value="">선택</option>
                                    {
                                        vehicleList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                            </div>
                            {/*<div className="modal-details-category">계약번호</div>*/}
                            {/*<div><input value={contractNumber} onChange={(e) => onChangeHandler(e, setContractNumber)}*/}
                            {/*            disabled={status !== '승인대기'} maxLength={10}/>*/}
                            {/*</div>*/}
                            <div className="modal-details-category">차대번호</div>
                            <div>
                                <input value={vehicleNumber} onChange={(e) => onChangeHandler(e, setVehicleNumber)}
                                       disabled={status !== '승인대기' && status !== '반려'} maxLength={8}/>
                                <p className="caution">차대번호 끝
                                    8자리</p>
                            </div>
                            <div className="modal-details-category">틴팅필름</div>
                            <div>
                                <select value={tintingFilm} onChange={(e) => onChangeHandler(e, setTintingFilm)}
                                        disabled={status !== '승인대기' && status !== '반려'}>
                                    <option value="">선택</option>
                                    {
                                        productList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="modal-details-category">틴팅위치</div>
                            <div id="input_tintingPosition">
                                <span>전면</span> <span>측면 1열</span> <span>측면 2열</span> <span>측면 3열</span> <span>후면</span>
                            </div>
                            <div className="modal-details-category">틴팅농도</div>
                            <div id="input_tintingDensity">
                                <select
                                    value={tintingDensity_front}
                                    onChange={(e) => onChangeHandler(e, setTintingDensity_front)}
                                    disabled={status !== '승인대기' && status !== '반려'}
                                >
                                    <option value="">선택</option>
                                    {
                                        densityList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select
                                    value={tintingDensity_side1}
                                    onChange={(e) => onChangeHandler(e, setTintingDensity_side1)}
                                    disabled={status !== '승인대기' && status !== '반려'}
                                >
                                    <option value="">선택</option>
                                    {
                                        densityList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select
                                    value={tintingDensity_side2}
                                    onChange={(e) => onChangeHandler(e, setTintingDensity_side2)}
                                    disabled={status !== '승인대기' && status !== '반려'}
                                >
                                    <option value="">선택</option>
                                    {
                                        densityList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select
                                    value={tintingDensity_side3}
                                    onChange={(e) => onChangeHandler(e, setTintingDensity_side3)}
                                    disabled={status !== '승인대기' && status !== '반려'}
                                >
                                    <option value="">선택</option>
                                    {
                                        densityList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select
                                    value={tintingDensity_back}
                                    onChange={(e) => onChangeHandler(e, setTintingDensity_back)}
                                    disabled={status !== '승인대기' && status !== '반려'}
                                >
                                    <option value="">선택</option>
                                    {
                                        densityList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="modal-details-category">요청사항</div>
                            <textarea value={requestDetail} onChange={(e) => onChangeHandler(e, setRequestDetail)}
                                      disabled={status !== '승인대기' && status !== '반려'}/>
                            {/* <div className="modal-details-category">시공 대리점</div>
                            <div>
                                <select disabled={status !== '승인대기' && status !== '반려'} value={agency}
                                        onChange={(e) => onChangeHandler(e, setAgency)}>
                                    <option value="">선택</option>
                                    {
                                        agencyList?.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                            </div> */}
                            <div className="modal-details-category">담당 FC</div>
                            <div>
                                <select
                                    value={responsibleFc}
                                    onChange={(e) => onChangeHandler(e, setResponsibleFc)}
                                    disabled={status !== '승인대기' && status !== '반려'}
                                >
                                    <option value="">선택</option>
                                    {
                                        fcList.map((item, index) => {
                                            return <option value={item.user_id}>{item.name}</option>
                                        })
                                    }
                                </select>
                                <p className="caution">* 시공 요청서는
                                    계약 발생 해당 월 내에 접수되어야 시공이 가능합니다. *</p>
                            </div>
                            {/*{(status !== '승인대기' && status !== '반려') &&*/}
                            {/*    <div className="modal-details-category">시공대리점</div>}*/}
                            {/*{(status !== '승인대기' && status !== '반려') && <div>{request.agency_affiliation_value}</div>}*/}
                            {status === "반려" && <div className="modal-details-category">반려사유</div>}
                            {status === "반려" &&
                                <textarea defaultValue={rejectionReason} disabled={true}/>}
                        </div>
                        <div className="detailController">
                            <button className="blackBtn" onClick={onClose}>닫기</button>
                            {/*{status !== '승인대기' &&*/}
                            {/*    <>*/}
                            {/*        <button onClick={handlePrint} className="printBtn"><img src={printer} alt="프린터"/>*/}
                            {/*            <p>인쇄</p></button>*/}
                            {/*        <div style={{display: "none"}}><ReqPrintingFormat*/}
                            {/*            dealer_affiliation={request.dealer_affiliation_value.split("/")[1]}*/}
                            {/*            dealer_name={request.name}*/}
                            {/*            dealer_contact_number={request.phone_number}*/}
                            {/*            customerName={request.customer_name}*/}
                            {/*            wished_date={wishedDate.format("YYYY-MM-DD")}*/}
                            {/*            vehicleType={request.vehicle_value.split("/")[1]}*/}
                            {/*            contractNumber={contractNumber}*/}
                            {/*            vehicleNumber={vehicleNumber}*/}
                            {/*            agency_affiliation={request.agency_affiliation_value}*/}
                            {/*            tintingFilm={request.product_value}*/}
                            {/*            tintingDensity_front={request.tinting_density_value.split("/")[0]}*/}
                            {/*            tintingDensity_side={request.tinting_density_value.split("/")[1]}*/}
                            {/*            registrationDate={dayjs(request.registration_date * 1000).format("YYYY-MM-DD")}*/}
                            {/*            fc={request.responsible_fc_id}*/}
                            {/*            ref={componentRef}/></div>*/}
                            {/*    </>}*/}
                            {(status === '승인대기' || status === '반려') &&
                                <button className="blackBtn" onClick={handleModifyRequest}>저장</button>}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (role === 'agency') {
        if (request.process_status === '취소') {
            alert("취소된 시공요청서입니다.");
            onClose();
        } else if (request.process_status === '시공진행' || request.process_status === '시공완료') {
            return (
                <div className="modal-wrapper" onClick={handleBackgroundClick}>
                    <div className="modal" id="request-details-modal">
                        <div className="header">
                            <p>시공요청서 상세</p>
                            <span className="close" onClick={onClose}>&times;</span>
                        </div>
                        <div className="content">
                            <div className="modal-details" id="request-details-agency">
                                <div className="modal-details-category">상태</div>
                                <div>{status}</div>
                                <div className="modal-details-category">딜러사/전시장</div>
                                <div>{request.dealer_affiliation_value.split("/")[1]} / {request.dealer_affiliation_value.split("/")[2]}</div>
                                <div className="modal-details-category">영업직원</div>
                                <div>{request.name}</div>
                                <div className="modal-details-category">영업직원 연락처</div>
                                <div>{request.phone_number}</div>
                                <div className="modal-details-category">계약자명</div>
                                <div>{customerName}</div>
                                <div className="modal-details-category">시공희망일</div>
                                <div id="request-detail-date">{wishedDate.format("YYYY-MM-DD")}</div>
                                <div className="modal-details-category">차종</div>
                                <div>
                                    <select disabled={true} value={vehicleCompany}>
                                        <option value="">선택</option>
                                        {
                                            vehicleCompanyList.map((item, index) => {
                                                return <option value={item['code_key']}>{item['code_value']}</option>
                                            })
                                        }
                                    </select>
                                    <select value={vehicleType} onChange={(e) => onChangeHandler(e, setVehicleType)}
                                            disabled={true}>
                                        <option value="">선택</option>
                                        {
                                            vehicleList.map((item, index) => {
                                                return <option value={item['code_key']}>{item['code_value']}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="modal-details-category">차대번호</div>
                                <div>
                                    <input value={vehicleNumber} disabled={true}/>
                                </div>
                                <div className="modal-details-category">틴팅필름</div>
                                <div>
                                    <select value={tintingFilm} onChange={(e) => onChangeHandler(e, setTintingFilm)}
                                            disabled={true}>
                                        <option value="">선택</option>
                                        {
                                            productList.map((item, index) => {
                                                return <option value={item['code_key']}>{item['code_value']}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="modal-details-category">틴팅위치</div>
                                <div id="input_tintingPosition">
                                    <span>전면</span> <span>측면 1열</span> <span>측면 2열</span> <span>측면 3열</span>
                                    <span>후면</span>
                                </div>
                                <div className="modal-details-category">틴팅농도</div>
                                <div id="input_tintingDensity">
                                    <select
                                        value={tintingDensity_front}
                                        onChange={(e) => onChangeHandler(e, setTintingDensity_front)}
                                        disabled={true}
                                    >
                                        <option value="">선택</option>
                                        {
                                            densityList.map((item, index) => {
                                                return <option value={item['code_key']}>{item['code_value']}</option>
                                            })
                                        }
                                    </select>
                                    <select
                                        value={tintingDensity_side1}
                                        onChange={(e) => onChangeHandler(e, setTintingDensity_side1)}
                                        disabled={true}
                                    >
                                        <option value="">선택</option>
                                        {
                                            densityList.map((item, index) => {
                                                return <option value={item['code_key']}>{item['code_value']}</option>
                                            })
                                        }
                                    </select>
                                    <select
                                        value={tintingDensity_side2}
                                        onChange={(e) => onChangeHandler(e, setTintingDensity_side2)}
                                        disabled={true}
                                    >
                                        <option value="">선택</option>
                                        {
                                            densityList.map((item, index) => {
                                                return <option value={item['code_key']}>{item['code_value']}</option>
                                            })
                                        }
                                    </select>
                                    <select
                                        value={tintingDensity_side3}
                                        onChange={(e) => onChangeHandler(e, setTintingDensity_side3)}
                                        disabled={true}
                                    >
                                        <option value="">선택</option>
                                        {
                                            densityList.map((item, index) => {
                                                return <option value={item['code_key']}>{item['code_value']}</option>
                                            })
                                        }
                                    </select>
                                    <select
                                        value={tintingDensity_back}
                                        onChange={(e) => onChangeHandler(e, setTintingDensity_back)}
                                        disabled={true}
                                    >
                                        <option value="">선택</option>
                                        {
                                            densityList.map((item, index) => {
                                                return <option value={item['code_key']}>{item['code_value']}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="modal-details-category">요청사항</div>
                                <textarea value={request.request_details} disabled={true}/>
                                <div className="modal-details-category">시공 대리점</div>
                                <div>{request.agency_affiliation_value}</div>
                                <div className="modal-details-category">전시장 담당자</div>
                                <div>
                                    <select
                                        value={responsibleFc}
                                        onChange={(e) => onChangeHandler(e, setResponsibleFc)}
                                        disabled={true}
                                    >
                                        <option value="">선택</option>
                                        {
                                            fcList.map((item, index) => {
                                                return <option value={item.user_id}>{item.name}</option>
                                            })
                                        }
                                    </select>
                                    <p className="caution">* 시공 요청서는 계약 발생 해당 월 내에 접수되어야 시공이 가능합니다. (계약발생 2개월 이내에만 정산
                                        가능)*</p>
                                </div>
                                <div className="modal-details-category">기타</div>
                                <textarea value={remark} disabled={true}/>
                            </div>
                            <div className="detailController">
                                <button className="blackBtn" onClick={onClose}>닫기</button>
                                {
                                    request.process_status === "시공진행" &&
                                    <button className="blackBtn"
                                            onClick={() => openWarrantyModal('생성')}>시공완료</button>
                                }
                                {
                                    request.process_status === '시공완료' &&
                                    <button className="blackBtn" onClick={() => openWarrantyModal('수정')}>수정</button>
                                }
                                <button onClick={handlePrint} className="printBtn"><img src={printer} alt="프린터"/>
                                    <p>인쇄</p></button>
                                <div style={{display: "none"}}><ReqPrintingFormat
                                    dealer_affiliation={request.dealer_affiliation_value.split("/")[1]}
                                    dealer_name={request.name}
                                    dealer_contact_number={request.phone_number}
                                    customerName={request.customer_name}
                                    wished_date={wishedDate.format("YYYY-MM-DD")}
                                    vehicleType={request.vehicle_value.split("/")[1]}
                                    contractNumber={contractNumber}
                                    vehicleNumber={vehicleNumber}
                                    agency_affiliation={request.agency_affiliation_value}
                                    tintingFilm={request.product_value}
                                    tintingDensity_front={request.tinting_density_value.split("/")[0]}
                                    tintingDensity_side={request.tinting_density_value.split("/")[1]}
                                    registrationDate={dayjs(request.registration_date * 1000).format("YYYY-MM-DD")}
                                    fc={request.responsible_fc_id}
                                    ref={componentRef}/></div>
                            </div>
                        </div>
                    </div>
                    {
                        isWarrantyModalOpen &&
                        <Modal_WarrantyDetails showModal={isWarrantyModalOpen} onClose={closeWarrantyModal}
                                               item={request} situation={warrantySituation} reRender={reRender}
                                               setReRender={setReRender} closeRequestModal={onClose}/>
                    }
                </div>
            )
        }
    } else if (role === 'financial') {
        return (
            <div className="modal-wrapper" onClick={handleBackgroundClick}>
                <div className="modal" id="request-details-modal">
                    <div className="header">
                        <p>시공요청서 상세</p>
                        <span className="close" onClick={onClose}>&times;</span>
                    </div>
                    <div className="content">
                        <div className="modal-details" id="request-details-financial">
                            <div className="modal-details-category">상태</div>
                            <div>{status}</div>
                            <div className="modal-details-category">딜러사/전시장</div>
                            <div>{request.dealer_affiliation_value.split("/")[1]} / {request.dealer_affiliation_value.split("/")[2]}</div>
                            <div className="modal-details-category">영업직원</div>
                            <div>{request.name}</div>
                            <div className="modal-details-category">영업직원 연락처</div>
                            <div>{request.phone_number}</div>
                            <div className="modal-details-category">계약자명</div>
                            <div>
                                <input value={customerName} disabled={true}/>
                            </div>
                            <div className="modal-details-category">시공희망일</div>
                            <div id="request-detail-date">{wishedDate.format("YYYY-MM-DD")}</div>
                            <div className="modal-details-category">차종</div>
                            <div>
                                <select disabled={true} value={vehicleCompany}>
                                    <option value="">선택</option>
                                    {
                                        vehicleCompanyList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select value={vehicleType} onChange={(e) => onChangeHandler(e, setVehicleType)}
                                        disabled={true}>
                                    <option value="">선택</option>
                                    {
                                        vehicleList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                            </div>
                            {/*<div className="modal-details-category">계약번호</div>*/}
                            {/*<div>*/}
                            {/*    <input value={contractNumber} disabled={true}/>*/}
                            {/*    <p className="caution">계약번호 끝*/}
                            {/*        10자리</p>*/}
                            {/*</div>*/}
                            <div className="modal-details-category">차대번호</div>
                            <div>
                                <input value={vehicleNumber} disabled={true}/>
                                <p className="caution">차대번호 끝
                                    8자리</p>
                            </div>
                            <div className="modal-details-category">틴팅필름</div>
                            <div>
                                <select value={tintingFilm} onChange={(e) => onChangeHandler(e, setTintingFilm)}
                                        disabled={true}>
                                    <option value="">선택</option>
                                    {
                                        productList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="modal-details-category">틴팅위치</div>
                            <div id="input_tintingPosition">
                                <span>전면</span> <span>측면 1열</span> <span>측면 2열</span> <span>측면 3열</span> <span>후면</span>
                            </div>
                            <div className="modal-details-category">틴팅농도</div>
                            <div id="input_tintingDensity">
                                <select
                                    value={tintingDensity_front}
                                    onChange={(e) => onChangeHandler(e, setTintingDensity_front)}
                                    disabled={true}
                                >
                                    <option value="">선택</option>
                                    {
                                        densityList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select
                                    value={tintingDensity_side1}
                                    onChange={(e) => onChangeHandler(e, setTintingDensity_side1)}
                                    disabled={true}
                                >
                                    <option value="">선택</option>
                                    {
                                        densityList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select
                                    value={tintingDensity_side2}
                                    onChange={(e) => onChangeHandler(e, setTintingDensity_side2)}
                                    disabled={true}
                                >
                                    <option value="">선택</option>
                                    {
                                        densityList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select
                                    value={tintingDensity_side3}
                                    onChange={(e) => onChangeHandler(e, setTintingDensity_side3)}
                                    disabled={true}
                                >
                                    <option value="">선택</option>
                                    {
                                        densityList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                                <select
                                    value={tintingDensity_back}
                                    onChange={(e) => onChangeHandler(e, setTintingDensity_back)}
                                    disabled={true}
                                >
                                    <option value="">선택</option>
                                    {
                                        densityList.map((item, index) => {
                                            return <option value={item['code_key']}>{item['code_value']}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="modal-details-category">요청사항</div>
                            <textarea value={request.request_details} disabled={true}/>
                            <div className="modal-details-category">시공 대리점</div>
                            <div>{request.agency_affiliation_value}</div>
                            <div className="modal-details-category">전시장 담당자</div>
                            <div>
                                <select
                                    value={responsibleFc}
                                    onChange={(e) => onChangeHandler(e, setResponsibleFc)}
                                    disabled={true}
                                >
                                    <option value="">선택</option>
                                    {
                                        fcList.map((item, index) => {
                                            return <option value={item.user_id}>{item.name}</option>
                                        })
                                    }
                                </select>
                                <p className="caution">* 시공 요청서는 계약 발생 해당 월 내에 접수되어야 시공이 가능합니다. *</p>
                            </div>
                            <div className="modal-details-category">반려사유</div>
                            {status === "승인대기" && <textarea value={rejectionReason}
                                                            onChange={(e) => onChangeHandler(e, setRejectionReason)}/>}
                            {status !== "승인대기" &&
                                <textarea defaultValue={rejectionReason} disabled={true}/>}
                        </div>
                        <div className="detailController">
                            <button className="blackBtn" onClick={onClose}>닫기</button>
                            {status === "승인대기" &&
                                <button className="blackBtn" onClick={() => handleModifyStatus("반려")}>반려</button>}
                            {status === "승인대기" &&
                                <button className="blackBtn" onClick={() => handleModifyStatus("승인")}>승인</button>}
                            {status === '승인' &&
                                <button className="blackBtn" onClick={() => handleModifyStatus("취소")}>승인취소</button>}
                            {/*<button onClick={handlePrint} className="printBtn"><img src={printer} alt="프린터"/><p>인쇄</p>*/}
                            {/*</button>*/}
                            {/*<div style={{display: "none"}}><ReqPrintingFormat*/}
                            {/*    dealer_affiliation={request.dealer_affiliation_value.split("/")[1]}*/}
                            {/*    dealer_name={request.name}*/}
                            {/*    dealer_contact_number={request.phone_number}*/}
                            {/*    customerName={customerName}*/}
                            {/*    wished_date={wishedDate.format("YYYY-MM-DD")}*/}
                            {/*    vehicleType={request.vehicle_value.split("/")[1]}*/}
                            {/*    contractNumber={contractNumber}*/}
                            {/*    vehicleNumber={vehicleNumber}*/}
                            {/*    agency_affiliation={request.agency_affiliation_value}*/}
                            {/*    tintingFilm={request.product_value}*/}
                            {/*    tintingDensity_front={request.tinting_density_value.split("/")[0]}*/}
                            {/*    tintingDensity_side={request.tinting_density_value.split("/")[1]}*/}
                            {/*    registrationDate={dayjs(request.registration_date * 1000).format("YYYY-MM-DD")}*/}
                            {/*    fc={request.responsible_fc_id}*/}
                            {/*    ref={componentRef}/></div>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}