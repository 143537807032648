import "../styles/RequestList.css";
import {useEffect, useState} from "react";
import Modal_RequestDetails, {handleModifyStatus} from "./Modal_RequestDetails";
import Paging from "./Paging";
import Modal_AgencySelect from "./Modal_AgencySelect";
import {allocateAgency, getAllRequest, getRequestList, modifyRequestStatus} from "../api/Api";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";

export default function RequestList({role, searchCondition, reRender, setReRender, currentPage, setCurrentPage}) {
    const navigate = useNavigate();
    const [isAgencySelectModalOpen, setIsAgencySelectModalOpen] = useState(false);
    const [isRequestDetailModalOpen, setIsRequestDetailModalOpen] = useState(false);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [additionalInfoPosition, setAdditionalInfoPosition] = useState({x: 0, y: 0})
    const [selectedItem, setSelectedItem] = useState(null); // 클릭한 요청서 데이터
    const [requestData, setRequestData] = useState([]);
    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [lastPageNumber, setLastPageNumber] = useState(1);
    const adminTableHeaders = [
        {text: '번호', value: 'index'},
        {text: '전시장', value: 'dealer_affiliation_value'},
        {text: '대리점', value: 'agency_affiliation_value'},
        {text: '영업직원', value: 'name'},
        {text: '고객명', value: 'customer_name'},
        {text: '차대번호', value: 'vehicle_info_number'},
        {text: '등록일', value: 'registration_date'},
        {text: '상태', value: 'process_status'}
    ] // 관리자 요청서 테이블 헤더
    const dealerTableHeaders = [
        {text: '번호', value: 'index'},
        {text: '고객명', value: 'customer_name'},
        {text: '차종', value: 'vehicle_value'},
        {text: '시공희망일', value: 'wished_date'},
        {text: '등록일', value: 'registration_date'},
        {text: '상태', value: 'process_status'},
        {text: '처리', value: 'request_execution'}
    ] // 딜러 요청서 테이블 헤더
    const agencyTableHeaders = [
        {text: '번호', value: 'index'},
        {text: '전시장', value: 'dealer_affiliation_value'},
        {text: '차대번호', value: 'vehicle_info_number'},
        {text: '영업직원', value: 'name'},
        {text: '고객명', value: 'customer_name'},
        {text: '시공희망일', value: 'wished_date'},
        {text: '등록일', value: 'registration_date'},
        {text: '상태', value: 'process_status'},
        {text: '처리', value: 'request_execution'}
    ] // 대리점 요청서 테이블 헤더
    const financialTableHeaders = [
        {text: '번호', value: 'index'},
        {text: '고객명', value: 'customer_name'},
        {text: '차종', value: 'vehicle_value'},
        {text: '시공희망일', value: 'wished_date'},
        {text: '등록일', value: 'registration_date'},
        {text: '상태', value: 'process_status'},
    ]
    const adminHeaderKey = adminTableHeaders.map((header) => header.value);
    const dealerHeaderKey = dealerTableHeaders.map((header) => header.value);
    const agencyHeaderKey = agencyTableHeaders.map((header) => header.value);
    const financialHeaderKey = financialTableHeaders.map((header) => header.value);

    const getRequests = async (searchCondition = {}, currentPageNumber = 1) => {
        let request;
        if (role !== 'admin') {
            request = await getRequestList(searchCondition, currentPageNumber);
        } else {
            request = await getAllRequest(searchCondition, currentPageNumber);
        }
        console.log(request);
        if (request?.status === 200) {
            setRequestData(request.data.job_requests);
            setLastPageNumber(request.data.total_page);
        } else if (request?.status === 401) {
            navigate("/");
        }
    }
    useEffect(() => {
        setSelection(new Set());
        getRequests(searchCondition, currentPage);
    }, [currentPage, searchCondition, reRender]);

    // 테이블 행 선택 관련
    const itemKey = 'id';
    const [selection, setSelection] = useState(new Set());
    const onChangeSelect = (value) => {
        // 기존의 selection으로 새로운 Set 생성
        const newSelection = new Set(selection);
        if (newSelection.has(value)) {
            // value가 있으면 삭제 (checked가 false이기 때문)
            newSelection.delete(value);
        } else {
            // value가 없으면 추가 (checked가 true이기 때문)
            newSelection.add(value);
        }
        // 새로운 Set으로 state 변경
        setSelection(newSelection);
    };

    // disabled가 true인 item만 반환하는 함수
    const getAbledItems = (items) => {
        return items.filter((item) => item.process_status === "승인");
    };
    const onChangeSelectAll = (e) => {
        if (e.target.checked) {
            // checked가 true인 경우 전체 선택
            const allCheckedSelection = new Set(
                // 활성화된 행의 배열을 순회하며 itemKey로 요소에 접근해 데이터를 저장
                getAbledItems(requestData).map((item) => item)
            );
            setSelection(allCheckedSelection);
        } else {
            // checked가 false인 경우 전체 선택 해제
            setSelection(new Set());
        }
    };
    // 전체 선택 상태 여부
    const isSelectedAll = () => {
        if (getAbledItems(requestData).length === 0) {
            return false;
        }
        return selection.size === getAbledItems(requestData).length;
    };

    // 요청서 호버링
    const handleMouseEnter = (item, e) => {
        if (item.request_details || item.remark) {
            setHoveredItem(item);
            const rect = e.target.getBoundingClientRect();
            setAdditionalInfoPosition({x: window.innerWidth / 2, y: rect.bottom + window.scrollY});
        }
    };
    const handleMouseLeave = () => {
        setHoveredItem(null);
    };
    const renderAdditionalInfo = (item) => {
        return (
            <div className="additional-info">
                <p style={{margin: 0, fontSize: '14px'}}>{item.remark + "\n" + item.request_details}</p>
            </div>
        );
    };

    // 페이지 변경 핸들러
    const handlePageChange = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
    };
    const openRequestDetailModal = (item) => {
        setSelectedItem(item);
        setIsRequestDetailModalOpen(true);
    };

    const closeRequestDetailModal = () => {
        setIsRequestDetailModalOpen(false);
    };

    const openAgencySelectModal = () => {
        if (selection.size > 0) {
            setIsAgencySelectModalOpen(true);
        } else {
            setIsAgencySelectModalOpen(false);
            window.alert("선택배정할 게시물을 하나 이상 선택하세요.");
        }
    };

    const closeAgencySelectModal = () => {
        setIsAgencySelectModalOpen(false);
    };

    const handleReApproval = async (request) => {
        const isConfirmed = window.confirm(`승인 요청 하시겠습니까?`);
        if (isConfirmed) {
            const modifyData = {
                job_request_id: request.id,
                process_status: '승인대기',
                rejectionReason: request.rejection_reason,
            }
            const mod = await modifyRequestStatus(modifyData);
            if (mod === 200) {
                alert(`승인 요청하였습니다.`);
                setReRender(!reRender);
            } else if (mod === 401) {
                navigate("/");
            }
        }
    }

    const handleConstructionProgress = async () => {
        if(selection.size === 0){
            alert("선택된 요청서가 없습니다.");
            return;
        }
        const isConfirmed = window.confirm("선택된 요청서를 일괄 시공 진행 처리하시겠습니까?");
        if (isConfirmed) {
            if (selection instanceof Set) {
                let isAllOk = true;
                for (const item of selection) {
                    const patchData = {
                        job_request_id: item.id,
                        agency_affiliation_code: item.agency_affiliation_code,
                    };
                    const result = await allocateAgency(patchData);
                    if (result === 401) {
                        alert("세션이 만료되었습니다. 다시 로그인해주세요.");
                        navigate("/");
                        isAllOk = false;
                        break;
                    } else if (result === 410) {
                        alert("권한이 유효하지 않습니다.");
                        isAllOk = false;
                        break;
                    } else if (result === 430) {
                    } else if (result !== 200) {
                        alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
                        isAllOk = false;
                        break;
                    }
                }
                if (isAllOk) {
                    alert("시공 진행 처리되었습니다.");
                    setReRender(!reRender);
                }
            }
        }
    }
    if (role === 'admin') {
        return (
            <div>
                {requestData.length > 0 ? (
                        <div>
                            <table className="list_table">
                                <thead>
                                <tr className="table-header">
                                    {
                                        <th>
                                            <input type="checkbox" checked={isSelectedAll()}
                                                   onChange={onChangeSelectAll}/>
                                        </th>
                                    }
                                    {
                                        adminTableHeaders.map((header) =>
                                            <th key={header.text}>
                                                {header.text}
                                            </th>
                                        )
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    requestData.map((item, index) => (
                                        <tr key={index} className="table-body">
                                            {
                                                <td>
                                                    <input type="checkbox"
                                                           disabled={!(item.process_status === '승인')}
                                                           checked={selection.has(item) && (item.process_status === '승인' || item.process_status === '시공진행' || item.process_status === '시공완료')}
                                                           onChange={() => onChangeSelect(item)}/>
                                                </td>
                                            }
                                            {
                                                adminHeaderKey.map((key) =>
                                                    <td key={key + index} onClick={() => openRequestDetailModal(item)}
                                                        onMouseEnter={(e) => handleMouseEnter(item, e)}
                                                        onMouseLeave={handleMouseLeave}
                                                        style={{cursor: "pointer"}}>
                                                        {key === 'index' ? indexOfFirstItem + index + 1 : key === 'dealer_affiliation_value' ? item[key].split("/")[2] : key === 'agency_affiliation_value' ? item[key].split("/")[1] : key === 'registration_date' ? dayjs(item[key] * 1000).format("YYYY-MM-DD") : item[key]}
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    ))
                                }
                                </tbody>
                                {hoveredItem && (
                                    <div className="hovered-item-info" style={{
                                        background: "rgba(0,0,0,0.85)",
                                        color: "white",
                                        position: "absolute",
                                        fontSize: '12px',
                                        top: additionalInfoPosition.y,
                                        left: '50%',
                                        padding: '8px 32px',
                                        borderRadius: '6px',
                                        transform: 'translate(-50%, 6%)',
                                    }}>
                                        {renderAdditionalInfo(hoveredItem)}
                                    </div>
                                )}
                            </table>
                            {/* <button className="allocBtn" onClick={handleConstructionProgress}>선택 시공 진행</button> */}
                            <button className="allocBtn" onClick={openAgencySelectModal}>선택 배정</button>
                            <Paging currentPage={currentPage}
                                    lastPage={lastPageNumber} handlePageChange={handlePageChange}/>
                        </div>) :
                    (<div className="no_data_exist">
                        <h3>시공 요청서가 존재하지 않습니다.</h3>
                    </div>)
                }
                {
                    isRequestDetailModalOpen && (
                        <Modal_RequestDetails showModal={isRequestDetailModalOpen} onClose={closeRequestDetailModal}
                                              request={selectedItem} role='admin' reRender={reRender}
                                              setReRender={setReRender}/>
                    )
                }
                {
                    isAgencySelectModalOpen && (
                        <Modal_AgencySelect showModal={isAgencySelectModalOpen} onClose={closeAgencySelectModal}
                                            items={selection} reRender={reRender} setReRender={setReRender}/>
                    )
                }
            </div>)
    } else if (role === 'dealer') {
        return (
            <div>
                {requestData.length > 0 ? (
                        <div>
                            <table className="list_table">
                                <thead>
                                <tr className="table-header">
                                    {
                                        dealerTableHeaders.map((header) =>
                                            <th key={header.text}>
                                                {header.text}
                                            </th>
                                        )
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    requestData.map((item, index) => (
                                        <tr key={index} className="table-body" onClick={() => openRequestDetailModal(item)}
                                            onMouseEnter={(e) => handleMouseEnter(item, e)} onMouseLeave={handleMouseLeave}
                                            style={{cursor: "pointer"}}>
                                            {
                                                dealerHeaderKey.map((key) =>
                                                    <td key={key + index}>
                                                        {(key === 'request_execution' && item['process_status'] === '반려') ? (
                                                                <button className="viewGuaranteeBtn"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleReApproval(item);
                                                                        }}>
                                                                    승인요청
                                                                </button>) :
                                                            key === 'index' ? indexOfFirstItem + index + 1 : key === 'vehicle_value' ? item[key].split("/")[1] : key === 'wished_date' || key === 'registration_date' ? dayjs(item[key] * 1000).format("YYYY-MM-DD") : item[key]}
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    ))
                                }
                                </tbody>
                                {
                                    hoveredItem && (
                                        <div className="hovered-item-info" style={{
                                            background: "rgba(0,0,0,0.85)",
                                            color: "white",
                                            position: "absolute",
                                            fontSize: '12px',
                                            top: additionalInfoPosition.y,
                                            left: '50%',
                                            padding: '8px 32px',
                                            borderRadius: '6px',
                                            transform: 'translate(-50%, 6%)',
                                        }}>
                                            {renderAdditionalInfo(hoveredItem)}
                                        </div>
                                    )
                                }
                            </table>
                            <Paging currentPage={currentPage} lastPage={lastPageNumber}
                                    handlePageChange={handlePageChange}/>
                        </div>) :
                    (<div className="no_data_exist">
                        <h3>시공 요청서가 존재하지 않습니다.</h3>
                    </div>)
                }
                {
                    isRequestDetailModalOpen && (
                        <Modal_RequestDetails showModal={isRequestDetailModalOpen} onClose={closeRequestDetailModal}
                                              request={selectedItem} role='dealer' reRender={reRender}
                                              setReRender={setReRender}/>
                    )
                }
            </div>
        )
    } else if (role === 'agency') {
        return (
            <div>
                {requestData.length > 0 ? (
                        <div>
                            <table className="list_table">
                                <thead>
                                <tr className="table-header">
                                    {
                                        agencyTableHeaders.map((header) =>
                                            <th key={header.text}>
                                                {header.text}
                                            </th>
                                        )
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    requestData.map((item, index) => (
                                        <tr key={index} className="table-body" onClick={() => openRequestDetailModal(item)}
                                            onMouseEnter={(e) => handleMouseEnter(item, e)} onMouseLeave={handleMouseLeave}
                                            style={{cursor: "pointer"}}>
                                            {
                                                agencyHeaderKey.map((key) =>
                                                    <td key={key + index}>
                                                        {key === 'index' ? indexOfFirstItem + index + 1 : key === 'dealer_affiliation_value' ? item[key].split("/")[2] : key === 'wished_date' || key === 'registration_date' ? dayjs(item[key] * 1000).format("YYYY-MM-DD") : item[key]}
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    ))
                                }
                                </tbody>
                                {
                                    hoveredItem && (
                                        <div className="hovered-item-info" style={{
                                            background: "rgba(0,0,0,0.85)",
                                            color: "white",
                                            position: "absolute",
                                            top: additionalInfoPosition.y,
                                            left: '50%',
                                            padding: '8px 32px',
                                            borderRadius: '6px',
                                            transform: 'translate(-50%, 6%)',
                                        }}>
                                            {renderAdditionalInfo(hoveredItem)}
                                        </div>
                                    )
                                }
                            </table>
                            <Paging currentPage={currentPage}
                                    lastPage={lastPageNumber} handlePageChange={handlePageChange}/>
                        </div>) :
                    (<div className="no_data_exist">
                        <h3>시공 요청서가 존재하지 않습니다.</h3>
                    </div>)
                }
                {
                    isRequestDetailModalOpen && (
                        <Modal_RequestDetails showModal={isRequestDetailModalOpen} onClose={closeRequestDetailModal}
                                              request={selectedItem} role='agency' reRender={reRender}
                                              setReRender={setReRender}/>
                    )
                }
            </div>
        )
    } else if (role === 'financial') {
        return (
            <div>
                {requestData.length > 0 ? (
                        <div>
                            <table className="list_table">
                                <thead>
                                <tr className="table-header">
                                    {
                                        financialTableHeaders.map((header) =>
                                            <th key={header.text}>
                                                {header.text}
                                            </th>
                                        )
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    requestData.map((item, index) => (
                                        <tr key={index} className="table-body" onClick={() => openRequestDetailModal(item)}
                                            onMouseEnter={(e) => handleMouseEnter(item, e)} onMouseLeave={handleMouseLeave}
                                            style={{cursor: "pointer"}}>
                                            {
                                                financialHeaderKey.map((key) =>
                                                    <td key={key + index}>
                                                        {key === 'index' ? indexOfFirstItem + index + 1 : key === 'vehicle_value' ? item[key].split("/")[1] : key === 'wished_date' || key === 'registration_date' ? dayjs(item[key] * 1000).format("YYYY-MM-DD") : item[key]}
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    ))
                                }
                                </tbody>
                                {
                                    hoveredItem && (
                                        <div className="hovered-item-info" style={{
                                            background: "rgba(0,0,0,0.85)",
                                            color: "white",
                                            position: "absolute",
                                            fontSize: '12px',
                                            top: additionalInfoPosition.y,
                                            left: '50%',
                                            padding: '8px 32px',
                                            borderRadius: '6px',
                                            transform: 'translate(-50%, 6%)',
                                        }}>
                                            {renderAdditionalInfo(hoveredItem)}
                                        </div>
                                    )
                                }
                            </table>
                            <Paging currentPage={currentPage}
                                    lastPage={lastPageNumber} handlePageChange={handlePageChange}/>
                        </div>) :
                    (<div className="no_data_exist">
                        <h3>시공 요청서가 존재하지 않습니다.</h3>
                    </div>)
                }
                {
                    isRequestDetailModalOpen && (
                        <Modal_RequestDetails showModal={isRequestDetailModalOpen} onClose={closeRequestDetailModal}
                                              request={selectedItem} role='financial' reRender={reRender}
                                              setReRender={setReRender}/>
                    )
                }
            </div>
        )
    }
}