import React from 'react';
import { forwardRef } from 'react';
import smithLogo from "../smith_logo.jpg";
import lrFinancial from "../lr_financial.jpg";


const ReqPrintingFormat = forwardRef((props, ref) => {
return (
<html lang="en" ref={ref}>
<head>
  <meta charset="UTF-8"/>
  <meta name="Generator" content="EditPlus®"/>
  <meta name="Author" content=""/>
  <meta name="Keywords" content=""/>
  <meta name="Description" content=""/>
  <title>Document</title>
  <style type="text/css">
    {`input[type="text"] {box-sizing:border-box; width:100%; border:0; height:100%; text-align:center;}
    .form_wrap {width:760px; border:0px solid #dcdcdc;}
    .form_wrap > .inner {padding:30px;}  
    .form_wrap h1 {position:relative; margin:0;}
    .form_wrap h1 span {position:absolute; right:0; top:50%; margin-top:-25px;}
    .form_wrap table {width:100%;border-collapse:collapse;border-spacing:0;margin:0;padding:0;table-layout:fixed;}
    .form_wrap table caption {font-size:16px; font-weight:bold; margin-bottom:10px; text-align:left;}
    .form_wrap table th,.form_wrap table td {
        font-size:14px; border:1px solid #323232; height:36px; vertical-align:middle; padding:0 5px; font-weight:bold;}
    .form_wrap table th {padding:0}
    .form_wrap table td small {font-size:12px; color:#646464;}
    .form_wrap table td span {font-weight:bold;}
    .form_wrap table th small {font-size:10px; display:block;}
    .form_wrap table td input[type="text"] {
        max-width:100%; text-align:center;
        border:0; outline:0; box-sizing:border-box;
    }
    .form_wrap table td input[type="radio"]{
        vertical-align:middle;
    }
    .form_wrap table td > table th,
    .form_wrap table td > table td {padding:0;}
    .form_wrap table td.work_date td {border:0;}
    .form_wrap table td.car_num td,
        .form_wrap table td.car_application td {border:0; border-left:1px solid #323232;}
    .form_wrap table td.car_num td:first-child,
        .form_wrap table td.car_application td:first-child {border:0;}

    .form_wrap .info div {margin-top:5px; margin-left:30px; font-size:13px;}
    .form_wrap .info strong {margin-top:8px; display:block; text-align:center;}
    .form_wrap .info .small {font-size:11px; margin-left:30px;}
    .form_wrap .sign_wrap {position: relative;text-align:right;}
    .form_wrap .sign_wrap p {font-weight:bold;}
    .form_wrap .sign_wrap p span {margin-left:10px;}
    .form_wrap .sign_wrap .logo { position: absolute; left: 0; bottom: 5px; }
    .form_wrap .company {margin-top:50px;text-align: right;}
    .form_wrap .company dt {float:left;}
    .form_wrap .company > dl > dd {margin-left:230px;margin-top:30px;}
    .form_wrap .company ul li {font-size:12px;}
    .form_wrap .company ul li dd {margin-left:150px;}
    .form_wrap .company > dl > dd > ul {list-style:none;}

    .form_wrap .sign_wrap .date input[type="text"] {width:50px; text-align:center;
    box-sizing:border-box; border:0;}
    .form_wrap .sign_wrap .sign:after {content:''; display:block; clear:both;}
    .form_wrap .sign_wrap .sign dl {float:right;}
    .form_wrap .sign_wrap .sign dl dt,
    .form_wrap .sign_wrap .sign dl dd {float:left; font-weight:bold; font-size:16px;}
    .form_wrap .sign_wrap .sign dl dd input[type="text"] {width:80px;
    box-sizing:border-box; border:0; text-align:center;}
        
        .form_wrap .reg_date:after {content:''; display:block; clear:both;}
        .form_wrap .reg_date table {width:40%; float:right;}
        .form_wrap .reg_date table th {border:0; font-size:12px; text-align:right;}               
        .form_wrap .reg_date table td {font-size:14px; height:30px; border:1px dashed;}`}
  </style>
 </head>
 <body>
  <div class="form_wrap">
    <div class="inner">
        <h1><img src={lrFinancial}/></h1>
        <div class="form">                      
            <table>
                <caption>틴팅 프로모션 시공요청서</caption>
                <tbody>
                    <tr>
                        {/* <th colspan="3" style="background-color:#ddd;">딜러사/전시장명</th> */}
                        <th colSpan="3" style={{backgroundColor: "#ddd"}}>딜러사/전시장명</th>
                        <td colspan="9"><input type="text" name="" value={props.dealer_affiliation} /></td>
                    </tr>
                    <tr>
                        {/* <th colspan="3" style="background-color:#ddd;">영업직원명</th> */}
                        <th colSpan="3" style={{backgroundColor: "#ddd"}}>영업직원명</th>
                        <td colspan="3"><input type="text" name="" value={props.dealer_name} /></td>
                        {/* <th colspan="3" style="background-color:#ddd;">연락처</th> */}
                        <th colSpan="3" style={{backgroundColor: "#ddd"}}>연락처</th>
                        <td colspan="3"><input type="text" name="" value={props.dealer_contact_number} /></td>
                    </tr>
                    <tr>
                        <th colspan="3">고 객 명</th>
                        <td colspan="9"><input type="text" name="" value={props.customerName} /></td>                  
                    </tr>
                    <tr>
                        <th colspan="3">시공일 희망일</th>
                        <td colspan="9" class="work_date">
                            <table>
                                <tr>
                                    {/* <td width="55px"><input type="text" name="" value="2024" style="width:40px"/>년</td> */}
                                    <td style={{width: "55px"}}><input type="text" name="" value={props.wished_date.split("-")[0]} style={{width: "40px"}} />년</td>
                                    {/* <td width="45px"><input type="text" name="" value="03" style="width:20px"/>월</td> */}
                                    <td style={{width: "45px"}}><input type="text" name="" value={props.wished_date.split("-")[1]} style={{width: "20px"}} />월</td>
                                    {/* <td width="45px"><input type="text" name="" value="02" style="width:20px"/>일</td> */}
                                    <td style={{width: "45px"}}><input type="text" name="" value={props.wished_date.split("-")[2]} style={{width: "20px"}} />일</td>
                                    <td width="300px"></td>
                                </tr>
                            </table>
                        </td>                               
                    </tr>
                    <tr>
                        <th colspan="3">계약번호</th>
                        <td colspan="9" class="car_application">
                            <table>
                                <tr>
                                    <td><input type="text" name="" value={props.contractNumber[0]}/></td>
                                    <td><input type="text" name="" value={props.contractNumber[1]}/></td>
                                    <td><input type="text" name="" value={props.contractNumber[2]}/></td>
                                    <td><input type="text" name="" value={props.contractNumber[3]}/></td>
                                    <td><input type="text" name="" value={props.contractNumber[4]}/></td>
                                    <td><input type="text" name="" value={props.contractNumber[5]}/></td>
                                    <td><input type="text" name="" value={props.contractNumber[6]}/></td>
                                    <td><input type="text" name="" value={props.contractNumber[7]}/></td> 
									<td><input type="text" name="" value={props.contractNumber[8]}/></td> 
									<td><input type="text" name="" value={props.contractNumber[9]}/></td> 
                                </tr>
                            </table>
                        </td>                       
                    </tr>
                    <tr>
                        <th colspan="3">차 대 번 호</th>
                        <td colspan="9" class="car_num">
                            <table>
                                <tr>
                                    <td><input type="text" name="" value={props.vehicleNumber[0]}/></td>
                                    <td><input type="text" name="" value={props.vehicleNumber[1]}/></td>
                                    <td><input type="text" name="" value={props.vehicleNumber[2]}/></td>
                                    <td><input type="text" name="" value={props.vehicleNumber[3]}/></td>
                                    <td><input type="text" name="" value={props.vehicleNumber[4]}/></td>
                                    <td><input type="text" name="" value={props.vehicleNumber[5]}/></td>
                                    <td><input type="text" name="" value={props.vehicleNumber[6]}/></td>
									<td><input type="text" name="" value={props.vehicleNumber[7]}/></td>
                                </tr>
                            </table>                        
                        </td>                       
                    </tr>
                    <tr>
                        <th colspan="3">차&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;종</th>
                        <td colspan="9"><input type="text" name="" value={props.vehicleType}/></td>
                    </tr>
                    <tr>
                        <th colspan="3">시공 대리점</th>
                        <td colspan="9"><input type="text" name="" value={props.agency_affiliation}/></td>
                    </tr>
                    <tr>
                        <th colspan="3" rowspan="2">틴 팅 필 름</th>
                        <th colspan="3" rowspan="2">
                            <input type="text" name="" value={props.tintingFilm}/>
                        </th>
                        <th colspan="6">
                            <input type="text" name="" value={props.tintingDensity_front+" %"}/>
                        </th>
                    </tr>
                    <tr>
                        <th colspan="6">
                            <input type="text" name="" value={props.tintingDensity_side+" %"}/>
                        </th>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="info">
            <strong>시공요청서는 계약발생 해당월 내에 접수되어야 시공이 가능합니다.</strong>
        </div>
        <div class="sign_wrap">
            <p class="date">
                <span><input type="text" name="" value={props.registrationDate.split("-")[0]}/>년</span>
                <span><input type="text" name="" value={props.registrationDate.split("-")[1]}/>월</span>
                <span><input type="text" name="" value={props.registrationDate.split("-")[2]}/>일</span>
            </p>
            <div class="sign">
                <dl>
                    <dt>신청 S C</dt>
                    <dd><input type="text" name="" value={props.dealer_name}/></dd>
                    <dd>(서명)</dd>
                </dl>
            </div>
            <div class="sign">
                <dl>
                    <dt>담당 FC</dt>
                    <dd><input type="text" name="" value={props.fc}/></dd>
                    <dd>(서명)</dd>
                </dl>
            </div>
			<div class="logo">
				<img src={smithLogo} />
			</div>
        </div>
        {/* <div class="company" style="margin-left:60px"> */}
        <div className="company" style={{marginLeft: "60px"}}>
            <dl>
                <dt></dt>
                <dd>
                    <ul>
                        <li>
                            <dl>
                                <dt>스미스클럽 대표전화</dt>
                                <dd>031-903-2121 (내선번호 5번)</dd>
                            </dl>
                        </li>
                    </ul>
                </dd>
            </dl>
        </div>
        {/* <div style="margin-top:50px"></div> */}
        <div style={{marginTop: "50px"}}></div>
    </div>
  </div>
 </body>
</html>)});

export default ReqPrintingFormat;