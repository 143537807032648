// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#request-input-date input {
    box-sizing: content-box;
    width: 60%;
    margin: 0;
    border: none;
    padding: 5px 16px 5px 10px;
    font-size: 13px;
    text-align: left;
    height: auto;
}

#input_tintingPosition span {
    text-align: center;
    display: inline-block;
    width: 104px;
    margin-right: 4px;
}

#input_tintingDensity select {
    width: 104px;
    min-width: 0;
    margin-right: 8px;
}

@media screen and (max-width: 600px){
    #request-input-date input{
        width: 80%;
    }
    #input_tintingPosition, #input_tintingDensity {
        width: calc(100% - 5px);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    #input_tintingPosition span {
        text-align: center;
        display: inline-block;
        width: 45%;
    }

    #input_tintingDensity select {
        width: 45%;
        margin: 0;
    }
}

`, "",{"version":3,"sources":["webpack://./src/styles/Modal_RequestWrite.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,UAAU;IACV,SAAS;IACT,YAAY;IACZ,0BAA0B;IAC1B,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,uBAAuB;QACvB,aAAa;QACb,mBAAmB;QACnB,6BAA6B;IACjC;;IAEA;QACI,kBAAkB;QAClB,qBAAqB;QACrB,UAAU;IACd;;IAEA;QACI,UAAU;QACV,SAAS;IACb;AACJ","sourcesContent":["#request-input-date input {\n    box-sizing: content-box;\n    width: 60%;\n    margin: 0;\n    border: none;\n    padding: 5px 16px 5px 10px;\n    font-size: 13px;\n    text-align: left;\n    height: auto;\n}\n\n#input_tintingPosition span {\n    text-align: center;\n    display: inline-block;\n    width: 104px;\n    margin-right: 4px;\n}\n\n#input_tintingDensity select {\n    width: 104px;\n    min-width: 0;\n    margin-right: 8px;\n}\n\n@media screen and (max-width: 600px){\n    #request-input-date input{\n        width: 80%;\n    }\n    #input_tintingPosition, #input_tintingDensity {\n        width: calc(100% - 5px);\n        display: flex;\n        align-items: center;\n        justify-content: space-evenly;\n    }\n\n    #input_tintingPosition span {\n        text-align: center;\n        display: inline-block;\n        width: 45%;\n    }\n\n    #input_tintingDensity select {\n        width: 45%;\n        margin: 0;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
