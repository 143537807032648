import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getUserInfo, LogIn} from "../api/Api";
import {useRecoilState} from "recoil";
import {IsLogin, userInfo} from "./UserInfo";
import "../styles/LogIn.css";

export default function LogInPage() {
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useRecoilState(IsLogin);
    const [user, setUser] = useRecoilState(userInfo);
    const [id, setId] = useState(getCookie('user_id') ? getCookie('user_id') : "");
    const [password, setPassword] = useState(getCookie('user_pw') ? getCookie('user_pw') : "");
    const [isSaveChecked, setIsSaveChecked] = useState(getCookie('user_pw') && getCookie('user_id'));

    const onChangeHandler = (e, setState) => {
        if (setState === setIsSaveChecked) {
            setIsSaveChecked(!isSaveChecked);
            return;
        }
        const value = e.target.value;
        setState(value);
    };

    function getCookie(cookieName) {
        const cookieString = document.cookie;
        const cookies = cookieString.split(';').map(cookie => cookie.trim().split('='));
        const cookie = cookies.find(([name]) => name === cookieName);
        return cookie ? cookie[1] : null;
    }

    const formSubmit = async (e) => {
        e.preventDefault();
        const user_id = id;
        const user_pw = password;
        const bodyData = {
            user_id: user_id,
            password: user_pw,
        };
        console.log(bodyData);
        if (isSaveChecked) {
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 365);
            document.cookie = `user_id=${id}; expires=${expirationDate.toUTCString()}; path=/`;
            document.cookie = `user_pw=${password}; expires=${expirationDate.toUTCString()}; path=/`;
        } else {
            document.cookie = `user_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            document.cookie = `user_pw=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
        }
        const log_in = await LogIn({bodyData});
        if (log_in === 200) {
            const myInfo = await getUserInfo();
            if (myInfo?.status === 200) {
                setIsLogin(true);
                setUser(myInfo.data.user_info);
                navigate("/req/list");
            } else {
                setIsLogin(false);
                navigate("/login/loginForm")
            }
        }
    };

    useEffect(() => {
        const cookieString = document.cookie;
        const cookiesArray = cookieString.split(';');
        const cookies = cookiesArray.reduce((acc, cookie) => {
            const [key, value] = cookie.split('=').map(item => item.trim());
            acc[key] = value;
            return acc;
        }, {});

        if (cookies.user_id && cookies.user_pw) {
            setIsSaveChecked(true);
        } else {
            setIsSaveChecked(false);
        }

        const inputElement = document.getElementById("login");
        inputElement.addEventListener("focus", preventZoom);
    }, []);

    const preventZoom = () => {
        const viewport = document.querySelector("meta[name=viewport]");
        viewport.setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no");
    };

    return (
        <div className="login">
            <form>
                <div className="loginController">
                    <div>
                        <label>ID/PW 저장</label>
                        <input type="checkbox" checked={isSaveChecked}
                               onChange={(e) => onChangeHandler(e, setIsSaveChecked)}/>
                    </div>
                    <div>
                        <span>아 이 디</span>
                        <input type="text" id="login" value={id} onChange={(e) => {
                            onChangeHandler(e, setId);
                        }}/>
                    </div>
                    <div>
                        <span>비밀번호</span>
                        <input type="password" id="login" value={password} onChange={(e) => {
                            onChangeHandler(e, setPassword);
                        }}/>
                    </div>
                </div>
                <button id="loginBtn" className="blackBtn" onClick={formSubmit}>로그인</button>
            </form>
            <div className="login-extra">
                <div className="loginExtraController">
                    <button className="blackBtn" onClick={() => {
                        navigate("/join/terms");
                    }}>회원가입
                    </button>
                    <button className="blackBtn" onClick={() => navigate("/join/search")}>아이디/비밀번호 찾기</button>
                </div>
                <p>회원관리 문의 031-903-2121(내선3번)</p>
            </div>
        </div>
    );
}
