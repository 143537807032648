import UserList from "../components/UserList";
import excelIcon from "../free-icon-sheet-11891828.png";
import {useState} from "react";
import Modal_Registration from "../components/Modal_Registration";
import SearchBar_User from "../components/SearchBar_User";
import {getUserExcel} from "../api/Api";
import {useNavigate} from "react-router-dom";

export default function UserManagementPage({user}) {
    const navigate = useNavigate();
    const [searchCondition, setSearchCondition] = useState({});
    const [isRegModalOpen, setIsRegModalOpen] = useState(false);
    const [reRender, setReRender] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const openRegModal = () => {
        setIsRegModalOpen(true);
    };

    const closeRegModal = () => {
        setIsRegModalOpen(false);
    };

    const downloadUserExcel = async () => {
        const download = await getUserExcel(searchCondition);
        if(download){
            navigate("/");
        }
    }

    return (
        <div className="page">
            <SearchBar_User searchCondition={searchCondition} setSearchCondition={setSearchCondition} setCurrentPage={setCurrentPage}/>
            <button id="userRegBtn" className="blackBtn" onClick={openRegModal}>사용자 등록</button>
            {isRegModalOpen &&
                <Modal_Registration showModal={isRegModalOpen} onClose={closeRegModal} reRender={reRender}
                                    setReRender={setReRender}/>}
            <div className="printExcelBtn">
                <button onClick={downloadUserExcel}>
                    <img src={excelIcon} alt="엑셀"/><span>엑셀 다운로드</span>
                </button>
            </div>
            <UserList searchCondition={searchCondition} reRender={reRender} setReRender={setReRender} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </div>
    )
}