// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#userModal {
    border: 2px solid black;
}

#userModal .modal-inputs {
    grid-template-columns: 2fr 8fr;
}

#userModal .modal-inputs span {
    margin: 0 3.2px;
}

#resetPwBtn {
    padding: 8px 24px;
}

#addStockBtn {
    font-size: 12.8px;
    padding: 6.4px 0;
    margin: 0 20px;
    width: 96px;
}

.addStockBtn:hover {
    background-color: #444444;
}

.userModifyController {
    display: flex;
    justify-content: center;
    margin: 48px auto 0 auto;
}

.userModifyController button {
    font-weight: bold;
    font-size: 12.8px;
    padding: 6.4px 0;
    margin: 0 32px;
    width: 96px;
}

.saveUserBtn {
    background-color: #323232;
    border: none;
    color: white;
}

.saveUserBtn:hover {
    background-color: #444444;
}`, "",{"version":3,"sources":["webpack://./src/styles/Modal_UserDetails.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["#userModal {\n    border: 2px solid black;\n}\n\n#userModal .modal-inputs {\n    grid-template-columns: 2fr 8fr;\n}\n\n#userModal .modal-inputs span {\n    margin: 0 3.2px;\n}\n\n#resetPwBtn {\n    padding: 8px 24px;\n}\n\n#addStockBtn {\n    font-size: 12.8px;\n    padding: 6.4px 0;\n    margin: 0 20px;\n    width: 96px;\n}\n\n.addStockBtn:hover {\n    background-color: #444444;\n}\n\n.userModifyController {\n    display: flex;\n    justify-content: center;\n    margin: 48px auto 0 auto;\n}\n\n.userModifyController button {\n    font-weight: bold;\n    font-size: 12.8px;\n    padding: 6.4px 0;\n    margin: 0 32px;\n    width: 96px;\n}\n\n.saveUserBtn {\n    background-color: #323232;\n    border: none;\n    color: white;\n}\n\n.saveUserBtn:hover {\n    background-color: #444444;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
