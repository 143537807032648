// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#modifyPwModal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* 흐린 배경 */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

#modifyPwModal {
    width: 240px;
    height: 400px;
    border: 2px solid black;
}

#modifyPwModal .content {
    text-align: center;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    height: 90%;
    justify-content: space-between;
}

#modifyPwModal .modifyPwBtn {
    margin: 0;
}

.modifyPwInputs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 80%;
}

.modifyPwInputs input {
    width: 160px;
    padding: 5px 10px;
    margin: 0 16px;
    font-size: 14px;
    border: 1px solid #dcdcdc;
}

@media screen and (max-width: 900px) {
    #modifyPwModal {
        /*height: 95vh;*/
        /*overflow-y: scroll;*/
    }
}

@media screen and (max-width: 600px) {
    #modifyPwModal {
        /*height: 55vh;*/
        /*overflow-y: hidden;*/
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Modal_Password.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B,EAAE,UAAU;IAC1C,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,cAAc;IACd,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI;QACI,gBAAgB;QAChB,sBAAsB;IAC1B;AACJ;;AAEA;IACI;QACI,gBAAgB;QAChB,sBAAsB;IAC1B;AACJ","sourcesContent":["#modifyPwModal-wrapper {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5); /* 흐린 배경 */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 999;\n}\n\n#modifyPwModal {\n    width: 240px;\n    height: 400px;\n    border: 2px solid black;\n}\n\n#modifyPwModal .content {\n    text-align: center;\n    overflow-y: hidden;\n    display: flex;\n    flex-direction: column;\n    height: 90%;\n    justify-content: space-between;\n}\n\n#modifyPwModal .modifyPwBtn {\n    margin: 0;\n}\n\n.modifyPwInputs {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    height: 80%;\n}\n\n.modifyPwInputs input {\n    width: 160px;\n    padding: 5px 10px;\n    margin: 0 16px;\n    font-size: 14px;\n    border: 1px solid #dcdcdc;\n}\n\n@media screen and (max-width: 900px) {\n    #modifyPwModal {\n        /*height: 95vh;*/\n        /*overflow-y: scroll;*/\n    }\n}\n\n@media screen and (max-width: 600px) {\n    #modifyPwModal {\n        /*height: 55vh;*/\n        /*overflow-y: hidden;*/\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
