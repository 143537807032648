// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#warrantyModal {
    width: 60%;
    height: 70%;
    max-height: 650px;
    border: 1px solid black;
}

.warranty-tintingPosition, .warranty-tintingFilm, .warranty-tintingDensity {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.warranty-tintingPosition span{
    width: 15%;
    text-align: center;
}

.warranty-tintingFilm select, .warranty-tintingDensity select {
    margin: 0;
    width: 15%;
    min-width: 0;
}
@media screen and (max-width: 900px) {
    #warrantyModal {
        width: 90%;
        height: 80%;
    }
}
@media screen and (max-width: 600px){
    #warrantyModal{
        width: 90%;
        height: 70%;
    }
    .warranty-tintingPosition, .warranty-tintingFilm, .warranty-tintingDensity{
        width: calc(100% - 5px);
    }
    .warranty-tintingPosition span{
        font-size: 12px;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Modal_WarrantyDetails.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,SAAS;IACT,UAAU;IACV,YAAY;AAChB;AACA;IACI;QACI,UAAU;QACV,WAAW;IACf;AACJ;AACA;IACI;QACI,UAAU;QACV,WAAW;IACf;IACA;QACI,uBAAuB;IAC3B;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":["#warrantyModal {\n    width: 60%;\n    height: 70%;\n    max-height: 650px;\n    border: 1px solid black;\n}\n\n.warranty-tintingPosition, .warranty-tintingFilm, .warranty-tintingDensity {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.warranty-tintingPosition span{\n    width: 15%;\n    text-align: center;\n}\n\n.warranty-tintingFilm select, .warranty-tintingDensity select {\n    margin: 0;\n    width: 15%;\n    min-width: 0;\n}\n@media screen and (max-width: 900px) {\n    #warrantyModal {\n        width: 90%;\n        height: 80%;\n    }\n}\n@media screen and (max-width: 600px){\n    #warrantyModal{\n        width: 90%;\n        height: 70%;\n    }\n    .warranty-tintingPosition, .warranty-tintingFilm, .warranty-tintingDensity{\n        width: calc(100% - 5px);\n    }\n    .warranty-tintingPosition span{\n        font-size: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
