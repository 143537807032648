import {useState} from "react";

export default function PopupList(){
    const popupTableHeader = [
        {
            text:'번호',
            value:'index'
        },
        {
            text:'제목',
            value:'title'
        },
        {
            text:'접속기기',
            value:'device'
        },
        {
            text:'시작일시',
            value:'start_date'
        },
        {
            text:'종료일시',
            value:'end_date'
        },
        {
            text:'left',
            value:'left'
        },
        {
            text: 'top',
            value: 'top'
        },
        {
            text: 'width',
            value: 'width'
        },
        {
            text: 'height',
            value: 'height'
        }
    ]

    return(
        <div>
            <table className="list_table">
                <thead>
                <tr className="table-header">
                    {
                        popupTableHeader.map((header)=>
                            <th key={header.text}>
                                {header.text}
                            </th>
                        )
                    }
                </tr>
                </thead>
                <tbody>

                </tbody>
            </table>
        </div>
    )
}