import TextEditor from "./TextEditor";
import "../styles/Modal_Notice.css";
import {useEffect, useState} from "react";
export default function Modal_Notice({showModal, onClose, noti}) {
    const modules = {
        toolbar: [],
    };
    const formats = [
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "code-block",
        "video",
        "align",
        "color",
        "background",
        "script",
        "table",
        "alt",
        "height",
        "width",
    ];

    const handleClose = () => {
        const today = new Date().toISOString().split('T')[0]; // YYYY-MM-DD 형식의 오늘 날짜
        localStorage.setItem('lastPopupDate', today);
        onClose();
    };
    return (
        <div className="modal-wrapper">
            <div className="modal" id="noticeModal">
                <div className="header">
                    <p>공지사항</p>
                    <span className="close" onClick={onClose}>&times;</span>
                </div>
                <div className="content">
                    <TextEditor text={noti} readOnly={true} modules={modules} formats={formats}/>
                    <div className="noticeController" onClick={handleClose}>
                        <input type="checkbox"/><span>오늘 하루동안 보지 않음</span>
                    </div>
                </div>
            </div>
        </div>
    )
}