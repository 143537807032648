import "../styles/Footer.css";
import { useState } from "react";
import Modal_Copyright from "./Modal_Copyright";

export default function Footer() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="footer-wrapper">
      <div className="footer">
        <div className="address">
          <div>상 호 : (주)스미스클럽</div>
          <div>대표이사 : 김 태 준</div>
          <div>본사주소 : 경기도 고양시 일산동구 일산로 418 스미스 빌딩</div>
          <div>본사전화 : 031-903-2121</div>
          <div>본사팩스 : 031-965-8558</div>
          <div>홈페이지 : www.the-smith.net</div>
          <div>E-mail : tj.kim@smithclub.net</div>
        </div>
        <div className="copy">
          <div className="as">
            A/S 및 소비자 견적 문의는 해당 지역 스미스클럽 공식 지사와 대리점에
            문의해 주시기 바랍니다.
          </div>
          <div className="copy_txt">
            COPYRIGHT © SMITHCLUB. ALL RIGHTS RESERVED.
          </div>
          <div onClick={openModal} style={{ cursor: "pointer" }}>
            [개인정보처리방침]
          </div>
          <Modal_Copyright showModal={isModalOpen} onClose={closeModal} />
        </div>
      </div>
    </div>
  );
}
