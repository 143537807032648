// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#regCodeModal {
    width: 288px;
    height: auto;
    min-height: 350px;
    border: 2px solid black;
}

#regCodeModal .content {
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.regCodeInputs {
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.regCodeInputs input {
    width: 95%;
    height: 23px;
    padding: 3px 4px;
    border: 1px solid #dcdcdc;
    margin: 0;
}

#regSaveBtn {
    width: 80%;
    font-size: 14.4px;
    padding: 8px 0;
    margin-top: 16px;
}

@media screen and (max-width: 900px) {
    #regCodeModal {
        height: 90%;
        max-height: 300px;
    }
    #regCodeModal .content{
        overflow-y: scroll;
    }
}

@media screen and (max-width: 600px) {
    #regCodeModal {
        height: auto;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Modal_CodeRegister.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;IACxB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;IACzB,SAAS;AACb;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI;QACI,WAAW;QACX,iBAAiB;IACrB;IACA;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":["#regCodeModal {\n    width: 288px;\n    height: auto;\n    min-height: 350px;\n    border: 2px solid black;\n}\n\n#regCodeModal .content {\n    width: calc(100% - 32px);\n    height: calc(100% - 32px);\n    overflow-y: hidden;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.regCodeInputs {\n    width: 100%;\n    min-height: 200px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: flex-start;\n}\n\n.regCodeInputs input {\n    width: 95%;\n    height: 23px;\n    padding: 3px 4px;\n    border: 1px solid #dcdcdc;\n    margin: 0;\n}\n\n#regSaveBtn {\n    width: 80%;\n    font-size: 14.4px;\n    padding: 8px 0;\n    margin-top: 16px;\n}\n\n@media screen and (max-width: 900px) {\n    #regCodeModal {\n        height: 90%;\n        max-height: 300px;\n    }\n    #regCodeModal .content{\n        overflow-y: scroll;\n    }\n}\n\n@media screen and (max-width: 600px) {\n    #regCodeModal {\n        height: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
