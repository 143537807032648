import {useState} from "react";
import DateInput from "./DateInput";
import * as React from "react";
import dayjs from "dayjs";

export default function SearchBar_Inventory({searchCondition, setSearchCondition, setCurrentPage}) {
    const [name, setName] = useState("");
    const [selectedStartDate, setSelectedStartDate] = React.useState(dayjs('2023-01-01'));
    const [selectedEndDate, setSelectedEndDate] = React.useState(dayjs());
    const [status, setStatus] = useState("");
    const [film, setFilm] = useState("");
    const [agency, setAgency] = useState("");
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
    };

    const onChangeHandler = (e, setState) => {
        setState(e.target.value);
    }

    const handelInventorySearch = () => {
        if (selectedStartDate && selectedEndDate) {
            if ((selectedStartDate.format("YYYY-MM-DD")) > (selectedEndDate.format("YYYY-MM-DD"))) {
                alert("올바른 등록 일자를 선택해주세요.");
                return;
            }
        }
        if(selectedStartDate){
            if (selectedStartDate.format("YYYY-MM-DD") === 'Invalid Date'){
                alert("올바른 등록 일자를 선택해주세요.");
                return;
            }
        }
        if(selectedEndDate){
            if (selectedEndDate.format("YYYY-MM-DD") === 'Invalid Date'){
                alert("올바른 등록 일자를 선택해주세요.");
                return;
            }
        }
        setSearchCondition({
            user_name: name,
            registration_date: selectedStartDate ? selectedEndDate ? selectedStartDate.format("YYYY-MM-DD") + "/" + selectedEndDate.format("YYYY-MM-DD") : selectedStartDate + "/" + " " : selectedEndDate ? " " + "/" + selectedEndDate : " / ",
            status: status,
            film_type: film,
            agency_affiliation: agency,
        });
        setCurrentPage(1);
    }
    return (
        <div className="searchBar">
            <button id="search_switch" className="blackBtn"
                    onClick={toggleSearch}>{isSearchOpen ? "검색 항목 닫기" : "검색 항목 열기"}</button>
                <div className={`search_admin ${isSearchOpen ? "open" : ''}`}>
                    <div className="search_input">
                        <div>
                            <label>사용자명</label>
                            <input id="search_name" className="search_values" value={name}
                                                       onChange={(e) => onChangeHandler(e, setName)}/>
                        </div>
                        <div style={{display: "flex", gridColumn: '2/span 2', alignItems: "center"}}>
                            <label>등록일</label>
                            <div id="search_registration_date">
                                <div style={{margin: '0 8px 0 0'}}><DateInput date={selectedStartDate}
                                                                           setDate={setSelectedStartDate}/></div>
                                ~
                                <div style={{margin: '0 0 0 8px'}}><DateInput date={selectedEndDate}
                                                                           setDate={setSelectedEndDate}/></div>
                            </div>
                        </div>
                        <div>
                            <label>상태</label>
                            <select id="search_status" className="search_values" value={status} onChange={(e)=>onChangeHandler(e, setStatus)}>
                                <option value="">선택</option>
                                <option value="입고">입고</option>
                                <option value="출고">출고</option>
                            </select>
                        </div>
                        <div>
                            <label>필름</label>
                            <select id="search_film" className="search_values" value={film} onChange={(e)=>onChangeHandler(e, setFilm)}>
                                <option value="">선택</option>
                                <option value="Authentic-T">Authentic-T</option>
                            </select>
                        </div>
                        <div>
                            <label>대리점</label>
                            <input type="text" id="search_agency" className="search_values" value={agency} onChange={(e)=>onChangeHandler(e, setAgency)}/>
                        </div>
                    </div>
                    <button id="searchBtn" className="blackBtn" onClick={handelInventorySearch}>검색</button>
                </div>
        </div>
    )
}