// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allocBtn {
    display: flex;
    justify-content: left;
    margin-left: 24px;
    padding: 8px 24px;
    border: 1px solid #ddd;
}

.allocBtn:hover {
    background-color: #e1e1e1;
}

@media screen and (max-width: 600px){
    .allocBtn{
        margin-left: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/RequestList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;IACrB,iBAAiB;IACjB,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".allocBtn {\n    display: flex;\n    justify-content: left;\n    margin-left: 24px;\n    padding: 8px 24px;\n    border: 1px solid #ddd;\n}\n\n.allocBtn:hover {\n    background-color: #e1e1e1;\n}\n\n@media screen and (max-width: 600px){\n    .allocBtn{\n        margin-left: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
