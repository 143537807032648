import Notice from "../components/Notice";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getNoticeADMIN} from "../api/Api";
export default function NoticePage() {
    const navigate = useNavigate();
    const [agencyNotiState, setAgencyNotiState] = useState(true);
    const [agencyNoti, setAgencyNoti] = useState();
    const [branchNotiState, setBranchNotiState] = useState(true);
    const [branchNoti, setBranchNoti] = useState();
    const [financialNotiState, setFinancialNotiState] = useState(true);
    const [financialNoti, setFinancialNoti] = useState();
    const [headNotiState, setHeadNotiState] = useState(true);
    const [headNoti, setHeadNoti] = useState();

    const fetchNoti = async () => {
        const response = (await getNoticeADMIN());
        setAgencyNoti(response?.agency?.content);
        setAgencyNotiState(response?.agency?.activation);
        setBranchNoti(response?.dealer?.content);
        setBranchNotiState(response?.dealer?.activation);
        setFinancialNoti(response?.financial?.content);
        setFinancialNotiState(response?.financial?.activation);
        setHeadNoti(response?.admin?.content);
        setHeadNotiState(response?.admin?.activation);
    }

    useEffect(() => {
        fetchNoti();
    }, []);

    return (
        <div className="page">
            <Notice aff={"대리점"} activeOption={agencyNotiState} setActiveOption={setAgencyNotiState} noti={agencyNoti} setNoti={setAgencyNoti}/>
            <Notice aff={"지점"} activeOption={branchNotiState} setActiveOption={setBranchNotiState} noti={branchNoti} setNoti={setBranchNoti}/>
            <Notice aff={"지점장"} activeOption={financialNotiState} setActiveOption={setFinancialNotiState} noti={financialNoti} setNoti={setFinancialNoti}/>
            <Notice aff={"본사"} activeOption={headNotiState} setActiveOption={setHeadNotiState} noti={headNoti} setNoti={setHeadNoti}/>
        </div>
    )
}