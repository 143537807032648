import "../styles/Nav.css";
import {NavLink, useLocation} from "react-router-dom";
import {useState} from "react";
import SideDrawer from "./SideDrawer";

export default function Nav({role}) {
    const [isSideOpen, setIsSideOpen] = useState(false);
    const openSide = () => {
        setIsSideOpen(true);
    }

    const closeSide = () => {
        setIsSideOpen(false);
    }
    if (role === "admin") {
        return (
            <>
                <div className="nav" id="nav_admin">
                    <NavLink to={"/req/list"} activeClassName={"active"}>
                        시공 요청서
                    </NavLink>
                    <NavLink to={"/grant/list"} activeClassName={"active"}>
                        시공 보증서
                    </NavLink>
                    <NavLink to={"/req/stock/list"} activeClassName={"active"}>
                        재고 내역
                    </NavLink>
                    <NavLink to={"/admin/user/list"} activeClassName={"active"}>
                        사용자 관리
                    </NavLink>
                    <NavLink to={"/code/info"} activeClassName={"active"}>
                        코드 관리
                    </NavLink>
                    <NavLink to={"/popup/list"} activeClassName={"active"}>
                        팝업 관리
                    </NavLink>
                    <NavLink to={"/req/noti/list"} activeClassName={"active"}>
                        공지사항
                    </NavLink>
                </div>
                <div className="navBtn" onClick={openSide}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                {isSideOpen && <SideDrawer show={isSideOpen} onClose={closeSide}/>}
            </>
        );
    } else {
        return (
            <>
                <div className="nav" id="nav_etc">
                    <NavLink to={"/myInfo/info"} activeClassName={"active"}>
                        내 정보
                    </NavLink>
                    <NavLink to={"/req/list"} activeClassName={"active"}>
                        시공 요청서
                    </NavLink>
                </div>
                <div className="navBtn" onClick={openSide}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                {isSideOpen && <SideDrawer show={isSideOpen} onClose={closeSide}/>}
            </>
        );
    }
}
