import {atom, selector} from "recoil";
import { recoilPersist } from 'recoil-persist';

const sessionStorage =
    typeof window !== "undefined" ? window.sessionStorage : undefined;

const { persistAtom } = recoilPersist({
    key: "user", // 고유한 key 값
    storage: sessionStorage,
})

export const userInfo = atom({
    key: 'user',
    default: {
        user_id: '',
        name: "",
        affiliation_code: '',
        affiliation_value: '',
        phone: '',
        address: "",
        email: "",
        authority_type: "",
        company_phone: "",
        stock: 0,
    },
    effects_UNSTABLE: [persistAtom],
})

export const IsLogin = atom({
    key: 'IsLogin',
    default: false,
    effects_UNSTABLE: [persistAtom],
});

export const IsLoginSelector = selector({
    key: 'IsLoginSelector',
    get: ({get}) => !!get(IsLogin)
})